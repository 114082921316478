import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Stage } from '../../__generated__/gql/graphql';

export default function UserFacingModalStage() {
  const { register, control } = useFormContext();

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              placeholder="Title of the project"
              aria-placeholder={'Title'}
              {...register('title')}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={'stage'}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                  <InputLabel>Stage</InputLabel>
                  <Select
                    labelId="simple-select-objective"
                    label="Stage"
                    sx={{ width: '100%' }}
                    value={value}
                    onChange={onChange}
                  >
                    {Object.values(Stage).map(stage => (
                      <MenuItem key={stage} value={stage}>
                        {StageDisplayMap[stage]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              control={control}
              rules={{ required: false }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-static"
              label="How to get started"
              multiline
              rows={4}
              placeholder="What are the steps to get access to the tool: eg. credentials, training, etc."
              variant="outlined"
              {...register('stepsDescription')}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export const StageDisplayMap = {
  DEVELOP: 'Develop',
  DEPLOY: 'Deploy',
  OPERATE: 'Operate',
  PLAN: 'Plan',
  RETIRE: 'Retire',
};

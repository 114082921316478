import { Comparator, Rule, ValueType } from '../../__generated__/gql/graphql';

export default function filterExperiments(
  experiments: any[],
  appliedRules: Rule[]
) {
  return experiments.filter((experiment: any) =>
    appliedRules.every((appliedRule: Rule) => {
      const dataValue = experiment[appliedRule.name!];

      if (dataValue === undefined) {
        return false;
      }

      const filterValue = appliedRule.value as string | number;
      switch (appliedRule.type) {
        case ValueType.String: {
          switch (appliedRule.comparator) {
            case Comparator.StartWith: {
              return dataValue.startsWith(filterValue);
            }
            case Comparator.EndWith: {
              return dataValue.endsWith(filterValue);
            }
            case Comparator.Contain: {
              return dataValue.includes(filterValue);
            }
            case Comparator.Equal: {
              return dataValue === filterValue;
            }
            default: {
              return false;
            }
          }
        }
        case ValueType.Number: {
          switch (appliedRule.comparator) {
            case Comparator.Equal: {
              return dataValue === filterValue;
            }
            case Comparator.Gt: {
              return dataValue > filterValue;
            }
            case Comparator.Lt: {
              return dataValue < filterValue;
            }
            case Comparator.Gte: {
              return dataValue >= filterValue;
            }
            case Comparator.Lte: {
              return dataValue <= filterValue;
            }
            default: {
              return false;
            }
          }
        }
        default:
          return false;
      }
    }, true)
  );
}

import React from 'react';
import { Avatar, Typography, Box, Tooltip, Chip } from '@mui/material';
import { User } from '../__generated__/gql/graphql';

type UserTagProps = {
  user?: User;
  size?: 'small' | 'medium';
  variant?: 'avatar' | 'chip' | 'full' | 'text';
  noWrap?: boolean;
};

const UserTag: React.FC<UserTagProps> = ({
  user,
  size = 'medium',
  variant = 'avatar',
  noWrap = false,
}) => {
  const isSmall = size === 'small';

  const avatarSize = isSmall ? 24 : 32;
  const fontSize = isSmall ? '0.75rem' : '0.875rem';

  const displayName = user
    ? user.name || user.email || 'User could not be resolved'
    : 'Not assigned';
  const initials = user?.initials || '?';

  const avatarComponent = (
    <Avatar
      sx={{
        width: avatarSize,
        height: avatarSize,
        fontSize,
      }}
    >
      {initials}
    </Avatar>
  );

  switch (variant) {
    case 'avatar':
      return (
        <Tooltip title={displayName} arrow>
          {avatarComponent}
        </Tooltip>
      );

    case 'chip':
      return <Chip label={displayName} size={size} />;

    case 'text':
      return (
        <Typography
          variant={isSmall ? 'body2' : 'body1'}
          component="div"
          noWrap={noWrap}
        >
          {displayName}
        </Typography>
      );

    case 'full':
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: 1 }}>
          {avatarComponent}
          <Box sx={{ ml: 1 }}>
            <Typography variant={isSmall ? 'body2' : 'body1'} component="div">
              {displayName}
            </Typography>
            {user?.email && user.email !== displayName && (
              <Typography variant="caption" color="text.secondary">
                {user.email}
              </Typography>
            )}
          </Box>
        </Box>
      );

    default:
      return null;
  }
};

export default UserTag;

import React, { useState } from 'react';
import {
  useMutation,
  gql,
  useApolloClient,
  useSuspenseQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  Stack,
  Typography,
  ListItemText,
  ListItemIcon,
  Tab,
  Tabs,
  Paper,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  DialogContentText,
} from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import { colorThemes } from '../theme';
import AddIcon from '@mui/icons-material/Add';
import {
  RequirementCompletionStatusEnum,
  MaterializedRequirement,
  User,
  GetGovernanceRequirementStatusQuery,
  GovernanceEvidence,
  RequirementAuditStatusEnum,
} from '../__generated__/gql/graphql';
import RequirementProgressStepper from './RequirementProgressStepper';
import EvidenceCard from './EvidenceCard';
import { DateTime } from 'luxon';
import UserList from '../components/UserList';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EvidenceModal from './EvidenceModal';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  LoadingButton,
} from '@mui/lab';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { formatGraphqlEnumToReadableText } from '../utils/stringManipulation';

const UPDATE_GOVERNANCE_REQUIREMENT_STATUS = gql`
  mutation UpdateGovernanceRequirementStatus(
    $materializedRequirementId: String!
    $markedAsCompleted: Boolean
    $completionStatus: RequirementCompletionStatusEnum
    $condName: String!
    $targetQueryMutation: String!
    $action: String!
  ) {
    updateGovernanceRequirementStatus(
      materializedRequirementId: $materializedRequirementId
      markedAsCompleted: $markedAsCompleted
      completionStatus: $completionStatus
    ) {
      governanceRequirementStatus {
        materializedRequirementId
        markedAsCompleted
        completionStatus
        checkPermission(
          arg: $materializedRequirementId
          condName: $condName
          targetQueryMutation: $targetQueryMutation
          action: $action
        ) {
          hasPermission
          userId
        }
      }
    }
  }
`;

const UNLINK_EVIDENCE_FROM_REQUIREMENT = gql`
  mutation UnlinkEvidenceFromRequirement(
    $requirementId: ID!
    $evidenceId: ID!
  ) {
    unlinkEvidenceFromRequirement(
      requirementId: $requirementId
      evidenceId: $evidenceId
    ) {
      requirement {
        id
        evidence {
          id
          title
          description
          externalUrl
        }
      }
    }
  }
`;

const UPDATE_GOVERNANCE_REQUIREMENT_OVERRIDES = gql`
  mutation UpdateGovernanceRequirementOverrides(
    $materializedRequirementId: String!
    $manualOverrideOwners: [String!]
    $manualOverrideApprovers: [String!]
    $condName: String!
    $targetQueryMutation: String!
    $action: String!
  ) {
    updateManualOverrides(
      materializedRequirementId: $materializedRequirementId
      manualOverrideOwners: $manualOverrideOwners
      manualOverrideApprovers: $manualOverrideApprovers
    ) {
      governanceRequirementStatus {
        manualOverrideOwners {
          id
          name
        }
        manualOverrideApprovers {
          id
          name
        }
        checkPermission(
          arg: $materializedRequirementId
          condName: $condName
          targetQueryMutation: $targetQueryMutation
          action: $action
        ) {
          hasPermission
          userId
        }
      }
    }
  }
`;

const ADD_GOV_REQ_COMMENT = gql`
  mutation AddGovernanceRequirementComment(
    $reqId: String!
    $commentText: String!
  ) {
    addGovernanceRequirementComment(reqId: $reqId, commentText: $commentText) {
      comments {
        id
        comment
        timestamp
        user {
          name
          id
        }
      }
    }
  }
`;

const DELETE_GOV_REQ_COMMENT = gql`
  mutation DeleteGovernanceRequirementComment(
    $reqId: String!
    $commentId: String!
  ) {
    deleteGovernanceRequirementComment(reqId: $reqId, commentId: $commentId) {
      comments {
        id
        comment
        timestamp
        user {
          name
          id
        }
      }
    }
  }
`;

const GET_REQUIREMENT_STATUS = gql`
  query GetGovernanceRequirementStatus(
    $materializedRequirementId: String!
    $condNameStatus: String!
    $targetQueryMutationStatus: String!
    $targetQueryMutationComments: String!
    $action: String!
    $auditId: String!
    $skipAudit: Boolean!
  ) {
    governanceRequirementStatus(
      materializedRequirementId: $materializedRequirementId
    ) {
      id
      checkPermissionStatus: checkPermission(
        arg: $materializedRequirementId
        condName: $condNameStatus
        targetQueryMutation: $targetQueryMutationStatus
        action: $action
      ) {
        ...PermissionFields
      }
      checkPermissionComments: checkPermission(
        arg: $materializedRequirementId
        targetQueryMutation: $targetQueryMutationComments
        action: $action
      ) {
        ...PermissionFields
      }
      completionStatus
      lastModified
      created
      comments {
        id
        comment
        timestamp
        user {
          name
          id
        }
      }
      changeEvents {
        id
        timestamp
        action
        user {
          name
        }
        changes {
          fieldName
          fieldType
          oldValue
          newValue
        }
        listChanges {
          fieldName
          added
          removed
        }
        metadata
      }
      evidence {
        id
        title
        description
        externalUrl
        file {
          url
        }
      }
      auditStatus(auditId: $auditId) @skip(if: $skipAudit) {
        id
        status
        comments
        evidenceNotes
      }
    }
  }
  fragment PermissionFields on PermissionType {
    hasPermission
    userId
  }
`;

const UPDATE_REQUIREMENT_AUDIT_STATUS = gql`
  mutation UpdateRequirementAuditStatus(
    $requirementId: String!
    $auditId: ID!
    $status: String
    $comments: String
    $evidenceNotes: [String!]
  ) {
    updateRequirementAuditStatus(
      requirementId: $requirementId
      auditId: $auditId
      status: $status
      comments: $comments
      evidenceNotes: $evidenceNotes
    ) {
      requirementAuditStatus {
        id
        status
        comments
        evidenceNotes
      }
    }
  }
  fragment PermissionFields on PermissionType {
    hasPermission
    userId
  }
`;

const formatDateTime = (isoString: string) => {
  return DateTime.fromISO(isoString).toLocaleString(DateTime.DATETIME_MED);
};

const FieldChangeDisplay = ({ change }: { change: any }) => (
  <Paper elevation={0} sx={{ p: 1, my: 1, bgcolor: 'background.paper' }}>
    <Typography variant="body2">
      <strong>{change.fieldName}</strong> changed from "{change.oldValue}" to "
      {change.newValue}"
    </Typography>
  </Paper>
);

const ListChangeDisplay = ({ change }: { change: any }) => (
  <Paper elevation={0} sx={{ p: 1, my: 1, bgcolor: 'background.paper' }}>
    <Typography variant="body2">
      <strong>{change.fieldName}</strong>
    </Typography>
    {change.added.length > 0 && (
      <Typography variant="body2" color="success.main">
        Added: {change.added.join(', ')}
      </Typography>
    )}
    {change.removed.length > 0 && (
      <Typography variant="body2" color="error.main">
        Removed: {change.removed.join(', ')}
      </Typography>
    )}
  </Paper>
);

export type SectionDrawerProps = DrawerProps & {
  requirement: MaterializedRequirement;
  isReadOnly?: boolean;
  auditId?: string;
};

const cardProps = {
  padding: '12px',
  bgcolor: colorThemes.DARK_BLUE_800,
  borderRadius: '8px',
};

const headingProps = {
  component: 'h3' as const,
  fontSize: '20px',
  fontWeight: 600,
};

export function RequirementDrawer({
  requirement,
  isReadOnly = false,
  auditId,
  ...props
}: SectionDrawerProps) {
  const readOnlyMode = isReadOnly || Boolean(auditId);

  return (
    <Drawer anchor="right" PaperProps={{ sx: { width: '66%' } }} {...props}>
      <React.Suspense
        fallback={
          <Box
            padding="24px"
            color={colorThemes.GREY_100}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        }
      >
        <RequirementDrawerContent
          requirement={requirement}
          isReadOnly={readOnlyMode}
          auditId={auditId}
        />
      </React.Suspense>
    </Drawer>
  );
}

function RequirementDrawerContent({
  requirement: {
    id,
    title,
    description,
    guidance,
    specifications,
    approvers,
    owners,
    evidenceSuggestions,
  },
  isReadOnly,
  auditId,
}: {
  requirement: MaterializedRequirement;
  isReadOnly?: boolean;
  auditId?: string;
}) {
  const client = useApolloClient();
  const [updateStatus] = useMutation(UPDATE_GOVERNANCE_REQUIREMENT_STATUS);
  const [addComment] = useMutation(ADD_GOV_REQ_COMMENT);
  const [deleteComment] = useMutation(DELETE_GOV_REQ_COMMENT);
  const [unlinkEvidenceFromRequirement] = useMutation(
    UNLINK_EVIDENCE_FROM_REQUIREMENT
  );
  const [updateOverrides] = useMutation(
    UPDATE_GOVERNANCE_REQUIREMENT_OVERRIDES
  );

  const [editingEvidence, setEditingEvidence] =
    useState<GovernanceEvidence | null>(null);

  const handleEditEvidence = (evidence: GovernanceEvidence) => {
    setEditingEvidence(evidence);
    setIsEvidenceModalOpen(true);
  };

  const handleCloseEvidenceModal = () => {
    setIsEvidenceModalOpen(false);
    setEditingEvidence(null);
  };

  const { data } = useSuspenseQuery<GetGovernanceRequirementStatusQuery>(
    GET_REQUIREMENT_STATUS,
    {
      variables: {
        materializedRequirementId: id,
        condNameStatus: 'req_status_change_owner_approver',
        targetQueryMutationStatus: 'updateGovernanceRequirementStatus',
        targetQueryMutationComments: 'deleteGovernanceRequirementComment',
        action: 'mutation',
        auditId: auditId ?? '',
        skipAudit: !auditId,
      },
    }
  );

  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const openEvidenceModal = () => {
    setIsEvidenceModalOpen(true);
  };

  const [requestApprovalDialogue, setRequestApprovalDialogue] = useState(false);
  const [requestChangesDialogue, setRequestChangesDialogue] = useState(false);

  const userStatus = data?.governanceRequirementStatus;
  const evidences = userStatus?.evidence ?? [];
  const [completionStatus, setCompletionStatus] =
    useState<RequirementCompletionStatusEnum>(
      userStatus?.completionStatus ?? RequirementCompletionStatusEnum.NotStarted
    );

  const getButtonText = () => {
    switch (completionStatus) {
      case RequirementCompletionStatusEnum.NotStarted:
        return 'Mark as Assigned';
      case RequirementCompletionStatusEnum.Assigned:
        return 'Mark as Completed';
      case RequirementCompletionStatusEnum.Completed:
        return 'Mark as Approved';
      case RequirementCompletionStatusEnum.Approved:
        return 'Approved';
      default:
        return 'Update Status';
    }
  };

  const getNextStatus = () => {
    switch (completionStatus) {
      case RequirementCompletionStatusEnum.NotStarted:
        return RequirementCompletionStatusEnum.Assigned;
      case RequirementCompletionStatusEnum.Assigned:
        return RequirementCompletionStatusEnum.Completed;
      case RequirementCompletionStatusEnum.Completed:
        return RequirementCompletionStatusEnum.Approved;
      default:
        return completionStatus;
    }
  };

  const handleUpdateStatus = async (
    newStatus: RequirementCompletionStatusEnum
  ) => {
    setCompletionStatus(newStatus);

    client.cache.modify({
      id: client.cache.identify({
        __typename: 'MaterializedRequirement',
        id,
      }),
      fields: {
        userStatus: existingStatus => ({
          ...existingStatus,
          completionStatus: newStatus,
        }),
      },
    });

    try {
      const result = await updateStatus({
        variables: {
          materializedRequirementId: id,
          completionStatus: newStatus,
          markedAsCompleted:
            newStatus === RequirementCompletionStatusEnum.Completed,
          condName: 'req_status_change_owner_approver',
          targetQueryMutation: 'updateGovernanceRequirementStatus',
          action: 'mutation',
        },
      });

      const updatedStatus =
        result.data.updateGovernanceRequirementStatus
          .governanceRequirementStatus;

      setButtonPerms(updatedStatus.checkPermission.hasPermission);

      if (updatedStatus.completionStatus !== newStatus) {
        setCompletionStatus(updatedStatus.completionStatus);
        client.cache.modify({
          id: client.cache.identify({
            __typename: 'MaterializedRequirement',
            id,
          }),
          fields: {
            userStatus: existingStatus => ({
              ...existingStatus,
              completionStatus: updatedStatus.completionStatus,
            }),
          },
        });
      }
      toast.success('Status update successful');
    } catch (error) {
      setCompletionStatus(completionStatus);
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          userStatus: existingStatus => ({
            ...existingStatus,
            completionStatus,
          }),
        },
      });
      toast.error('Failed to update status.');
      console.error(error);
    }
  };

  const handleRequestApproval = async (
    newStatus: RequirementCompletionStatusEnum
  ) => {
    handleUpdateStatus(newStatus);
    handleAddComment();
    setRequestApprovalDialogue(false);
  };

  const handleRequestChanges = async (
    newStatus: RequirementCompletionStatusEnum
  ) => {
    handleUpdateStatus(newStatus);
    handleAddComment();
    setRequestChangesDialogue(false);
  };

  const handleUnlinkEvidence = async (evidenceId: string) => {
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'MaterializedRequirement',
        id,
      }),
      fields: {
        userEvidence: (existingEvidence, { readField }) => ({
          ...existingEvidence,
          evidenceTexts: existingEvidence.evidenceTexts.filter(
            (evidence: any) => readField('id', evidence) !== evidenceId
          ),
        }),
      },
    });

    try {
      await unlinkEvidenceFromRequirement({
        variables: { requirementId: userStatus?.id ?? '', evidenceId },
      });
      toast.success('Evidence unlinked successfully');
    } catch (error) {
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          userEvidence: existingEvidence => ({
            ...existingEvidence,
            evidenceTexts: [
              ...existingEvidence.evidenceTexts,
              { id: evidenceId },
            ],
          }),
        },
      });
      toast.error('Failed to unlink evidence');
      console.error(error);
    }
  };

  const handleUserChange = async (
    userType: 'owner' | 'approver',
    newUsers: User[] | null
  ) => {
    const usersArray = newUsers ? newUsers.map(user => user.id) : [];
    const variables = {
      materializedRequirementId: id,
      ...(userType === 'owner' && { manualOverrideOwners: usersArray }),
      ...(userType === 'approver' && { manualOverrideApprovers: usersArray }),
      condName: 'req_status_change_owner_approver',
      targetQueryMutation: 'updateGovernanceRequirementStatus',
      action: 'mutation',
    };

    client.cache.modify({
      id: client.cache.identify({
        __typename: 'MaterializedRequirement',
        id,
      }),
      fields: {
        [userType === 'owner' ? 'owners' : 'approvers']: () => newUsers,
      },
    });

    const hasOwners =
      userType === 'owner'
        ? (newUsers?.length ?? 0) > 0
        : (owners?.length ?? 0) > 0;
    const hasApprovers =
      userType === 'approver'
        ? (newUsers?.length ?? 0) > 0
        : (approvers?.length ?? 0) > 0;
    const newStatus =
      (hasOwners || hasApprovers) &&
      completionStatus === RequirementCompletionStatusEnum.NotStarted
        ? RequirementCompletionStatusEnum.Assigned
        : !hasOwners &&
          !hasApprovers &&
          completionStatus === RequirementCompletionStatusEnum.Assigned
        ? RequirementCompletionStatusEnum.NotStarted
        : completionStatus;
    setCompletionStatus(newStatus);

    try {
      const result = await updateOverrides({ variables });
      setButtonPerms(
        result.data.updateManualOverrides.governanceRequirementStatus
          .checkPermission.hasPermission
      );
      toast.success(
        `${userType === 'owner' ? 'Owners' : 'Approvers'} updated successfully`
      );
    } catch (error) {
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          [userType === 'owner' ? 'owners' : 'approvers']: () =>
            userType === 'owner' ? owners : approvers,
        },
      });
      setCompletionStatus(completionStatus);
      toast.error(`Failed to update ${userType}`);
      console.error(error);
    }
  };

  const [activeTab, setActiveTab] = useState('details');

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const [buttonPerms, setButtonPerms] = useState(
    data.governanceRequirementStatus?.checkPermissionStatus?.hasPermission ??
      false
  );
  const commentPerms =
    data.governanceRequirementStatus?.checkPermissionComments?.hasPermission ??
    false;

  const navigate = useNavigate();

  const [comments, setComments] = useState(
    data?.governanceRequirementStatus?.comments
  );
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddComment = async () => {
    if (newComment.trim()) {
      setLoading(true);
      try {
        const commentResult = await addComment({
          variables: {
            reqId: id,
            commentText: newComment,
          },
        });
        setComments(
          commentResult.data.addGovernanceRequirementComment.comments
        );
        setNewComment('');
        setLoading(false);
        toast.success('Comment posted successfully');
      } catch (error) {
        toast.error('Failed to add comment');
        setLoading(false);
        console.error(error);
      }
    }
  };

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState('');
  const handleDeleteComment = async () => {
    if (deleteCommentId) {
      try {
        const commentResult = await deleteComment({
          variables: {
            reqId: id,
            commentId: deleteCommentId,
          },
        });
        setComments(
          commentResult.data.deleteGovernanceRequirementComment.comments
        );
        setDeleteCommentId('');
        toast.success('Comment successfully deleted');
      } catch (error) {
        setDeleteCommentId('');
        toast.error('Failed to delete comment.');
        console.error(error);
      }
    }
  };

  const [auditStatus, setAuditStatus] = useState<RequirementAuditStatusEnum>(
    data?.governanceRequirementStatus?.auditStatus?.status ??
      RequirementAuditStatusEnum.NotReviewed
  );
  const [auditComments, setAuditComments] = useState(
    data?.governanceRequirementStatus?.auditStatus?.comments ?? ''
  );
  const [evidenceNotes, setEvidenceNotes] = useState<string[]>(
    data?.governanceRequirementStatus?.auditStatus?.evidenceNotes?.filter(
      (note): note is string => note !== null
    ) ?? []
  );

  const [updateAuditStatus] = useMutation(UPDATE_REQUIREMENT_AUDIT_STATUS);

  const handleUpdateAuditStatus = async (
    newStatus: RequirementAuditStatusEnum
  ) => {
    try {
      const result = await updateAuditStatus({
        variables: {
          requirementId: id,
          auditId,
          status: newStatus,
          comments: auditComments,
          evidenceNotes,
        },
      });
      setAuditStatus(
        result.data.updateRequirementAuditStatus.requirementAuditStatus.status
      );
      toast.success('Audit status updated successfully');
    } catch (error) {
      toast.error('Failed to update audit status');
      console.error(error);
    }
  };

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const getAuditStatusIcon = (status: RequirementAuditStatusEnum) => {
    switch (status) {
      case RequirementAuditStatusEnum.Compliant:
        return <CheckCircleIcon sx={{ color: colorThemes.ACTION_BLUE }} />;
      case RequirementAuditStatusEnum.NonCompliant:
        return <ErrorIcon sx={{ color: colorThemes.GREY_600 }} />;
      case RequirementAuditStatusEnum.NotReviewed:
      default:
        return <HourglassEmptyIcon sx={{ color: colorThemes.GREY_600 }} />;
    }
  };

  return (
    <Box padding="24px" color={colorThemes.GREY_100}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <Typography component="h2" fontSize="26px" fontWeight={700}>
          {title}
        </Typography>
        {!isReadOnly && (
          <ButtonGroup>
            <Tooltip
              title={
                !buttonPerms &&
                completionStatus === RequirementCompletionStatusEnum.Completed
                  ? 'Must be listed as an approver to perform this action.'
                  : !buttonPerms &&
                    completionStatus !==
                      RequirementCompletionStatusEnum.Completed
                  ? 'Must be listed as an owner to perform this action.'
                  : ''
              }
            >
              <span>
                {completionStatus ===
                  RequirementCompletionStatusEnum.Completed && (
                  <Button
                    variant="outlined"
                    onClick={() => setRequestChangesDialogue(true)}
                    disabled={!buttonPerms}
                    sx={{ marginRight: '10px' }}
                  >
                    Request Changes
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() =>
                    (completionStatus ===
                      RequirementCompletionStatusEnum.Assigned &&
                      setRequestApprovalDialogue(true)) ||
                    (completionStatus !==
                      RequirementCompletionStatusEnum.Assigned &&
                      handleUpdateStatus(getNextStatus()))
                  }
                  disabled={
                    !buttonPerms ||
                    completionStatus ===
                      RequirementCompletionStatusEnum.Approved
                  }
                >
                  {getButtonText()}
                </Button>
              </span>
            </Tooltip>
          </ButtonGroup>
        )}
      </Box>
      <Dialog open={requestApprovalDialogue} sx={{ borderRadius: '8px' }}>
        <DialogTitle>Complete Requirement</DialogTitle>
        <DialogContent sx={{ fontSize: '0.8em' }}>
          Completing this requirement will send a request for requirement
          approval.
          <TextField
            label="Add a comment"
            variant="outlined"
            fullWidth
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRequestApprovalDialogue(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleRequestApproval(getNextStatus())}
            color="primary"
            autoFocus
          >
            Complete and Request Approval
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={requestChangesDialogue} sx={{ borderRadius: '8px' }}>
        <DialogTitle>Request Changes</DialogTitle>
        <DialogContent sx={{ fontSize: '0.8em' }}>
          Would you like to request changes to this requirement?
          <TextField
            label="Request changes"
            variant="outlined"
            fullWidth
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRequestChangesDialogue(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleRequestChanges(RequirementCompletionStatusEnum.Assigned)
            }
            color="primary"
            autoFocus
          >
            Request Changes
          </Button>
        </DialogActions>
      </Dialog>
      {auditId && (
        <Box {...cardProps} sx={{ mt: 2, mb: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography {...headingProps}>Audit Review</Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasUnsavedChanges}
              onClick={() => {
                handleUpdateAuditStatus(auditStatus);
                setHasUnsavedChanges(false);
              }}
            >
              Save Changes
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <ButtonGroup sx={{ mb: 2 }}>
              {Object.values(RequirementAuditStatusEnum).map(status => (
                <Button
                  key={status}
                  variant={auditStatus === status ? 'contained' : 'outlined'}
                  onClick={() => {
                    setAuditStatus(status);
                    setHasUnsavedChanges(true);
                  }}
                  startIcon={getAuditStatusIcon(status)}
                >
                  {formatGraphqlEnumToReadableText(status)}
                </Button>
              ))}
            </ButtonGroup>

            <TextField
              fullWidth
              multiline
              rows={4}
              label="Audit Comments"
              value={auditComments}
              onChange={e => {
                setAuditComments(e.target.value);
                setHasUnsavedChanges(true);
              }}
              sx={{ mb: 2 }}
            />

            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Evidence Notes
            </Typography>
            {evidences.length > 0 ? (
              evidences.map((evidence, index) => (
                <TextField
                  key={evidence?.id}
                  fullWidth
                  label={`Note for ${evidence?.title}`}
                  value={evidenceNotes[index] || ''}
                  onChange={e => {
                    const newNotes = [...evidenceNotes];
                    newNotes[index] = e.target.value;
                    setEvidenceNotes(newNotes);
                    setHasUnsavedChanges(true);
                  }}
                  sx={{ mb: 2 }}
                />
              ))
            ) : (
              <Typography color="text.secondary" sx={{ mb: 1 }}>
                No evidences have been added to this requirement.
              </Typography>
            )}
          </Box>
        </Box>
      )}

      <Box
        sx={{
          marginTop: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box display={'flex'} gap={2} flexDirection={'column'}>
          <Stack direction="row" spacing={2}>
            <Typography>
              <strong>Owner:</strong>{' '}
            </Typography>
            <UserList
              users={owners as User[]}
              variant="chip"
              size="small"
              editable={true}
              onUserChange={newUsers => handleUserChange('owner', newUsers)}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography>
              <strong>Approver:</strong>{' '}
            </Typography>
            <UserList
              users={approvers as User[]}
              variant="chip"
              size="small"
              editable={true}
              onUserChange={newUsers => handleUserChange('approver', newUsers)}
            />
          </Stack>
        </Box>
        <Box>
          <Typography>
            <strong>Created:</strong>{' '}
            {userStatus?.created ? formatDateTime(userStatus.created) : ''}
          </Typography>
          <Typography>
            <strong>Last edited:</strong>{' '}
            {userStatus?.lastModified
              ? formatDateTime(userStatus.lastModified)
              : ''}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ marginY: '12px', bgcolor: '#295581' }} />
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="Details" value="details" />
        <Tab label="Changes" value="changes" />
        <Tab label="Comments" value="comments" />
      </Tabs>
      {activeTab === 'details' && (
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="grid" gridTemplateColumns="4fr 6fr" gap="16px">
            <Box {...cardProps} display="flex" flexDirection="column">
              <Typography {...headingProps}>Status</Typography>
              <RequirementProgressStepper status={completionStatus} />
            </Box>
            <Box {...cardProps}>
              <Typography {...headingProps} marginBottom="16px">
                Description
              </Typography>
              <Typography>{description}</Typography>
            </Box>
          </Box>
          <Box {...cardProps}>
            <Typography {...headingProps} marginBottom="16px">
              Guidance
            </Typography>
            <Typography>{guidance}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="6fr 4fr" gap="20px">
            <Box
              {...cardProps}
              flex={1}
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <Box sx={{ '& > *': { mb: 2 } }}>
                <Typography {...headingProps}>Evidence</Typography>
                <Box display="flex" flexDirection={'column'} gap={2}>
                  {evidences.map((evidence, index) => (
                    <EvidenceCard
                      key={index}
                      evidence={evidence! as GovernanceEvidence}
                      handleEvidenceDelete={
                        !isReadOnly ? handleUnlinkEvidence : undefined
                      }
                      onEdit={!isReadOnly ? handleEditEvidence : undefined}
                      isReadOnly={isReadOnly}
                    />
                  ))}
                </Box>
                {evidences.length === 0 && (
                  <Typography color="text.secondary">
                    No evidence added yet.
                  </Typography>
                )}
                {!isReadOnly && (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={openEvidenceModal}
                    >
                      Add Evidence
                    </Button>
                  </Box>
                )}
                <Divider sx={{ my: 3, bgcolor: colorThemes.GREY_400 }} />
                {evidenceSuggestions && evidenceSuggestions.length > 0 && (
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                      Suggested Evidence:
                    </Typography>
                    <List>
                      {evidenceSuggestions.map((suggestion, index) => (
                        <ListItem key={index} sx={{ py: 0.5 }}>
                          <ListItemIcon sx={{ minWidth: '24px' }}>
                            <ArrowRightIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <ReactMarkdown
                                components={{
                                  a: ({ node, href, ...props }) => {
                                    const isExternal = href?.startsWith('http');
                                    return (
                                      <a
                                        {...props}
                                        href={href}
                                        style={{
                                          color: colorThemes.GREY_100,
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                        }}
                                        onClick={e => {
                                          if (!isExternal) {
                                            e.preventDefault();
                                            href && navigate(href);
                                          }
                                        }}
                                        {...(isExternal
                                          ? {
                                              target: '_blank',
                                              rel: 'noopener noreferrer',
                                            }
                                          : {})}
                                      />
                                    );
                                  },
                                }}
                              >
                                {suggestion?.description || ''}
                              </ReactMarkdown>
                            }
                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
            </Box>
            <Box {...cardProps} flex={1}>
              <Typography {...headingProps}>Specs</Typography>
              <List>
                {specifications?.map(spec => (
                  <ListItem
                    key={spec?.description}
                    sx={{ display: 'flex', gap: '4px' }}
                  >
                    {spec?.description}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      )}
      {activeTab === 'changes' && (
        <Box {...cardProps}>
          <Typography {...headingProps} marginBottom="16px">
            Change History
          </Typography>
          <Timeline position="alternate">
            {userStatus?.changeEvents?.map((event, index) => (
              <TimelineItem key={event?.id ?? index}>
                <TimelineOppositeContent color="text.secondary">
                  {formatDateTime(event?.timestamp ?? '')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    color={
                      event?.action === 'UPDATE'
                        ? 'primary'
                        : event?.action === 'CREATE'
                        ? 'success'
                        : 'error'
                    }
                  />
                  {index < (userStatus?.changeEvents?.length ?? 0) - 1 && (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    elevation={3}
                    sx={{ p: 2, bgcolor: 'background.default' }}
                  >
                    <Typography variant="h6" component="span">
                      {event?.action}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      By: {event?.user?.name}
                    </Typography>
                    {event?.changes?.map((change, changeIndex) => (
                      <FieldChangeDisplay key={changeIndex} change={change} />
                    ))}
                    {event?.listChanges?.map((change, changeIndex) => (
                      <ListChangeDisplay key={changeIndex} change={change} />
                    ))}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      )}
      {activeTab === 'comments' && (
        <Box {...cardProps}>
          <Typography {...headingProps} marginBottom="10px">
            Comments
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <TextField
                label="Add a comment"
                variant="outlined"
                fullWidth
                value={newComment}
                onChange={e => setNewComment(e.target.value)}
                sx={{ mb: 1, mr: 1, flex: 1, minHeight: '60px' }}
              />
              <LoadingButton
                variant="contained"
                onClick={handleAddComment}
                sx={{ mb: 1, ml: 1, mr: 1, minHeight: '60px' }}
                loading={loading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
          <Box>
            {comments
              ?.slice(0)
              .reverse()
              .map((comment, index) => (
                <Paper
                  key={index}
                  sx={{
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {formatDateTime(comment?.timestamp ?? '')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      minHeight: '60px',
                      '&:hover': {
                        '& .trash-icon': {
                          display: 'block',
                        },
                      },
                    }}
                  >
                    <Typography variant="body1">
                      <strong>
                        {comment?.user?.name ?? comment?.user?.id}:
                      </strong>{' '}
                      {comment?.comment}
                    </Typography>
                    {commentPerms && (
                      <IconButton
                        onClick={() => {
                          setDeleteConfirmOpen(true);
                          setDeleteCommentId(comment?.id ?? '');
                        }}
                        className="trash-icon"
                        sx={{
                          display: 'none',
                          transition: 'color 0.3s',
                          '&:hover': { cursor: 'pointer' },
                        }}
                      >
                        <TrashIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Dialog
                    open={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                  >
                    <DialogTitle>Confirm Comment Deletion</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete this comment? This
                        action cannot be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setDeleteConfirmOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          handleDeleteComment();
                          setDeleteConfirmOpen(false);
                        }}
                        color="error"
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              ))}
          </Box>
        </Box>
      )}
      <EvidenceModal
        open={isEvidenceModalOpen}
        onClose={handleCloseEvidenceModal}
        requirementStatusId={userStatus?.id ?? ''}
        editEvidence={editingEvidence}
      />
    </Box>
  );
}

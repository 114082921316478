import {
  Clear,
  Description,
  Download,
  OpenInNew,
  Edit,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Divider,
  Typography,
  Tooltip,
  Link,
  Collapse,
} from '@mui/material';
import { FC, useState } from 'react';
import { GovernanceEvidence } from '../__generated__/gql/graphql';

interface EvidenceCardProps {
  evidence: GovernanceEvidence;
  handleEvidenceDelete?: (id: string) => void;
  onEdit?: (evidence: GovernanceEvidence) => void;
  isReadOnly?: boolean;
}

const EvidenceCard: FC<EvidenceCardProps> = ({
  evidence,
  handleEvidenceDelete,
  onEdit,
  isReadOnly = false,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        bgcolor: '#295581',
        color: 'white',
        p: 2,
        borderRadius: 2,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100%',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
          bgcolor: '#3a6ea5',
        },
      }}
      onClick={() => setOpen(!open)}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Description />
          <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 600 }}>
            {evidence.title}
          </Typography>
        </Box>
        {!isReadOnly && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {onEdit && (
              <Tooltip title="Edit Evidence">
                <IconButton
                  size="small"
                  sx={{ color: 'white' }}
                  onClick={e => {
                    e.stopPropagation();
                    onEdit(evidence);
                  }}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {handleEvidenceDelete && (
              <Tooltip title="Delete Evidence">
                <IconButton
                  size="small"
                  sx={{ color: 'white' }}
                  onClick={e => {
                    e.stopPropagation();
                    handleEvidenceDelete(evidence.id ?? '');
                  }}
                >
                  <Clear fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
      <Collapse in={open} timeout={300}>
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <Divider sx={{ width: '100%', my: 1, borderColor: 'white' }} />
          {evidence.description && (
            <Typography
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            >
              {evidence.description}
            </Typography>
          )}
          {evidence.file && (
            <Link
              component="a"
              href={evidence.file.url ?? ''}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
              onClick={e => e.stopPropagation()}
            >
              <Download sx={{ mr: 1 }} />
              <Typography variant="body2" noWrap>
                Download File
              </Typography>
            </Link>
          )}
          {evidence.externalUrl && (
            <Link
              component="a"
              href={evidence.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
                p: 0.5,
                bgcolor: '#4379c5',
                borderRadius: 1,
                '&:hover': {
                  bgcolor: '#5893e3',
                },
                width: 'fit-content',
              }}
              onClick={e => e.stopPropagation()}
            >
              <OpenInNew sx={{ mr: 1, fontSize: '16px' }} />
              <Typography variant="body2" fontSize={'12px'} noWrap>
                Visit External URL
              </Typography>
            </Link>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default EvidenceCard;

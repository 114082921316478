import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import ConfirmDialog from './ConfirmDialog';

interface ConfirmOptions {
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface ConfirmCtxType {
  show: (confirmOptions: ConfirmOptions) => void;
}

export const ConfirmCtx = createContext<ConfirmCtxType | null>(null);

interface Props {
  children: ReactNode;
}

export function UnsavedChangesProvider({ children }: Props) {
  const [confirm, setConfirm] = useState<ConfirmOptions | null>(null);
  const [open, setOpen] = useState(false);

  const show = useCallback((confirmOptions: ConfirmOptions) => {
    setConfirm(confirmOptions);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setConfirm(null);
  }, []);

  const onConfirm = useCallback(() => {
    confirm?.onConfirm?.();
    handleClose();
  }, [confirm, handleClose]);

  const onCancel = useCallback(() => {
    confirm?.onCancel?.();
    handleClose();
  }, [confirm, handleClose]);

  const value = useMemo(() => ({ show }), [show]);

  return (
    <ConfirmCtx.Provider value={value}>
      {confirm && (
        <ConfirmDialog onCancel={onCancel} onConfirm={onConfirm} open={open} />
      )}
      {children}
    </ConfirmCtx.Provider>
  );
}

import { Box, Card, CardContent, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from '../../../../utils/dateTimeHelpers';
import {
  DocumentationComponentResult,
  DocumentationResultObject,
} from '../../../../__generated__/gql/graphql';
import { colorThemes } from '../../../../theme';
import {
  MermaidGraphicOutput,
  MLFramework,
  Standard,
  TaskType,
} from '../../../../__generated__/pydantic_parsers';
import MermaidGraphic from '../../../../components/MermaidGraphic';
import TextResultCard from './documentation_result/TextResultCard';
import { FactViewer } from './documentation_result/FactViewer';
import ArtifactViewer from './documentation_result/ArtifactViewer';

export const DocumentationResultViewer = ({
  creationTimestamp,
  documentationResult,
}: {
  creationTimestamp: string;
  documentationResult:
    | DocumentationResultObject['generatedDocumentation']
    | null
    | undefined;
}) => {
  const formattedDateTime = formatDateTime(creationTimestamp);

  return (
    <>
      {creationTimestamp && (
        <Typography variant="subtitle1" color="textSecondary">
          Generated: {formattedDateTime}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '12px',
          height: 'calc(100vh - 230px)',
          overflowY: 'auto',
        }}
      >
        {documentationResult ? (
          documentationResult
            .filter(componentResult => componentResult !== null)
            .map(componentResult => (
              <DocumentationResultCard
                component={componentResult!}
                key={`componentResult-${componentResult?.name}-${uuidv4()}`}
              />
            ))
        ) : (
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontSize: '1.2rem',
              marginTop: '1rem',
            }}
          >
            No documentation generated yet. Click the Generate Documentation
            button to get started.
          </Typography>
        )}
      </Box>
    </>
  );
};

interface DocumentationResultCardProps {
  component: DocumentationComponentResult;
}

function DocumentationResultCard({ component }: DocumentationResultCardProps) {
  let content = null;
  switch (component.outputFormat) {
    case 'Standard':
      content = (
        <TextResultCard parsedOutput={JSON.parse(component.text) as Standard} />
      );
      break;
    case 'TaskType':
      content = (
        <CardContent>
          <FactViewer
            factResult={(JSON.parse(component.text) as TaskType).task_type}
          />
        </CardContent>
      );
      break;
    case 'MLFramework':
      content = (
        <CardContent>
          <FactViewer
            factResult={
              (JSON.parse(component.text) as MLFramework).ml_framework
            }
          />
        </CardContent>
      );
      break;
    case 'MermaidGraphicOutput':
      content = (
        <CardContent>
          <MermaidGraphic
            chart={
              (JSON.parse(component.text) as MermaidGraphicOutput).md_string
            }
          />
        </CardContent>
      );
      break;
    default:
      content = <CardContent>{component.text}</CardContent>;
  }
  return (
    <Card sx={{ backgroundColor: '#E9F2F7', width: '100%', flexShrink: 0 }}>
      <CardContent
        style={{
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
        }}
      >
        <Typography color={colorThemes.DARK_BLUE_600} variant="h6">
          {component.name}
        </Typography>
        {(!component.artifacts || component.artifacts.length === 0) && (
          <Box color={colorThemes.DARK_BLUE_600}>{content}</Box>
        )}
        {component.artifacts?.map(artifact => (
          <ArtifactViewer key={artifact?.id} artifact={artifact!} />
        ))}
      </CardContent>
    </Card>
  );
}

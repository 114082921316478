import { Card, Typography } from '@mui/material';
import { axisClasses, BarChart } from '@mui/x-charts';
import { GetGovernanceFrameworkDashboardQuery } from '../../__generated__/gql/graphql';
import { colorThemes } from '../../theme';

export default function RequirementStatusDistributionChart({
  data,
}: {
  data: GetGovernanceFrameworkDashboardQuery['governanceFrameworksDashboard'];
}) {
  const statusDistribution = data?.requirementStageStatistics;
  const categories = ['Not Started', 'Assigned', 'Completed', 'Approved'];
  const counts = [
    statusDistribution?.numNotStarted || 0,
    statusDistribution?.numAssigned || 0,
    statusDistribution?.numCompleted || 0,
    statusDistribution?.numApproved || 0,
  ];

  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: colorThemes.DARK_BLUE_600,
        justifyContent: 'center',
        gap: '16px',
        padding: 1,
        height: 300,
        border: '2px solid #334E68',
      }}
    >
      <Typography variant="h6" textAlign="center" gutterBottom>
        Requirement Status Distribution
      </Typography>
      <BarChart
        xAxis={[
          {
            scaleType: 'band',
            data: categories,
            colorMap: {
              type: 'ordinal',
              colors: [
                '#00a6fb',
                '#0582ca',
                '#006494',
                '#003554',
                '#03273C',
                '#051923',
              ],
            },
          },
        ]}
        series={[
          {
            data: counts,
          },
        ]}
        sx={{
          borderRadius: '6px 6px 8px 8px',
          marginBottom: '36px',
          bgcolor: '#efeae4',
          color: 'black',
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tick}, .${axisClasses.line}`]: {
              stroke: 'black',
              strokeWidth: 1,
            },
            [`.${axisClasses.tickLabel}`]: {
              fill: 'black',
            },
          },
        }}
      />
    </Card>
  );
}

import { Card, CardContent, Typography } from '@mui/material';
import ProjectIntegrations from '../project_view/ProjectIntegrations';
import { useParams } from 'react-router-dom';

export const Integrations = () => {
  const { projectId } = useParams();

  return (
    <Card variant="outlined" sx={{ width: '100%', height: '100%' }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Integrations
        </Typography>
        {projectId && <ProjectIntegrations projectID={projectId} />}
      </CardContent>
    </Card>
  );
};

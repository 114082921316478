import { useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
export const ADD_GOOGLE_DRIVE_CREDENTIALS = gql`
  mutation AddGoogleDriveCredentials($redirectURL: String!) {
    addGoogleDriveCredentials(redirectUrl: $redirectURL) {
      user {
        email
      }
    }
  }
`;

export default function OAuthRedirectPage() {
  const navigate = useNavigate();
  const [addGoogleDriveCredentials, { loading, error }] = useMutation(
    ADD_GOOGLE_DRIVE_CREDENTIALS
  );
  // we need to guarantee the mutation is only called once
  // as the mutation fails if it's called again with
  // the same token
  const hasCalledMutation = useRef(false);

  useEffect(() => {
    if (!hasCalledMutation.current) {
      addGoogleDriveCredentials({
        ignoreResults: true,
        variables: {
          redirectURL: window.location.href,
        },
      }).then(() => {
        const oAuthRedirectUrl = localStorage.getItem('oAuthRedirectUrl');
        navigate(oAuthRedirectUrl ?? '/documentation');
      });
      hasCalledMutation.current = true;
    }
  }, [addGoogleDriveCredentials, navigate]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;
  return (
    <>
      <div>Signed in</div>
    </>
  );
}

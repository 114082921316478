import { Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { colorThemes } from '../theme';

interface Props {
  elements: string[];
  selectedElement?: string;
  setSelectedElement: (newSelection: string) => void;
  noElementText?: string;
  color?: string;
}

export default function DropdownSelector({
  elements,
  selectedElement,
  setSelectedElement,
  noElementText = 'No elements available',
  color = 'black',
}: Props): JSX.Element {
  const lastElement = elements[elements.length - 1];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const defaultValue =
    selectedElement ??
    (elements.length > 0 ? elements[elements.length - 1] : noElementText);

  return (
    <Select
      size="small"
      value={defaultValue}
      displayEmpty
      open={isDropdownOpen}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
            overflow: 'auto',
          },
        },
      }}
      sx={{
        width: 120,
        color,
        padding: '4px 8px',

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },

        '& .MuiOutlinedInput-input': {
          padding: '0',
        },

        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: color,
            border: 2,
          },
        },

        '& .MuiSvgIcon-root': {
          color,
        },

        '.MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
          backgroundColor: colorThemes.GREY_100,
          color,
        },
      }}
      onChange={event => setSelectedElement(event.target.value as string)}
      renderValue={selected =>
        selected === lastElement
          ? isDropdownOpen
            ? `Latest -- ${selected}`
            : 'Latest'
          : selected
      }
    >
      {elements.length > 0 ? (
        elements.map(element => (
          <MenuItem key={element} value={element}>
            {element === lastElement ? `Latest -- ${element}` : element}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled value={noElementText}>
          No element available
        </MenuItem>
      )}
    </Select>
  );
}

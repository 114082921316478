import React, { useState, useMemo } from 'react';
import {
  Box,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  CircularProgress,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { User } from '../__generated__/gql/graphql';
import { useLazyQuery, gql } from '@apollo/client';
import UserTag from './UserTag';

const USERS_BASIC_INFO = gql`
  query GetAvailableUsers {
    allUsers {
      id
      name
      email
      __typename
    }
  }
`;

type EditableUserTagProps = {
  users?: (User | null)[];
  size?: 'small' | 'medium';
  variant?: 'avatar' | 'chip' | 'full' | 'text';
  onUserChange: (newUsers: User[] | null) => void;
};

const EditableUserTag: React.FC<EditableUserTagProps> = ({
  users = [],
  size = 'small',
  variant = 'avatar',
  onUserChange,
}) => {
  const initialUserIds = users.filter(Boolean).map(user => user!.id);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedUserIds, setSelectedUserIds] =
    useState<string[]>(initialUserIds);

  const [fetchUsers, { loading, error, data }] = useLazyQuery(USERS_BASIC_INFO);

  const allUsers = useMemo(() => {
    if (data?.allUsers) {
      return data.allUsers as User[];
    } else if (users.length > 0) {
      return users.filter(Boolean) as User[];
    } else {
      return [];
    }
  }, [data, users]);

  const handleEditClick = () => {
    setIsEditing(true);
    fetchUsers();
  };

  const handleUserChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    setSelectedUserIds(value);
  };

  const handleClose = () => {
    setIsEditing(false);
    const selectedUsers = allUsers.filter(user =>
      selectedUserIds.includes(user.id)
    );
    onUserChange(selectedUsers);
  };

  const getUserById = (id: string) => {
    return allUsers.find(user => user.id === id) || null;
  };

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        border: '1px solid #ccc',
        borderRadius: '16px',
        '&:hover .edit-icon': { opacity: 1 },
      }}
    >
      {!isEditing && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          {selectedUserIds.length > 0 ? (
            selectedUserIds.map((userId: string) => {
              const user = getUserById(userId);
              return (
                <UserTag
                  key={userId}
                  user={user}
                  variant={variant}
                  size={size}
                />
              );
            })
          ) : (
            <Typography variant={'body2'} component="div" sx={{ mx: '8px' }}>
              No users selected
            </Typography>
          )}
        </Box>
      )}

      {!isEditing && (
        <IconButton
          size="small"
          onClick={handleEditClick}
          className="edit-icon"
          sx={{
            opacity: 0,
            transition: 'opacity 0.2s',
            position: 'absolute',
            right: '-30px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      )}

      {isEditing && (
        <FormControl
          fullWidth
          sx={{
            width: 'auto',
            margin: '4px 0',
          }}
        >
          <Select
            multiple
            value={selectedUserIds}
            onChange={handleUserChange}
            input={<OutlinedInput label="Select Users" />}
            renderValue={selected => {
              const selectedIds = selected as string[];
              const selectedUsers = allUsers.filter(user =>
                selectedIds.includes(user.id)
              );
              return selectedUsers
                .map(user => user.name ?? user.email)
                .join(', ');
            }}
            onClose={handleClose}
            size="small"
            sx={{
              '.MuiSelect-select': {
                padding: '0px 32px 0px 8px !important',
                fontSize: '12px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: '0px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: '0px',
              },
              '.MuiSelect-icon': {
                color: 'white',
              },
            }}
          >
            {loading && (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            )}
            {error && <MenuItem disabled>Error loading users</MenuItem>}
            {allUsers.map((user: User) => (
              <MenuItem key={user.id} value={user.id}>
                <Checkbox
                  checked={selectedUserIds.includes(user.id)}
                  size="small"
                />
                <ListItemText primary={user.name ?? user.email} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default EditableUserTag;

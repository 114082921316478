import { Box, Card, CardContent, Typography } from '@mui/material';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { colorThemes } from '../../theme';

export default function AverageSeverityCircularDashboard({
  value,
}: {
  value: number;
}) {
  const adjustedValue = (value / 3) * 100;
  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: colorThemes.DARK_BLUE_600,
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        height: 300,
        transition: 'all .2s',
        border: '2px solid #334E68',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">Average Severity</Typography>
        <Box sx={{ width: 120, height: 120 }}>
          <CircularProgressbarWithChildren
            value={adjustedValue}
            counterClockwise
            styles={buildStyles({
              pathColor: getColor(adjustedValue),
              trailColor: colorThemes.GREY_400,
            })}
            children={
              <Typography variant="h4">{truncateNumber(value)}</Typography>
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
}

function getColor(value: number) {
  if (value <= 33.4) {
    return '#27cc1f';
  } else if (value <= 66.78) {
    return '#cc921f';
  }
  return '#cc1f1f';
}

function truncateNumber(number: number): number {
  return Math.trunc(number * 10) / 10;
}

import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Typography, Select, MenuItem, Icon } from '@mui/material';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { colorThemes } from '../../theme';

interface CircularGraph {
  name: string;
  options: { label: string; value: number }[];
  label: string;
}

interface CustomSelectProps {
  title: string;
  graphs: CircularGraph[];
  control: any;
  readOnly?: boolean;
}

function CircularProgressDropdown({
  title,
  graphs,
  control,
  readOnly = false,
}: CustomSelectProps) {
  const watchedValues = useWatch({
    control,
    name: graphs.map(graph => graph.name),
  });

  const nestedProgressBars = graphs.reduce(
    (acc: React.ReactNode, graph, index) => {
      return renderCircularProgressbar(watchedValues[index], graph, index, acc);
    },
    null as React.ReactNode
  );

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={'column'}
      sx={{ width: 330, height: 250 }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          height: 250,
        }}
      >
        {nestedProgressBars}
        <Box display={'flex'} flexDirection={'column'}>
          {graphs.map((graph, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box display={'flex'}>
                <Icon style={{ fontSize: 20, color: getColor(index) }}>•</Icon>
                <Typography variant="subtitle1">{graph.label}</Typography>
              </Box>
              <Controller
                name={graph.name}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={readOnly}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: colorThemes.GREY_100,
                        },
                      },
                    }}
                    sx={{
                      width: 150,
                      height: 35,
                      bgcolor: colorThemes.GREY_100,
                      color: 'black',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: colorThemes.GREY_300,
                      },
                      '& .MuiSelect-icon': {
                        color: 'black',
                      },
                      '&.Mui-disabled': {
                        bgcolor: colorThemes.GREY_100,
                        '& .MuiSelect-select': {
                          opacity: 1,
                          WebkitTextFillColor: 'black !important',
                        },
                        '& .MuiSelect-icon': {
                          display: 'none',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  >
                    {graph.options.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={{
                          color: 'black',
                          bgcolor: colorThemes.GREY_100,
                          '&:hover': { bgcolor: colorThemes.GREY_300 },
                          '&.Mui-selected': {
                            bgcolor: colorThemes.GREY_200,
                          },
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CircularProgressDropdown;

function renderCircularProgressbar(
  value: number,
  graph: CircularGraph,
  index: number,
  children: React.ReactNode
) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: 120 - index * 30,
        height: 120 - index * 30,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgressbarWithChildren
          value={100}
          counterClockwise
          strokeWidth={8 + index * 2}
          styles={buildStyles({
            pathColor: 'white',
          })}
        >
          {children}
        </CircularProgressbarWithChildren>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgressbarWithChildren
          value={((value ?? 0) / (graph.options.length - 1)) * 100}
          counterClockwise
          strokeWidth={8 + index * 2}
          styles={buildStyles({
            pathTransitionDuration: 0.5,
            pathColor: getColor(index),
            trailColor: 'transparent',
          })}
        >
          {children}
        </CircularProgressbarWithChildren>
      </Box>
    </Box>
  );
}

function getColor(index: number) {
  switch (index % 4) {
    case 0:
      return '#3a89ff';
    case 1:
      return '#8CBAE4';
    case 2:
      return '#295581';
  }
}

import React from 'react';
import { Typography, Stack, Tooltip, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserTag from './UserTag';
import EditableUserTag from './EditableUserTag';
import { User } from '../__generated__/gql/graphql';
import { colorThemes } from '../theme';

type UserListProps = {
  users: (User | null)[] | null;
  emptyMessage?: string;
  remediationMessage?: string;
  variant?: 'avatar' | 'chip' | 'full' | 'text';
  size?: 'small' | 'medium';
  editable?: boolean;
  onUserChange?: (newUsers: User[] | null) => void;
};

const UserList: React.FC<UserListProps> = ({
  users,
  emptyMessage = 'Not assigned',
  remediationMessage,
  variant = 'avatar',
  size = 'medium',
  editable = false,
  onUserChange,
}) => {
  const isSmall = size === 'small';
  const typographyVariant = isSmall ? 'body2' : 'body1';

  const renderHelpIcon = () =>
    remediationMessage && (
      <Tooltip title={remediationMessage}>
        <HelpOutlineIcon
          sx={{
            fontSize: isSmall ? '0.875rem' : '1rem',
            color: colorThemes.DARK_BLUE_700,
            marginLeft: '4px',
          }}
        />
      </Tooltip>
    );

  if (editable) {
    return (
      <EditableUserTag
        users={users ?? []}
        variant={variant}
        size={size}
        onUserChange={onUserChange}
      />
    );
  }

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
      {(users ?? []).length > 0 ? (
        (users ?? []).map((user, index) => (
          <Box
            key={user?.id || `empty-${index}`}
            style={{ marginBottom: '8px' }}
          >
            <UserTag user={user} variant={variant} size={size} />
          </Box>
        ))
      ) : (
        <Box
          style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}
        >
          <Typography variant={typographyVariant} component="div">
            {emptyMessage}
          </Typography>
          {renderHelpIcon()}
        </Box>
      )}
    </Stack>
  );
};

export default UserList;

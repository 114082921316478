import { Stack, Box, IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditableTypography from './EditableTypography';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface StackEditorProps {
  stacks: string[];
  isEditable: boolean;
  setStacks: (stacks: string[]) => void;
}

const StackEditor: React.FC<StackEditorProps> = ({
  stacks,
  isEditable,
  setStacks,
}) => {
  const handleAddStack = () => {
    const newStack = 'New Element';
    setStacks([...stacks, newStack]);
  };

  const handleDeleteStack = (index: number) => {
    const updatedStacks = [...stacks];
    updatedStacks.splice(index, 1);
    setStacks(updatedStacks);
  };

  const handleEditStack = (index: number, newValue: string) => {
    const updatedStacks = [...stacks];
    updatedStacks[index] = newValue;
    setStacks(updatedStacks);
  };
  return (
    <>
      <Stack spacing={1} direction={'column'}>
        {stacks.map((stack, index) => (
          <Box
            key={index}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={'2px'}
            minHeight={'36px'}
          >
            <NavigateNextIcon />
            <EditableTypography
              onChange={e => handleEditStack(index, e as string)}
              isEditable={isEditable}
            >
              {stack}
            </EditableTypography>
            <Box
              visibility={isEditable ? 'visible' : 'hidden'}
              minWidth={'48px'}
            >
              <IconButton
                onClick={() => handleDeleteStack(index)}
                aria-label="delete"
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Stack>
      {isEditable && (
        <>
          <IconButton
            onClick={() => handleAddStack()}
            aria-label="delete"
            size="small"
          >
            <AddIcon />
          </IconButton>
        </>
      )}
    </>
  );
};

export default StackEditor;

import { Card, CardContent, Modal } from '@mui/material';
import ExperimentMetricChart from './ExperimentMetricChart';

interface ChartViewModalProps {
  data: [string, Array<any>] | undefined;
  onClose: () => void;
}

export default function ChartViewModal({ data, onClose }: ChartViewModalProps) {
  return (
    <Modal
      open={data !== undefined}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card sx={{ minWidth: 600, width: '80%' }}>
        <CardContent>
          <ExperimentMetricChart
            metric={data?.[0] as string}
            data={data?.[1] as Array<any>}
            preview={false}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}

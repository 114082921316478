import { Box, Button, Typography } from '@mui/material';
import { useState, useCallback } from 'react';
import TableHead from './TableHead';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import {
  UserResponsibilityEnum,
  UserRoleEnum,
  UsersItemsQuery,
} from '../__generated__/gql/graphql';
import UserRow from './UserRow';
import { ToastContainer, toast } from 'react-toastify';
import { useUnsavedChanges } from '../hooks/useUnsavedChanges';

export const GET_USERS = gql(`
  query UsersItems{
  allUsers{
    id
    name
    email
    responsibilities
    roles
    proofOfQualification
  }
}
`);

export const UPDATE_USERS = gql(`
  mutation UpdateUserRoles($updatedUsers: [RolesAndResponsibilitiesInput]) {
  updateUserRoles(updatedUsers: $updatedUsers) {
    users {
      id
      name
      roles
      responsibilities
    }
  }
}
`);

export const RoleResponsibilityMap: Record<
  UserRoleEnum,
  UserResponsibilityEnum[]
> = {
  DEVELOPER: [
    UserResponsibilityEnum.RiskManagement,
    UserResponsibilityEnum.Safety,
    UserResponsibilityEnum.Privacy,
    UserResponsibilityEnum.Development,
    UserResponsibilityEnum.Performance,
    UserResponsibilityEnum.DataQa,
  ],
  AI_LEAD: [
    UserResponsibilityEnum.AiSystem,
    UserResponsibilityEnum.Security,
    UserResponsibilityEnum.HumanOver,
    UserResponsibilityEnum.AiPolicy,
  ],
  PROJECT_MANAGEMENT: [
    UserResponsibilityEnum.AssetRscManage,
    UserResponsibilityEnum.OrgContext,
  ],
  MANAGEMENT: [UserResponsibilityEnum.Approval],
  COMPLIANCE: [
    UserResponsibilityEnum.SupplierRel,
    UserResponsibilityEnum.LegalReq,
    UserResponsibilityEnum.AiPolicy,
  ],
};

export interface UserProps {
  id: string;
  name: string;
  email: string;
  responsibilities: UserResponsibilityEnum[];
  roles: UserRoleEnum[];
  proofOfQualification: string;
}

export default function UserManagementPage() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data: rawData } = useSuspenseQuery<UsersItemsQuery>(GET_USERS);

  const userData: UserProps[] = [];
  rawData?.allUsers?.forEach(user => {
    if (user) {
      userData.push({
        id: user.id,
        name: user.name ?? '',
        email: user.email,
        responsibilities:
          user.responsibilities?.filter(
            (resp): resp is UserResponsibilityEnum => resp !== null
          ) ?? [],
        roles:
          user.roles?.filter((role): role is UserRoleEnum => role !== null) ??
          [],
        proofOfQualification: user.proofOfQualification ?? '',
      });
    }
  });
  const [users, setUsers] = useState(userData);

  const [updateUsersRoles] = useMutation(UPDATE_USERS, {
    onCompleted: () => {
      toast.success('Users saved');
    },
    onError: error => {
      toast.error(`Error saving Users: ${error.message}`);
    },
    variables: {
      updatedUsers: users.map(user => ({
        userId: user.id,
        roles: user.roles,
        responsibilities: user.responsibilities,
        proofOfQualification: user.proofOfQualification,
      })),
    },
  });

  let initialAvailableResponsibilities = Object.values(UserResponsibilityEnum);
  users?.forEach(user => {
    user?.responsibilities?.forEach(resp => {
      initialAvailableResponsibilities =
        initialAvailableResponsibilities.filter(
          responsibility => responsibility !== resp
        );
    });
  });

  const [availableResponsibilities, setAvailableResponsibilities] = useState(
    initialAvailableResponsibilities
  );

  function toggleRole(role: UserRoleEnum, index: number) {
    const newUsers = [...users];
    const user = newUsers[index];
    // Guard Clauses
    if (!user) {
      return;
    }
    if (user.roles === undefined || user.roles === null) {
      user.roles = [];
    }
    if (user.responsibilities === undefined || user.responsibilities === null) {
      user.responsibilities = [];
    }
    if (!user.roles.includes(role)) {
      user.roles.push(role);
      RoleResponsibilityMap[role].forEach(responsibility => {
        if (
          availableResponsibilities.includes(responsibility) &&
          !user.responsibilities?.includes(responsibility)
        ) {
          user.responsibilities?.push(responsibility);
          setAvailableResponsibilities(prev =>
            prev.filter(resp => resp !== responsibility)
          );
        }
      });
    } else {
      user.roles = user.roles.filter(currentRole => currentRole !== role);
      RoleResponsibilityMap[role].forEach(responsibility => {
        if (user.responsibilities?.includes(responsibility)) {
          user.responsibilities = user.responsibilities.filter(
            currentResponsibility => currentResponsibility !== responsibility
          );
          setAvailableResponsibilities(prev => [...prev, responsibility]);
        }
      });
    }
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  function removeResponsibility(
    index: number,
    responsibility: UserResponsibilityEnum
  ) {
    const newUsers = [...users];
    const user = newUsers[index];
    if (!user) {
      return;
    }
    user.responsibilities = user.responsibilities?.filter(
      currentResponsibility => currentResponsibility !== responsibility
    );
    setAvailableResponsibilities(prev => [...prev, responsibility]);
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  function addResponsibility(
    index: number,
    responsibility: UserResponsibilityEnum
  ) {
    const newUsers = [...users];
    const user = newUsers[index];
    if (!user) {
      return;
    }
    user.responsibilities?.push(responsibility);
    setAvailableResponsibilities(prev =>
      prev.filter(resp => resp !== responsibility)
    );
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  function onWrite(index: number, input: string) {
    const newUsers = [...users];
    const user = newUsers[index];
    if (!user) {
      return;
    }
    user.proofOfQualification = input;
    newUsers[index] = { ...user };
    setUsers(newUsers);
  }

  const isDirty = useCallback(() => {
    return JSON.stringify(users) !== JSON.stringify(userData);
  }, [users, userData]);

  useUnsavedChanges({ isDirty: isDirty() });

  return (
    <>
      <Typography variant="h4">Roles & Permissions</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ marginTop: '15vh', width: '90%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <TableHead
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              disableSearch={true}
            />
            {users
              // .filter(
              //   user =>
              //     user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
              // )
              .map(
                (user, index) =>
                  user && (
                    <UserRow
                      user={user}
                      index={index}
                      key={index}
                      addResponsibility={addResponsibility}
                      toggleRole={toggleRole}
                      removeResponsibility={removeResponsibility}
                      availableResponsibilities={availableResponsibilities}
                      onWrite={onWrite}
                    />
                  )
              )}
          </Box>
        </Box>

        <ToastContainer />
      </Box>
      <Box
        sx={{
          right: '1rem',
          bottom: '1rem',
          position: 'absolute',
        }}
      >
        <Button onClick={() => updateUsersRoles()}>Save Changes</Button>
      </Box>
    </>
  );
}

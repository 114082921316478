import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import { FC, useState } from 'react';
import RiskAssessmentCharts from './RiskAssessmentCharts';
import { RiskAssessmentFrameworkMaterialized } from '../../__generated__/gql/graphql';
import { ArrowForwardIos } from '@mui/icons-material';

interface RiskAssessmentScoreProps {
  riskAssessmentFrameworks: RiskAssessmentFrameworkMaterialized[] | [];
}

const RiskAssessmentScoreOverview: FC<RiskAssessmentScoreProps> = ({
  riskAssessmentFrameworks,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  return (
    <Box display="flex" height={'100%'} width={'100%'}>
      <List component="nav" sx={{ pt: '16px' }}>
        {riskAssessmentFrameworks.map((framework, index) => (
          <ListItemButton
            key={framework.riskAssessmentReference?.id}
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index)}
            sx={{ display: 'flex', gap: '10px', borderRadius: '8px' }}
          >
            <ListItemText primary={framework.name ?? ''} />
            <ArrowForwardIos sx={{ fontSize: '12px' }} />
          </ListItemButton>
        ))}
      </List>
      <Box flexGrow={1} p={2}>
        {selectedIndex === null ? (
          <Box>Select a risk assessment to see the details.</Box>
        ) : (
          <RiskAssessmentCharts
            selectedAssessment={
              riskAssessmentFrameworks[selectedIndex].riskAssessmentReference!
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default RiskAssessmentScoreOverview;

import { Box, CardHeader, TextField } from '@mui/material';
import { FC } from 'react';
import { colorThemes } from '../../theme';
import { RiskAssessment } from '../../__generated__/gql/graphql';
import { Control, Controller } from 'react-hook-form';
import { RISK_FORM_PREFIX } from '../risk_assessment/RiskAssessment';

interface ResidualRiskSectionProps {
  control: Control<RiskAssessment, object>;
  activeRiskSectionId: number;
}

const ResidualRiskSection: FC<ResidualRiskSectionProps> = ({
  control,
  activeRiskSectionId,
}) => {
  return (
    <Box
      bgcolor={colorThemes.GREY_100}
      color="black"
      borderRadius="8px"
      display="block"
      width="auto"
      height="auto"
    >
      <CardHeader title="Residual Risk" sx={{ fontWeight: 'bold' }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Controller
          name={`${RISK_FORM_PREFIX}.${activeRiskSectionId}.residualRisk`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Description"
              onFocus={() => field.onChange('')}
              sx={{
                margin: '16px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  color: 'black',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&.Mui-disabled fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                  color: 'black',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
                width: '600px',
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ResidualRiskSection;

import { Box, Card, CardContent, Typography } from '@mui/material';
import { colorThemes } from '../../theme';

export default function NumberDisplay({
  value,
  title,
}: {
  value: number;
  title: string;
}) {
  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: colorThemes.DARK_BLUE_600,
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        height: 300,
        transition: 'all .2s',
        border: '2px solid #334E68',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <Box
          sx={{
            width: 120,
            height: 120,
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2">{value}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

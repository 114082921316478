import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';

interface TooltipWithIconProps {
  title: string;
  href?: string;
}

export default function TooltipWithIcon({ title, href }: TooltipWithIconProps) {
  const tooltipTitle = href ? (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      {title}
    </Link>
  ) : (
    title
  );

  return (
    <Tooltip title={tooltipTitle} arrow>
      <IconButton>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const INITIALIZE_USER_AND_ORGANIZATION = gql`
  mutation InitializeUserAndOrganization {
    initializeUserAndOrganization {
      user {
        id
        name
        email
      }
      organization {
        id
        name
      }
    }
  }
`;

export default function WelcomePage() {
  const navigate = useNavigate();
  const [initializeUserAndOrganization] = useMutation(
    INITIALIZE_USER_AND_ORGANIZATION
  );

  const registerNewUser = async () => {
    try {
      await initializeUserAndOrganization();
      toast.success('User and organization initialized successfully!');
      navigate('/projects');
    } catch (err) {
      toast.error(`An unexpected error occurred: ${err}`);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={4}
      gap={4}
    >
      <Typography variant="h3" align="center">
        Welcome to Trail 🚀
      </Typography>
      <Typography variant="h6" align="center" maxWidth="800px">
        Trail offers the simplest way to operationalize AI Governance and the EU
        AI Act, empowering developers and businesses to create trustworthy AI
        solutions.
      </Typography>

      <Box width="100%">
        <Grid container spacing={4} mt={1} justifyContent="center">
          {[
            {
              title: 'AI Policy',
              description:
                'Build your own AI policy based on latest standards. Guided by a practical questionnaire that leverages your organizational information.',
            },
            {
              title: 'AI Registry',
              description:
                'Collect and manage all your AI use cases. Classify, compare and evaluate use cases based on risk and impact.',
            },
            {
              title: 'Workflows',
              description:
                'Automatically translate requirements into actionable tasks and have your compliance status at sight. ',
            },
            {
              title: 'Risk Management',
              description:
                'Manage AI risks based on the latest standards and risk assessments — within trail or integrated into your existing Risk Management process.',
            },
            {
              title: 'Audit Trail',
              description:
                'Create transparency during AI development. Combine data, models, hypotheses and results in one central place for increased auditability.',
            },
            {
              title: 'Automated Documentation',
              description:
                'Save time through accurate, automated documentation — both for technical and compliance purposes.',
            },
          ].map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card elevation={3} sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1">{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4} width="100%" textAlign="center">
        <Typography variant="h4" gutterBottom>
          Let's start building AI you can trust.
        </Typography>
        <Typography variant="body1" maxWidth="800px" margin="auto">
          Stop worrying about documentation, knowledge loss, or governance and
          start leveraging AI.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={registerNewUser}
          sx={{ mt: '20px' }}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
}

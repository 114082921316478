import { Box, CardActions } from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { GetEvaluationsQuery } from '../../../__generated__/gql/graphql';
import { useProject } from '../../../contexts/project';
import EvaluationInformation from './EvaluationInformation';

const RUN_EVALUATION_MUTATION = gql`
  mutation RunEvaluation($evaluationId: String!, $projectId: String!) {
    runEvaluation(evaluationId: $evaluationId, projectId: $projectId) {
      result {
        id
        progress
        outcome
        reason
        sources
        startTime
        endTime
      }
    }
  }
`;

// I love typescript acrobatics
type ExperimentType = NonNullable<GetEvaluationsQuery['experiment']>;
type EvaluationsArrayType = NonNullable<ExperimentType['evaluations']>;
type SingleEvaluationType = NonNullable<
  EvaluationsArrayType extends (infer U)[] ? U : never
>;

interface Props {
  evaluation: SingleEvaluationType;
  experimentId: string;
  queryCacheUpdate: TypedDocumentNode;
}

export default function EvaluationCard({
  evaluation,
  experimentId,
  queryCacheUpdate,
}: Props) {
  const { project } = useProject();

  const [runEvaluation, { loading }] = useMutation(RUN_EVALUATION_MUTATION, {
    variables: { evaluationId: evaluation.id, projectId: project?.id },
    update: (cache, { data: { runEvaluation } }) => {
      const newResult = runEvaluation.result;

      const { experiment } = cache.readQuery({
        query: queryCacheUpdate,
        variables: { experimentId },
      }) as any;

      const updatedEvaluations = (experiment.evaluations ?? []).map(
        (cachedEvaluation: any) => {
          if (cachedEvaluation.id === evaluation.id) {
            return {
              ...cachedEvaluation,
              mostRecentResult: newResult,
            };
          }
          return cachedEvaluation;
        }
      );

      cache.writeQuery({
        query: queryCacheUpdate,
        data: {
          experiment: {
            ...experiment,
            evaluations: updatedEvaluations,
          },
        },
        variables: { experimentId },
      });
    },
  });

  const handleRerunClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation(); // Prevent card click event from firing
    runEvaluation();
  };

  return (
    <Box>
      <EvaluationInformation
        evaluation={evaluation}
        actions={
          <CardActions disableSpacing>
            <LoadingButton
              size="small"
              startIcon={<RefreshIcon />}
              onClick={handleRerunClick}
              loading={loading}
            >
              Rerun Evaluation
            </LoadingButton>
          </CardActions>
        }
      />
    </Box>
  );
}

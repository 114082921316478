import { toast } from 'react-toastify';

export const copyToClipboard = (
  text: string,
  successMessage = 'Copied to clipboard!'
) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success(successMessage);
    })
    .catch(() => {
      toast.error('Failed to copy to clipboard');
    });
};

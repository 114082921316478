import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

interface PaywallModalProps {
  open: boolean;
  onClose: () => void;
  onUpgrade?: () => void;
}

export const PaywallModal: React.FC<PaywallModalProps> = ({
  open,
  onClose,
  onUpgrade,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <LockIcon color="primary" />
          <Typography variant="h6" fontWeight="bold">
            Unlock Premium Features
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Upgrade to access the ISO 42001 Framework
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={2}>
          Enhance your AI management with our premium plan, featuring the ISO
          42001 framework for comprehensive compliance and risk management.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Join industry leaders in securing your AI processes and building trust
          with stakeholders.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onUpgrade}
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'primary.dark',
            },
          }}
        >
          View Pricing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaywallModal;

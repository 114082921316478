import { FC, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShieldIcon from '@mui/icons-material/Shield';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { toast, ToastContainer } from 'react-toastify';
import {
  GetCurrentUserForPricingQuery,
  GetOrganizationNameQuery,
} from '../__generated__/gql/graphql';

const REQUEST_ISO42001_ADD_ON = gql`
  mutation RequestISO42001AddOn(
    $userEmail: String!
    $organizationName: String!
  ) {
    requestIso42001AddOn(
      userEmail: $userEmail
      organizationName: $organizationName
    ) {
      success
    }
  }
`;

const GET_CURRENT_USER = gql`
  query getCurrentUserForPricing {
    currentUser {
      email
    }
  }
`;

const GET_ORGANIZATION_NAME = gql`
  query getOrganizationName {
    organization {
      name
    }
  }
`;

const ISO42001AddOn: React.FC = () => {
  const { data: userData } =
    useSuspenseQuery<GetCurrentUserForPricingQuery>(GET_CURRENT_USER);
  const { data: organizationData } = useSuspenseQuery<GetOrganizationNameQuery>(
    GET_ORGANIZATION_NAME
  );
  const [requestIsoAccess, { loading }] = useMutation(REQUEST_ISO42001_ADD_ON, {
    variables: {
      userEmail: userData?.currentUser?.email,
      organizationName: organizationData?.organization?.name,
    },
    onCompleted: (data: { requestIso42001AddOn?: { success: boolean } }) => {
      if (data?.requestIso42001AddOn?.success) {
        toast.success('ISO 42001 Add-on request submitted successfully!');
        setRequestSuccess(true);
      }
    },
    onError: () => {
      toast.error('Failed to submit consultation request. Please try again.');
    },
  });

  const handleRequestIsoAccess = async () => {
    await requestIsoAccess();
  };

  const [requestSuccess, setRequestSuccess] = useState(false);

  return (
    <Card sx={{ mt: 4, p: 4, boxShadow: 3, borderRadius: 2 }}>
      <CardContent>
        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <ShieldIcon color="primary" sx={{ fontSize: 40 }} />
          <Typography variant="h4" fontWeight="bold">
            ISO 42001 Framework Add-on
          </Typography>
        </Box>
        <Typography variant="h5" color="primary" gutterBottom fontWeight="bold">
          Elevate Your AI Management
        </Typography>
        <Typography variant="body1" color="text.secondary" mb={3}>
          Implement a robust Artificial Intelligence Management System (AIMS)
          with our ISO 42001 framework
        </Typography>

        <List>
          {[
            'Mitigate AI-related risks effectively',
            'Build trust among customers and stakeholders',
            'Facilitate compliance with AI regulations',
            'Structured approach to AI policy development',
            'Continuous improvement of AI processes',
          ].map((benefit, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={benefit} />
            </ListItem>
          ))}
        </List>

        <Box mt={4} mb={2}>
          <Typography variant="h6" color="primary" gutterBottom>
            Custom Enterprise Pricing
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Tailored solutions to fit your organization's unique AI management
            needs
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || requestSuccess}
          onClick={handleRequestIsoAccess}
          sx={{
            py: 1.5,
            fontSize: '1.1rem',
            fontWeight: 'bold',
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
            },
          }}
        >
          {requestSuccess
            ? 'Thank you! Someone from Trail will be in touch with you shortly'
            : loading
            ? 'Submitting...'
            : 'Request ISO 42001 Consultation'}
        </Button>
      </CardContent>
    </Card>
  );
};

export const PricingPage: FC = () => {
  return (
    <>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h3" align="center">
          Enhance Your AI Management
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" mb={6}>
          Implement the industry-leading ISO 42001 framework with our
          specialized add-on
        </Typography>

        <Box sx={{ maxWidth: 1000, mx: 'auto' }}>
          <ISO42001AddOn />
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
};

export default PricingPage;

import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';

import DashboardDetails from './DashboardDetails';

export default function Dashboard() {
  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          width: '95%',
          flexGrow: 1,
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <Typography variant="h4" color="white">
            Project Dashboard
          </Typography>
        </Box>
      </Box>

      <DashboardDetails />
    </>
  );
}

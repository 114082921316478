import { Box, Typography, MenuItem, Select, FormControl } from '@mui/material';
import {
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
} from '../__generated__/gql/graphql';
import {
  getRiskClassCategoryText,
  getRiskClassRoleText,
} from '../risk_classification/QuestionnaireResult';

interface RiskClassTagProps {
  riskClassCategory?: RiskClassCategoryEnum;
  riskClassRole?: RiskClassRoleEnum;
  isEditable: boolean;
  setRiskClassRole?: (role: RiskClassRoleEnum) => void;
  setRiskClassCategory?: (category: RiskClassCategoryEnum) => void;
}

const RiskClassTag: React.FC<RiskClassTagProps> = ({
  riskClassCategory,
  riskClassRole,
  isEditable,
  setRiskClassRole,
  setRiskClassCategory,
}) => {
  const isRole = !!setRiskClassRole;
  const riskClassValue = isRole ? riskClassRole : riskClassCategory;

  const getBorderColor = (riskClassCategoryValue?: RiskClassCategoryEnum) => {
    switch (riskClassCategoryValue) {
      case RiskClassCategoryEnum.Minimal:
        return { border: 'green', background: 'rgba(0, 128, 0, 0.1)' };
      case RiskClassCategoryEnum.Limited:
        return { border: 'orange', background: 'rgba(255, 165, 0, 0.1)' };
      case RiskClassCategoryEnum.High:
      case RiskClassCategoryEnum.HighAndLimited:
        return { border: 'red', background: 'rgba(255, 0, 0, 0.1)' };
      case RiskClassCategoryEnum.HighExemptions:
        return { border: 'yellow', background: 'rgba(255, 255, 0, 0.1)' };
      case RiskClassCategoryEnum.Prohibited:
        return { border: 'black', background: 'rgba(0, 0, 0, 0.1)' };
      default:
        return { border: 'white', background: 'transparent' };
    }
  };

  const { border, background } = getBorderColor(riskClassCategory);

  const displayText = isRole
    ? getRiskClassRoleText(riskClassValue as RiskClassRoleEnum)
    : getRiskClassCategoryText(riskClassValue as RiskClassCategoryEnum);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      height="100%"
      sx={{
        border: `2px solid ${border}`,
        backgroundColor: background,
        ...(isEditable ? {} : { py: '20px' }),
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      {isEditable ? (
        <FormControl variant="filled" fullWidth>
          <Select
            value={riskClassValue || ''}
            onChange={event => {
              if (isRole && setRiskClassRole) {
                setRiskClassRole(event.target.value as RiskClassRoleEnum);
              } else if (!isRole && setRiskClassCategory) {
                setRiskClassCategory(
                  event.target.value as RiskClassCategoryEnum
                );
              }
            }}
            sx={{
              pt: '0px',
              pb: '4px',
              px: '4px',
              '& .MuiSelect-select': {
                whiteSpace: 'normal',
                pt: '10px',
              },
            }}
          >
            {isRole
              ? Object.values(RiskClassRoleEnum).map(riskRole => (
                  <MenuItem key={riskRole} value={riskRole}>
                    {getRiskClassRoleText(riskRole as RiskClassRoleEnum)}
                  </MenuItem>
                ))
              : Object.values(RiskClassCategoryEnum).map(riskCategory => (
                  <MenuItem key={riskCategory} value={riskCategory}>
                    {getRiskClassCategoryText(
                      riskCategory as RiskClassCategoryEnum
                    )}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      ) : riskClassValue ? (
        <Typography variant="h6">{displayText}</Typography>
      ) : (
        <Typography variant="h6">
          {riskClassRole === 'OUT_OF_SCOPE' ? 'Out of Scope' : 'Not set yet'}
        </Typography>
      )}
    </Box>
  );
};

export default RiskClassTag;

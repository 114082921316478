import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { FC } from 'react';

interface ConfirmDialogProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  open: boolean;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={open} sx={{ borderRadius: '8px' }}>
      <DialogTitle>You have unsaved changes</DialogTitle>
      <DialogContent>
        Are you sure you want to leave this page without saving?
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Stay</Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

import {
  DataSourceEnum,
  DocumentationScopeEnum,
  DocumentationTypeEnum,
  DocumentComponentInput,
  DocumentComponentTypeEnum,
} from '../../../../__generated__/gql/graphql';

export interface DocumentationTemplate {
  id: number;
  documentationScope: DocumentationScopeEnum;
  documentationType: DocumentationTypeEnum;
  title: string;
  description: string;
  components: DocumentComponentInput[];
}

export const DocumentationTemplates: DocumentationTemplate[] = [
  {
    id: 0,
    title: 'Custom Organizational',
    documentationScope: DocumentationScopeEnum.Organization,
    documentationType: DocumentationTypeEnum.Generated,
    description: 'Organization Specific Custom Documentation',
    components: [],
  },
  {
    id: 1,
    title: 'Gen Customizable Organizational',
    documentationScope: DocumentationScopeEnum.Project,
    documentationType: DocumentationTypeEnum.Generated,
    description: '',
    components: [
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Abstract',
        query: '',
        componentType: DocumentComponentTypeEnum.Abstract,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Use Case',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Risk Classification',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Architecture',
        query: '',
        componentType: DocumentComponentTypeEnum.Architecture,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Quality Metrics',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Bias Checks',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Sources',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Model Architecture',
        query:
          'Describe the model architecture along with any inference time input data adjustments',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Model Quality Metrics',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Features',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Feature Importances/Model Bias',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Limitations and Risks',
        query: '',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
    ],
  },
  {
    id: 3,
    title: 'AI Policy',
    documentationScope: DocumentationScopeEnum.Organization,
    documentationType: DocumentationTypeEnum.Generated,
    description:
      'This template is based on the EU AI Act and can be customized to fit your organization',
    components: [
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Legal Disclaimer',
        query: '',
        componentType: DocumentComponentTypeEnum.LegalDisclaimer,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: '',
        query: '',
        componentType: DocumentComponentTypeEnum.PurposeAndGoal,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Scope Of AI Policy',
        query: '',
        componentType: DocumentComponentTypeEnum.ScopeOfPolicy,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Coming into Force',
        query: '',
        componentType: DocumentComponentTypeEnum.ComingIntoForce,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Contact of AI Policy',
        query: '',
        componentType: DocumentComponentTypeEnum.ContactOPolicy,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Definition of AI',
        query: '',
        componentType: DocumentComponentTypeEnum.DefinitionOfAi,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Alignment with Organisation',
        query: '',
        componentType: DocumentComponentTypeEnum.AlignmentWithOrganisation,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Risk Profile',
        query: '',
        componentType: DocumentComponentTypeEnum.RiskProfile,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'AI Objectives',
        query: '',
        componentType: DocumentComponentTypeEnum.AiObjectives,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'AI Principles',
        query: '',
        componentType: DocumentComponentTypeEnum.AiPrinciples,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'AI Systems',
        query: '',
        componentType: DocumentComponentTypeEnum.AiSystems,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'AI Governance System',
        query: '',
        componentType: DocumentComponentTypeEnum.AiGovernanceSystem,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Other Policies and Regulations',
        query: '',
        componentType: DocumentComponentTypeEnum.OtherPolicies,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Obligation Requirements',
        query: '',
        componentType: DocumentComponentTypeEnum.ObligationsRequirements,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Text,
        name: 'Non-Compliance Clause',
        query: '',
        componentType: DocumentComponentTypeEnum.NonCompliance,
      },
    ],
  },
  {
    id: 5,
    title: 'Model Documentation',
    documentationScope: DocumentationScopeEnum.Experiment,
    documentationType: DocumentationTypeEnum.Generated,
    description: '',
    components: [
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'System Components',
        query:
          'Document the System Components that were used in the provided source code',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'System Diagram',
        query: '',
        componentType: DocumentComponentTypeEnum.ArchitectureDiagram,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Model Framework',
        query: '',
        componentType: DocumentComponentTypeEnum.MlFramework,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Algorithm type',
        query: 'What type of algorithm was used?',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Tools',
        query: 'What kind of tools were used?',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Hardware Ressources',
        query: 'What are the compute ressources (hardware)?',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Storage',
        query: 'Which tool is used for the storage of the data?',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Human Ressources',
        query:
          'What are the human ressources involved in this model development process?',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
    ],
  },
  {
    id: 6,
    title: 'Custom Project',
    documentationScope: DocumentationScopeEnum.Project,
    documentationType: DocumentationTypeEnum.Proxy,
    description: 'Project Specific Custom Documentation',
    components: [],
  },
  {
    id: 6,
    title: 'Custom Experiment',
    documentationScope: DocumentationScopeEnum.Experiment,
    documentationType: DocumentationTypeEnum.Proxy,
    description: 'Experiment Specific Custom Documentation',
    components: [],
  },
  {
    id: 6,
    title: 'Data Documentation',
    documentationScope: DocumentationScopeEnum.Experiment,
    documentationType: DocumentationTypeEnum.Proxy,
    description: '',
    components: [
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Provenance',
        query:
          'Show me the steps the data went through (Data Lineage/provenance)',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Last updated/modified',
        query: 'When was the dataset last updated/modified?',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Categories',
        query:
          'Which of the dataset categories: training, validation, test? are present? If otheres are present, also describe them',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Labelling Process',
        query: 'Describe the datalabeling process',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Intended Use of Data',
        query: 'Describe the intended use of the data',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Quality & Bias',
        query: 'Describe the data quality & bias',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Preparation',
        query: 'Describe the data preparation steps',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
      {
        args: [],
        dataSource: DataSourceEnum.Code,
        name: 'Data Retention & Disposal Policy',
        query: 'Describe the data retention & disposal policy',
        componentType: DocumentComponentTypeEnum.CustomText,
      },
    ],
  },
];

export const DocumentComponentNameMap = {
  [DocumentComponentTypeEnum.Abstract]: 'Abstract',
  [DocumentComponentTypeEnum.Architecture]: 'Architecture',
  [DocumentComponentTypeEnum.ArchitectureDiagram]: 'Architecture Diagram',
  [DocumentComponentTypeEnum.CustomText]: 'Custom Text',
  [DocumentComponentTypeEnum.BiasMitigationStrategy]:
    'Bias Mitigation Strategy',
  [DocumentComponentTypeEnum.ClassificationMetrics]: 'Classification Metrics',
  [DocumentComponentTypeEnum.CrossValidation]: 'Cross Validation',
  [DocumentComponentTypeEnum.CustomArtifact]: 'Custom Artifact',
  [DocumentComponentTypeEnum.DataIntegrity]: 'Data Integrity',
  [DocumentComponentTypeEnum.DataProcessing]: 'Data Processing',
  [DocumentComponentTypeEnum.DataSource]: 'Data Sources',
  [DocumentComponentTypeEnum.DataSummary]: 'Data Summary',
  [DocumentComponentTypeEnum.EasyAlgorithmExplanation]:
    'Easy Algorithm Explanation',
  [DocumentComponentTypeEnum.HoldoutTestset]: 'Holdout Testset',
  [DocumentComponentTypeEnum.InputDataType]: 'Input Data Type',
  [DocumentComponentTypeEnum.IntendedUseCase]: 'Intended Use Case',
  [DocumentComponentTypeEnum.LegalDisclaimer]: 'Legal Disclaimer',
  [DocumentComponentTypeEnum.MlFramework]: 'ML Framework',
  [DocumentComponentTypeEnum.ModelParameters]: 'Model Parameters',
  [DocumentComponentTypeEnum.OutputDataType]: 'Output Data Type',
  [DocumentComponentTypeEnum.PerformanceSummary]: 'Performance Summary',
  [DocumentComponentTypeEnum.PurposeAndGoal]: 'Purpose and Goal of AI Policy',
  [DocumentComponentTypeEnum.QualitativePerformanceMetrics]:
    'Qualitative Performance Metrics',
  [DocumentComponentTypeEnum.QuantitativePerformanceMetrics]:
    'Quantitative Performance Metrics',
  [DocumentComponentTypeEnum.ReferencePapers]: 'Reference Papers',
  [DocumentComponentTypeEnum.RepresentativeData]: 'Representative Data',
  [DocumentComponentTypeEnum.RolesResponsibilities]:
    'Roles and Responsibilities',
  [DocumentComponentTypeEnum.ScopeOfPolicy]: 'Scope of Policy',
  [DocumentComponentTypeEnum.StratifiedSampling]: 'Stratified Sampling',
  [DocumentComponentTypeEnum.TaskType]: 'Task Type',
  [DocumentComponentTypeEnum.TrainTestSplit]: 'Train Test Split',
  [DocumentComponentTypeEnum.UnintendedUseCases]: 'Unintended Use Cases',
  [DocumentComponentTypeEnum.UnittestModelConversion]:
    'Unittest Model Conversion',
  [DocumentComponentTypeEnum.PolicyCustomization]: 'Policy Customization',
  [DocumentComponentTypeEnum.ComingIntoForce]: 'Coming Into Force',
  [DocumentComponentTypeEnum.ContactOPolicy]: 'Contact of Policy',
  [DocumentComponentTypeEnum.DefinitionOfAi]: 'Definition of AI',
  [DocumentComponentTypeEnum.AlignmentWithOrganisation]:
    'Alignment with Organisation',
  [DocumentComponentTypeEnum.AiObjectives]: 'AI Objectives',
  [DocumentComponentTypeEnum.AiPrinciples]: 'AI Principles',
  [DocumentComponentTypeEnum.NonCompliance]: 'Non-Compliance Clause',
  [DocumentComponentTypeEnum.AiGovernanceSystem]: 'AI Governance System',
  [DocumentComponentTypeEnum.ObligationsRequirements]:
    'Obligations/ Requirements',
  [DocumentComponentTypeEnum.OtherPolicies]: 'Other Policies & Regulations',
  [DocumentComponentTypeEnum.RiskProfile]: 'Risk Profile',
  [DocumentComponentTypeEnum.AiSystems]: 'AI Systems',
};

// export const DocumentationTemplates: DocumentationTemplate[] = [
//   {
//     id: 0,
//     title: 'Predefined Org.',
//     templateType: DocumentationTemplateTypeEnum.Organization_Specific,
//     documentationType: DocumentationTypeEnum.FixedGenerated,
//     description: 'start from scratch',
//     components: [],
//   },
//   {
//     id: 1,
//     title: 'Gen Customizable Org.',
//     templateType: DocumentationTemplateTypeEnum.Project_Specific,
//     documentationType: DocumentationTypeEnum.Generated,
//     description: 'The EU AI Act',
//     components: [
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Abstract',
//         query: '',
//         componentType: DocumentComponentTypeEnum.Abstract,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Use Case',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Risk Classification',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Architecture',
//         query: '',
//         componentType: DocumentComponentTypeEnum.Architecture,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Quality Metrics',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Bias Checks',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Sources',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Model Architecture',
//         query:
//           'Describe the model architecture along with any inference time input data adjustments',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Model Quality Metrics',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Features',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Feature Importances/Model Bias',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Limitations and Risks',
//         query: '',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//     ],
//   },
//     ],
//   },
//   {
//     id: 4,
//     title: 'Model Documentation',
//     templateType: DocumentationTemplateTypeEnum.Project_Specific,
//     documentationType: DocumentationTypeEnum.Generated,
//     description: 'This offers a simple Model Documentation Template',
//     components: [
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'System Components',
//         query:
//           'Document the System Components that were used in the provided source code',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'System Diagram',
//         query: '',
//         componentType: DocumentComponentTypeEnum.ArchitectureDiagram,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Model Framework',
//         query: '',
//         componentType: DocumentComponentTypeEnum.MlFramework,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Algorithm type',
//         query: 'What type of algorithm was used?',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Tools',
//         query: 'What kind of tools were used?',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Hardware Ressources',
//         query: 'What are the compute ressources (hardware)?',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Storage',
//         query: 'Which tool is used for the storage of the data?',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Human Ressources',
//         query:
//           'What are the human ressources involved in this model development process?',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//     ],
//   },
//   {
//     id: 5,
//     title: 'Data Documentation',
//     templateType: DocumentationTemplateTypeEnum.Project_Specific,
//     documentationType: DocumentationTypeEnum.Generated,
//     description: 'This Docu Template describes the data used in the model',
//     components: [
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Provenance',
//         query:
//           'Show me the steps the data went through (Data Lineage/provenance)',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Last updated/modified',
//         query: 'When was the dataset last updated/modified?',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Categories',
//         query:
//           'Which of the dataset categories: training, validation, test? are present? If otheres are present, also describe them',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Labelling Process',
//         query: 'Describe the datalabeling process',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Intended Use of Data',
//         query: 'Describe the intended use of the data',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Quality & Bias',
//         query: 'Describe the data quality & bias',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Preparation',
//         query: 'Describe the data preparation steps',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//       {
//         args: [],
//         dataSource: DataSourceEnum.Code,
//         name: 'Data Retention & Disposal Policy',
//         query: 'Describe the data retention & disposal policy',
//         componentType: DocumentComponentTypeEnum.CustomText,
//       },
//     ],
//   }

import { useMemo, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Box,
  Button,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import HeaderEditorModal from './HeaderEditorModal';
import { Header } from './ExperimentTableHeaderEditor';
import {
  Experiment,
  Filterable,
  Rule,
  ValueType,
} from '../../__generated__/gql/graphql';
import filterExperiments from '../../tree_visualization/experiment_filter_modal/ExperimentFilterUtil';
import ExperimentFilterModal from '../../tree_visualization/experiment_filter_modal/ExperimentFilterModal';
import { gql } from '../../__generated__/gql';

const NUM_ITEMS_IN_PAGE: number[] = [5, 10, 25];
const borderstyle = '1px solid #787373';
const headerstyle = {
  borderLeft: borderstyle,
  borderBottom: borderstyle,
};
const cellstyle = {
  minWidth: '120px',
  height: '60px',
  borderLeft: borderstyle,
  borderBottom: '0px',
};

export const GET_EXPERIMENTS = gql(`
query TableView_items($project_id: String!, $filters: [Rule]) {
  project(id: $project_id, filters: $filters) {
    id
    title
    experiments {
      id
      title
      comments
      parentExperimentId
      hierarchyIndex
      hypothesis
      creationTimestamp
      metricDelta
      paramDelta
      gitCommitHash
      instanceRuns {
        id
        comment
        metrics
        parameters
        isComplete
      }
    }
  }
}
`);

export default function ExperimentTable() {
  const { projectId } = useParams();
  const { data } = useQuery(GET_EXPERIMENTS, {
    variables: { project_id: projectId || '', filters: [] },
  });

  const loadedExperiments = data?.project?.experiments ?? [];

  const [headers, setHeaders] = useState([] as Header[]);
  const [tableData, setTableData] = useState([] as any[]);
  const [experiments, filterables]: [Experiment[], Filterable[]] =
    useMemo(() => {
      const updateExperiments: Experiment[] = [];
      const updateFilterables: Filterable[] = [];
      const parameterHeaders: Header[] = [];
      const metricHeaders: Header[] = [];
      loadedExperiments.forEach(expr => {
        expr?.instanceRuns?.forEach((instanceRun: any) => {
          const experiment: any = {
            Experiment: expr.id,
          };

          const metrics = instanceRun?.metrics || [];
          Object.entries(metrics).forEach(([metric, value]) => {
            if (
              !metricHeaders.find(
                (metricHeader: Header) => metricHeader.name === metric
              )
            ) {
              metricHeaders.push({
                name: metric,
                show: true,
              });
              experiment[metric] = value;

              if (typeof value === 'string') {
                updateFilterables.push({
                  name: metric,
                  type: ValueType.String,
                });
              } else if (typeof value === 'number') {
                updateFilterables.push({
                  name: metric,
                  type: ValueType.Number,
                });
              }
            }
          });

          const parameters = instanceRun?.parameters || [];
          Object.entries(parameters).forEach(([parameter, value]) => {
            if (
              !parameterHeaders.find((phrd: Header) => phrd.name === parameter)
            ) {
              parameterHeaders.push({
                name: parameter,
                show: true,
              });
              experiment[parameter] = value;

              if (typeof value === 'string') {
                updateFilterables.push({
                  name: parameter,
                  type: ValueType.String,
                });
              } else if (typeof value === 'number') {
                updateFilterables.push({
                  name: parameter,
                  type: ValueType.Number,
                });
              }
            }
          });

          updateExperiments.push(experiment);
        });
      });

      updateFilterables.push(
        {
          name: 'Id',
          type: ValueType.String,
        },
        {
          name: 'Experiment',
          type: ValueType.String,
        }
      );

      const updateHeaders: Header[] = [
        {
          name: 'Experiment',
          show: true,
        },
      ];
      if (parameterHeaders.length > 0) {
        updateHeaders.push({
          name: 'Parameters',
          show: true,
          subHeaders: parameterHeaders,
        });
      }
      if (metricHeaders.length > 0) {
        updateHeaders.push({
          name: 'Metrics',
          show: true,
          subHeaders: metricHeaders,
        });
      }
      setHeaders(updateHeaders);
      setTableData([...updateExperiments]);

      return [updateExperiments, updateFilterables];
    }, [data]);

  const [position, setPostion] = useState(0);
  const [numPageItems, setNumPageItems] = useState(NUM_ITEMS_IN_PAGE[0]);

  const [sortColumns, setSortColumns] = useState(
    [] as { name: string; descend: boolean }[]
  );
  useEffect(() => {
    const newTableData =
      sortColumns.length > 0
        ? tableData.sort((d1: any, d2: any) => {
            // Disabled to improve improve readability and avoid unnecessary nesting.
            // eslint-disable-next-line no-restricted-syntax
            for (const sortColumn of sortColumns) {
              const v1 = d1[sortColumn.name];
              const v2 = d2[sortColumn.name];
              if (v1 === undefined && v2 === undefined) {
                // eslint-disable-next-line no-continue
                continue;
              }
              if (v1 === undefined) {
                return 1;
              }
              if (v2 === undefined) {
                return -1;
              }
              if (v1 !== v2) {
                if (typeof v1 === 'number') {
                  return (v1 - v2) * (sortColumn.descend ? -1 : 1);
                }
                if (typeof v1 === 'string') {
                  return v1.localeCompare(v2) * (sortColumn.descend ? 1 : -1);
                }
              }
            }

            return 0;
          })
        : experiments;

    setTableData([...newTableData]);
  }, [sortColumns]);

  const shownHeaders = [] as { name: string; subHeaders?: string[] }[];
  headers.forEach((header: Header) => {
    if (header.show) {
      const subHeaders = header.subHeaders?.reduce(
        (accumulator: string[], subHeader: Header) => {
          if (subHeader.show) {
            accumulator.push(subHeader.name);
          }

          return accumulator;
        },
        [] as string[]
      );
      const shownHeader = { name: header.name } as {
        name: string;
        subHeaders?: string[];
      };
      if (subHeaders && subHeaders.length > 0) {
        shownHeader.subHeaders = subHeaders;
      }
      shownHeaders.push(shownHeader);
    }
  });

  const [showHeaderEditor, setShowHeaderEditor] = useState(false);
  const [showFilterExperimentModal, setShowFilterExperimentModal] =
    useState(false);
  const [appliedRules, setAppliedRules] = useState<Rule[]>([]);
  const [filteredTableData, setFilteredTableData] = useState([] as any[]);
  useEffect(() => {
    setFilteredTableData(filterExperiments(tableData, appliedRules));
    setPostion(0);
  }, [tableData, appliedRules]);

  const movePage = (direction: 1 | -1) => {
    const newPostion = position + direction * numPageItems;
    if (newPostion >= 0 && newPostion < filteredTableData.length) {
      setPostion(newPostion);
    }
  };

  const getSortOrder = (column: string) => {
    const index = sortColumns.findIndex(
      (sortColumn: { name: string; descend: boolean }) =>
        sortColumn.name === column
    );
    let sortIcon = null;
    if (index !== -1) {
      sortIcon = sortColumns[index].descend ? (
        <ArrowDropDownIcon />
      ) : (
        <ArrowDropUpIcon />
      );
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {column} {sortIcon} {index >= 0 ? index + 1 : ''}
      </Box>
    );
  };

  const toggleColumnSort = (column: string) => {
    let newSortColumns = sortColumns;
    const index = sortColumns.findIndex(
      (sortColumn: { name: string; descend: boolean }) =>
        sortColumn.name === column
    );
    if (index === -1) {
      newSortColumns = [...sortColumns, { name: column, descend: true }];
    } else {
      const sortColumn = sortColumns.splice(index, 1)[0];
      sortColumn.descend = !sortColumn.descend;
      if (!sortColumn.descend) {
        newSortColumns = [
          ...sortColumns.slice(0, index),
          sortColumn,
          ...sortColumns.slice(index),
        ];
      } else {
        newSortColumns = [...sortColumns];
      }
    }

    setSortColumns(newSortColumns);
  };

  const changeTableColumns = (newHeaders: Header[]) => {
    // Remove the hidden columns from sort columns.
    newHeaders.forEach((newHeader: Header) => {
      const { show } = newHeader;
      if (!show) {
        if (!newHeader.subHeaders) {
          const index = sortColumns.findIndex(
            columns => columns.name === newHeader.name
          );
          if (index !== -1) {
            sortColumns.splice(index, 1);
          }
          return;
        }
      }
      newHeader?.subHeaders?.forEach((subHeader: Header) => {
        if (!show || !subHeader.show) {
          const index = sortColumns.findIndex(
            columns => columns.name === subHeader.name
          );
          if (index !== -1) {
            sortColumns.splice(index, 1);
          }
        }
      });
    });
    setHeaders(newHeaders);
    setSortColumns([...sortColumns]);
  };

  return (
    <Box>
      <TableContainer
        sx={{
          maxHeight: '80vh',
          border: borderstyle,
          borderRadius: '4px',
          boxShadow: '1px 1px 4px 4px #0a2640',
          overflow: 'auto',
        }}
      >
        <Table stickyHeader aria-label="experiment table view">
          <TableHead>
            <TableRow>
              {shownHeaders.map(
                (shownHeader: { name: string; subHeaders?: string[] }) => (
                  <TableCell
                    key={shownHeader.name}
                    rowSpan={!shownHeader.subHeaders ? 2 : 1}
                    colSpan={
                      shownHeader.subHeaders ? shownHeader.subHeaders.length : 1
                    }
                    sx={headerstyle}
                    onClick={() => toggleColumnSort(shownHeader.name)}
                  >
                    {shownHeader.subHeaders
                      ? shownHeader.name
                      : getSortOrder(shownHeader.name)}
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {shownHeaders
                .reduce(
                  (
                    accumulator: string[],
                    shownHeader: { name: string; subHeaders?: string[] }
                  ) => {
                    if (shownHeader.subHeaders) {
                      accumulator.push(...shownHeader.subHeaders);
                    }

                    return accumulator;
                  },
                  [] as string[]
                )
                .map((shownHeader: string) => (
                  <TableCell
                    key={shownHeader}
                    onClick={() => toggleColumnSort(shownHeader)}
                    sx={{ ...headerstyle, top: '57px' }}
                  >
                    {getSortOrder(shownHeader)}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTableData
              .slice(position, position + numPageItems)
              .map((tableDataPage: any, index: number) => (
                <TableRow
                  key={tableDataPage.key}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#062442' : '#021930',
                    '&:hover': {
                      backgroundColor: '#021324',
                    },
                  }}
                >
                  {shownHeaders.map(shownHeader =>
                    shownHeader.subHeaders ? (
                      shownHeader.subHeaders.map((subHeader: string) => (
                        <TableCell key={subHeader} sx={cellstyle}>
                          {tableDataPage[subHeader] !== undefined
                            ? tableDataPage[subHeader].toFixed(3)
                            : ''}
                        </TableCell>
                      ))
                    ) : (
                      <TableCell key={shownHeader.name} sx={cellstyle}>
                        {tableDataPage[shownHeader.name]}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          margin: '10px 0 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            endIcon={<EditIcon />}
            onClick={() => setShowHeaderEditor(true)}
          >
            EDIT COLUMNS
          </Button>
          <Button
            variant="contained"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilterExperimentModal(true)}
          >
            FILTER EXPERIMENTS
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControl sx={{ width: '100px' }}>
            <Select
              labelId="items-per-page-select-label"
              id="items-per-page-select"
              defaultValue={numPageItems}
              onChange={e => {
                setNumPageItems(e.target.value as number);
                setPostion(0);
              }}
              sx={{ height: '30px' }}
            >
              {NUM_ITEMS_IN_PAGE.map((nip: number) => (
                <MenuItem key={nip} value={nip}>
                  {nip}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton aria-label="previous page" onClick={() => movePage(-1)}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography>
            {`${Math.round(position / numPageItems) + 1} - ${Math.ceil(
              filteredTableData.length / numPageItems
            )} of ${Math.ceil(filteredTableData.length / numPageItems)}`}
          </Typography>
          <IconButton aria-label="next page" onClick={() => movePage(1)}>
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      <HeaderEditorModal
        isOpen={showHeaderEditor}
        onClose={() => setShowHeaderEditor(false)}
        onSubmit={changeTableColumns}
        headers={headers}
      />
      <ExperimentFilterModal
        filterables={filterables}
        appliedRules={appliedRules}
        setAppliedRules={setAppliedRules}
        isOpen={showFilterExperimentModal}
        onClose={() => setShowFilterExperimentModal(false)}
      />
    </Box>
  );
}

import React, { useState, Suspense } from 'react';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  IconButton,
  CardContent,
  Card,
  SelectChangeEvent,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllUsersQuery, AccessRoleEnum } from '../__generated__/gql/graphql';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers(includeAuditors: true) {
      email
      name
      accessRole
    }
  }
`;

const INVITE_NEW_USER = gql`
  mutation InviteNewUser(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    inviteNewUser(email: $email, firstName: $firstName, lastName: $lastName) {
      user {
        email
        name
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      user {
        email
      }
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation updateUserAccessRole(
    $userEmail: String!
    $updatedAccessRole: String!
  ) {
    updateUserAccessRole(
      userEmail: $userEmail
      updatedAccessRole: $updatedAccessRole
    ) {
      user {
        email
        accessRole
      }
    }
  }
`;

const UsersTable: React.FC = () => {
  const { data } = useSuspenseQuery<GetAllUsersQuery>(GET_ALL_USERS);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {
    refetchQueries: [{ query: GET_ALL_USERS }],
    onCompleted: () => {
      toast.success('User role updated successfully!');
    },
    onError: error => {
      toast.error(`Error updating user role: ${error.message}`);
    },
  });
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_ALL_USERS }],
    onCompleted: () => {
      toast.success('User deleted successfully!');
    },
    onError: error => {
      toast.error(`Error deleting user: ${error.message}`);
    },
  });

  const handleDelete = (email: string) => {
    deleteUser({ variables: { email } });
  };

  const handleRoleChange = (userEmail: string, newRole: string) => {
    updateUserRole({
      variables: {
        userEmail,
        updatedAccessRole: newRole,
      },
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Access Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data?.allUsers ?? []).map(
            user =>
              user && (
                <TableRow key={user.email}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    <Select
                      value={user.accessRole || ''}
                      onChange={(event: SelectChangeEvent) => {
                        const selectedKey = Object.entries(AccessRoleEnum).find(
                          ([_, value]) => value === event.target.value
                        )?.[0];
                        handleRoleChange(user.email, selectedKey || '');
                      }}
                      displayEmpty
                    >
                      {Object.entries(AccessRoleEnum).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(user.email)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UserManagement: React.FC = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [open, setOpen] = useState(false);

  const [inviteNewUser, { loading: mutationLoading }] = useMutation(
    INVITE_NEW_USER,
    {
      refetchQueries: [{ query: GET_ALL_USERS }],
      onCompleted: data => {
        toast.success(
          `User ${data.inviteNewUser.user.name} invited successfully!`
        );
        setEmail('');
        setFirstName('');
        setLastName('');
        setOpen(false);
      },
      onError: error => {
        toast.error(`Error inviting user: ${error.message}`);
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!email || !firstName || !lastName) {
      toast.error('All fields are required');
      return;
    }

    inviteNewUser({ variables: { email, firstName, lastName } });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card variant="outlined" sx={{ width: '100%', height: '100%' }}>
      <CardContent>
        <Box sx={{ padding: 4 }}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Suspense fallback={<CircularProgress />}>
            <UsersTable />
          </Suspense>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpen}
            sx={{ marginTop: 2 }}
          >
            Add New User
          </Button>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6">Invite New User</Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
              >
                <TextField
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <TextField
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
                {mutationLoading ? (
                  <CircularProgress />
                ) : (
                  <Button type="submit" variant="contained" color="primary">
                    Invite
                  </Button>
                )}
              </Box>
            </Box>
          </Modal>
        </Box>
      </CardContent>
      <ToastContainer />
    </Card>
  );
};

export default UserManagement;

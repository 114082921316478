import React from 'react';
import { Typography } from '@mui/material';

interface TimestampProps {
  timestamp: number;
}

function formatTimestamp(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  return `${formattedDate} - ${formattedTime}`;
}

function Timestamp({ timestamp }: TimestampProps): JSX.Element {
  const formattedTimestamp = formatTimestamp(timestamp);

  return <Typography>{formattedTimestamp}</Typography>;
}

export default Timestamp;

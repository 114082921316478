import { Avatar, Box } from '@mui/material';

export const IntegrationButton = ({
  logo,
  label,
  action,
}: {
  logo: string;
  label: string;
  action?: () => void;
}) => {
  return (
    <Box
      onClick={action}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        padding: '16px',
        width: '100px',
        height: '100px',
        ':hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08);',
          borderRadius: '5px',
        },
      }}
    >
      <Avatar src={logo} />
      {label}
    </Box>
  );
};

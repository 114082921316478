import { useSuspenseQuery } from '@apollo/client';
import { useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { gql } from '../__generated__/gql';
import AddNewItemCard from '../components/AddNewItemCard';
import SearchIcon from '@mui/icons-material/Search';
import AuditDetailCard from './AuditDetailCard';
import NewAuditModal from './NewAuditModal';
import type { AuditViewItemsQuery } from '../__generated__/gql/graphql';
import AuditDetailDrawerSuspenseWrapper from './AuditDetailDrawerSuspenseWrapper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GET_AUDITS = gql(`
  query AuditViewItems {
    allGovernanceAudits {
      id
      name
      description
      status
      startDate
      endDate
      frameworkId
      auditors {
        id
        name
        email
        initials
      }
    }
  }
`);

export default function AuditView() {
  const { data } = useSuspenseQuery<AuditViewItemsQuery>(GET_AUDITS);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState<NonNullable<
    NonNullable<AuditViewItemsQuery['allGovernanceAudits']>[number]
  > | null>(null);
  const [showCreationModal, setShowCreationModal] = useState(false);

  const audits = data?.allGovernanceAudits ?? [];
  const filteredAudits = audits.filter(audit =>
    audit?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box display={'flex'} gap={2} flexDirection={'column'} width={'100%'}>
      <ToastContainer position="top-right" />
      <Typography variant="h4" marginBottom={3}>
        Governance Audits
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          gap: '12px',
        }}
      >
        <TextField
          id="search"
          sx={{ width: 700 }}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search for an audit ..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box width={'100%'} display={'flex'}>
        <Box
          sx={{
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant="h6">
            {filteredAudits.length === 0
              ? 'No Audits'
              : filteredAudits.length === 1
              ? '1 Audit'
              : `${filteredAudits.length} Audits`}
          </Typography>

          <Box display="flex" flexWrap="wrap" gap="12px">
            {filteredAudits.map(
              audit =>
                audit && (
                  <AuditDetailCard
                    key={audit.id}
                    audit={audit}
                    onClick={() => {
                      setSelectedAudit(audit);
                      setShowSidebar(!showSidebar);
                    }}
                  />
                )
            )}
            <AddNewItemCard
              onClick={() => setShowCreationModal(true)}
              sx={{ width: 400, height: 200 }}
            />
          </Box>
        </Box>
      </Box>

      {selectedAudit && (
        <AuditDetailDrawerSuspenseWrapper
          key={selectedAudit.id}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          selectedAudit={selectedAudit}
        />
      )}

      <NewAuditModal
        isOpen={showCreationModal}
        onClose={() => setShowCreationModal(false)}
      />
    </Box>
  );
}

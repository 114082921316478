import {
  Box,
  ButtonGroup,
  CircularProgress,
  Tab,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  DocumentationEntry,
  documentComponentsAtom,
  documentIdAtom,
  documentTemplateAtom,
  documentTitleAtom,
  editableFileIdAtom,
  experimentIdAtom,
  projectIdAtom,
} from './DocumentationPageStates';
import { v4 as uuidv4 } from 'uuid';
import { colorThemes } from '../../theme';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  DataSourceEnum,
  DocumentationQuery,
  DocumentationScopeEnum,
  DocumentationTypeEnum,
} from '../../__generated__/gql/graphql';
import ComponentsTab from './tabs/components/ComponentsTab';
import GenerationTab from './tabs/generation/GenerationTab';
import { toast, ToastContainer } from 'react-toastify';
import { Loading } from '../../components/Loading';
import EditorTab from './tabs/editor/EditorTab';
import TemplateTab from './tabs/template/TemplatesTab';
import { useProject } from '../../contexts/project';
import DropdownSelector from '../../components/DropdownSelector';

export const GET_DOCUMENTATION = gql(`
  query Documentation($documentation_id:String!) {
    documentation(id:$documentation_id) {
      __typename
      documentationType
      documentationScope
      latestVersion
      components {
        type
        name
        dataSource
        query
        args
      }
      name
      id
      documentationResult {
        id
        editableFile {
          id
        }
      }
      project {
        id
        title
        experiments {
          id
        }
      }
    }
  }`);

export const UPDATE_DOCUMENTATION_MUTATION = gql(`
    mutation UpdateDocumentation($components: [DocumentComponentInput], $id: String!, $name: String!) {
        updateDocumentation(components: $components, documentationId: $id, name: $name) {
        __typename
        documentation {
          __typename
          id
        }
      }
    }
  `);

const CREATE_DOCUMENTATION_MUTATION = gql(`
  mutation CreateTemplatedDocumentation(
    $name: String!
    $documentationType: DocumentationType!
    $projectId: String
    $components: [DocumentComponentInput!]!
    $documentCreator: String!
    $documentationScope: DocumentationScope!
  ) {
    createDocumentation(
      name: $name
      documentationType: $documentationType
      projectId: $projectId
      components: $components
      documentCreator: $documentCreator
      documentationScope: $documentationScope
    ) {
      documentation {
        __typename
        id
        name
        documentationType
      }
    }
  }
`);

export default function DocumentationPage() {
  const { documentationId } = useParams();
  const [documentComponents, setDocumentComponents] = useRecoilState(
    documentComponentsAtom
  );
  const setDocumentId = useSetRecoilState(documentIdAtom);
  const setEditableFileId = useSetRecoilState(editableFileIdAtom);
  const setProjectId = useSetRecoilState(projectIdAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const [documentTitle, setDocumentTitle] = useRecoilState(documentTitleAtom);

  if (!searchParams.get('view')) {
    setSearchParams({ view: 'components' });
  }
  const [tabValue, setTabValue] = useState(
    searchParams.get('view') || 'components'
  );

  const [experimentIds, setExperimentIds] = useState<string[]>([]);

  const [selectedExperiment, setSelectedExperiment] =
    useRecoilState(experimentIdAtom);

  const { data, loading } = useQuery<DocumentationQuery>(GET_DOCUMENTATION, {
    variables: {
      documentation_id: documentationId ?? '',
    },
    onCompleted: data => {
      setDocumentTitle(data.documentation?.name ?? '');
      setDocumentComponents(
        (data.documentation?.components as DocumentationEntry[]).map(
          component => {
            return {
              ...component,
              id: uuidv4(),
            };
          }
        )
      );
      setExperimentIds(
        data.documentation?.project?.experiments?.map(e => e?.id || '') ?? []
      );
      setSelectedExperiment(
        data.documentation?.project?.experiments?.[0]?.id ?? ''
      );
      setProjectId(data.documentation?.project?.id ?? '');
      setDocumentId(data.documentation?.id ?? '');
      if (data.documentation?.documentationResult?.editableFile?.id) {
        setEditableFileId(
          data.documentation.documentationResult.editableFile.id
        );
      }
    },
    skip: !documentationId,
    notifyOnNetworkStatusChange: true,
  });

  const [createDocumentation, { loading: isCreatingLoading }] = useMutation(
    CREATE_DOCUMENTATION_MUTATION,
    {
      update(cache, { data: { createDocumentation } }) {
        const newDocumentation = createDocumentation.documentation;
        cache.modify({
          fields: {
            allDocumentations(existingDocumentationRefs = []) {
              const newDocumentationRef = cache.writeFragment({
                data: newDocumentation,
                fragment: gql`
                  fragment NewDocumentation on Documentation {
                    id
                    name
                    project {
                      title
                    }
                  }
                `,
              });
              return [...existingDocumentationRefs, newDocumentationRef];
            },
          },
        });
      },
      onCompleted: data => {
        navigate(
          `/documentation/${data.createDocumentation.documentation.id}?view=generation`
        );
        setDocumentId(data.createDocumentation.documentation.id);
        setTabValue('generation');
      },
    }
  );

  const currentTemplate = useRecoilValue(documentTemplateAtom);
  const { project } = useProject();
  const handleCreateDocumentation = async () => {
    try {
      await createDocumentation({
        variables: {
          name: documentTitle,
          documentationType:
            currentTemplate?.documentationType ??
            DocumentationTypeEnum.Generated,
          projectId: project?.id,
          components: documentComponents.map(item => ({
            query: item.query ?? '',
            dataSource:
              (item.dataSource as DataSourceEnum) ?? DataSourceEnum.Code,
            componentType: item.type,
            args: [],
            name: item.name,
          })),
          documentCreator: 'USER',
          documentationScope: currentTemplate?.documentationScope,
        },
      });
      toast.success('Documentation created successfully');
    } catch (error) {
      console.error('Error creating documentation:', error);
      toast.error('Failed to create documentation');
    }
  };

  const [saveDocumentation, { loading: isSaveLoading }] = useMutation(
    UPDATE_DOCUMENTATION_MUTATION
  );

  const handleSaveDocumentation = async () => {
    saveDocumentation({
      onCompleted: () => {
        toast.success('Documentation saved');
      },
      onError: () => toast.error('Failed'),
      variables: {
        components: documentComponents.map(item => ({
          query: item.query ?? '',
          dataSource:
            (item.dataSource as DataSourceEnum) ?? DataSourceEnum.Code,
          componentType: item.type,
          args: [],
          name: item.name,
        })),
        name: documentTitle ?? 'Document Title',
        id: documentationId ?? '',
      },
    });
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('view', newValue);
    setSearchParams(newSearchParams);
  };

  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ChevronLeftIcon
            sx={{ ':hover': { cursor: 'pointer' } }}
            onClick={() => navigate('/documentations')}
          />
          <DocumentTitle />
        </Box>
        <Box sx={{ marginRight: '1.5rem', display: 'flex', gap: '0.5rem' }}>
          {data?.documentation?.documentationScope ===
            DocumentationScopeEnum.Experiment && (
            <>
              <Typography variant="subtitle1">Experiment:</Typography>
              <DropdownSelector
                color="white"
                elements={experimentIds}
                selectedElement={selectedExperiment}
                setSelectedElement={string => {
                  setSelectedExperiment(string);
                }}
              />
            </>
          )}
        </Box>
      </Box>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'inline-block' }}>
            {!documentationId && (
              <TabList
                onChange={handleTabChange}
                sx={{
                  background: colorThemes.DARK_BLUE_800,
                  marginTop: '1rem',
                }}
              >
                <Tab
                  label="Template"
                  value="template"
                  sx={{ background: colorThemes.DARK_BLUE_800 }}
                />
                <Tab
                  label="Components"
                  value="components"
                  sx={{ background: colorThemes.DARK_BLUE_800 }}
                />
              </TabList>
            )}
            {documentationId && (
              <TabList
                onChange={handleTabChange}
                sx={{
                  background: colorThemes.DARK_BLUE_800,
                  marginTop: '1rem',
                }}
              >
                <Tab
                  label="Components"
                  value="components"
                  sx={{ background: colorThemes.DARK_BLUE_800 }}
                />
                <Tab
                  label="Generation"
                  value="generation"
                  sx={{ background: colorThemes.DARK_BLUE_800 }}
                />
                <Tab
                  label="Edit"
                  value="edit"
                  sx={{ background: colorThemes.DARK_BLUE_800 }}
                />
              </TabList>
            )}
          </Box>
          <ButtonGroup>
            {(tabValue === 'components' || tabValue === 'template') && (
              <LoadingButton
                variant="contained"
                loading={documentationId ? isSaveLoading : isCreatingLoading}
                loadingIndicator={<CircularProgress size={24} />}
                onClick={
                  documentationId
                    ? handleSaveDocumentation
                    : handleCreateDocumentation
                }
                sx={{
                  '&:hover': {
                    backgroundColor: colorThemes.YELLOW_200,
                  },
                  marginRight: '1.5rem',
                  height: '2.2rem',
                  '&:disabled': {
                    backgroundColor: colorThemes.YELLOW_600,
                  },
                }}
              >
                {documentationId ? 'Save Changes' : 'Create Documentation'}
              </LoadingButton>
            )}
          </ButtonGroup>
        </Box>
        {loading ? (
          <Loading message="Loading" />
        ) : (
          <Box>
            {documentationId ? (
              <>
                <TabPanel value="components">
                  <ComponentsTab />
                </TabPanel>
                <TabPanel value="generation">
                  <GenerationTab />
                </TabPanel>
                <TabPanel value="edit">
                  <EditorTab />
                </TabPanel>
              </>
            ) : (
              <>
                <TabPanel value="template">
                  <TemplateTab />
                </TabPanel>
                <TabPanel value="components">
                  <ComponentsTab />
                </TabPanel>
              </>
            )}
          </Box>
        )}
      </TabContext>
      <ToastContainer />
    </Box>
  );
}

function DocumentTitle() {
  const [searchParams] = useSearchParams();
  const [documentTitle, setDocumentTitle] = useRecoilState(documentTitleAtom);

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDocumentTitle(event.target.value);
  }

  const editable =
    searchParams.get('view') === 'components' ||
    searchParams.get('view') === 'template';
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <input
        type="text"
        value={documentTitle}
        onChange={handleTitleChange}
        placeholder={'Document Title'}
        disabled={!editable}
        style={{
          border: 'none',
          outline: 'none',
          resize: 'none',
          backgroundColor: colorThemes.DARK_BLUE_800,
          color: 'white',
          width: '600px',
          fontWeight: 'bold',
          padding: '8px',
          fontSize: '1.6rem',
        }}
      />
      {/* Optional Pencil Icon but alignment is off. I couldn't figure out the required distance to left */}
      {/* {editable && (
        <DriveFileRenameOutlineIcon
          sx={{
            position: 'absolute',
            left: `${documentTitle.length * 15 + 2}px`,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      )} */}
    </Box>
  );
}

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  Box,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { colorThemes } from '../theme';

export default function InternalContextStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsInternalParties,
    append: appendInternalParty,
    remove: removeInternalParty,
  } = useFieldArray({
    control,
    name: 'internalParties',
  });
  const addFieldParty = () => {
    appendInternalParty({ name: '', requirements: '' });
  };
  return (
    <>
      <Typography variant="h6" marginBottom={'0.5rem'} fontWeight={600}>
        Internal Context
      </Typography>
      <TextField
        id="outlined-basic"
        label="Context"
        variant="outlined"
        {...register('internalContext')}
        sx={{ width: '100%', marginBottom: '1rem' }}
      />
      <TextField
        id="outlined-basic"
        label="Contractual Obligations"
        variant="outlined"
        {...register('contractualObligations')}
        sx={{ width: '100%' }}
      />
      <Typography
        variant="h6"
        marginTop={'1.5rem'}
        marginBottom={'0.5rem'}
        fontWeight={600}
      >
        Internal Parties
      </Typography>
      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
          gap: '4px',
          paddingRight: '1rem',
          backgroundColor: colorThemes.ACTION_BLUE,
        }}
        onClick={addFieldParty}
      >
        <AddIcon />
        Add Party
      </Button>
      <Grid container>
        {fieldsInternalParties.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginTop={'5px'}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Party"
                variant="outlined"
                aria-placeholder={'Party'}
                {...register(`internalParties.${index}.name`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                label="Requirements"
                variant="outlined"
                {...register(`internalParties.${index}.requirements`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeInternalParty(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6" marginTop={'1.5rem'} fontWeight={600}>
        Further
      </Typography>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Controller
          name="isClimateRelevant"
          render={({ field: { value, onChange } }) => (
            <Checkbox onChange={onChange} checked={value} />
          )}
        />
        <Typography sx={{ fontSize: '1rem' }}>
          Is Climate a relevant issue?
        </Typography>
      </Box>
    </>
  );
}

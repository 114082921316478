import React, { useState } from 'react';
import {
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItemIcon,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';

interface FormNavigationSidebarProps {
  categories: Array<{
    categoryName: string;
    sections: Array<{ label: string; infoColor?: string }>;
  }>;
  activeSection: string;
  onNavigate: (categoryIndex: number, sectionIndex: number) => void;
}

export const buildRiskSectionId = (catIdx: number, secIdx: number) => {
  return `${catIdx}-${secIdx}`;
};

export default function FormNavigationSidebar({
  categories,
  activeSection,
  onNavigate,
}: FormNavigationSidebarProps) {
  const [openCategories, setOpenCategories] = useState<{
    [key: string]: boolean;
  }>({});

  const handleToggle = (categoryName: string) => {
    setOpenCategories(prevOpenCategories => ({
      ...prevOpenCategories,
      [categoryName]: !prevOpenCategories[categoryName],
    }));
  };

  return (
    <List sx={{ width: '100%' }} component="nav">
      {categories.map((category, catIdx) => (
        <React.Fragment key={category.categoryName}>
          <ListItemButton onClick={() => handleToggle(category.categoryName)}>
            <ListItemText primary={category.categoryName} />
            {openCategories[category.categoryName] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={openCategories[category.categoryName]}
            timeout="auto"
            unmountOnExit
          >
            {category.sections.map((section, secIdx) => (
              <ListItemButton
                key={section.label}
                onClick={() => onNavigate(catIdx, secIdx)}
                sx={{
                  pl: 4,
                  bgcolor:
                    activeSection === buildRiskSectionId(catIdx, secIdx)
                      ? 'action.selected'
                      : 'background.paper',
                }}
              >
                <ListItemText primary={section.label} />
                {section.infoColor && (
                  <ListItemIcon>
                    <CircleIcon
                      sx={{ color: section.infoColor, fontSize: 16 }}
                    />
                  </ListItemIcon>
                )}
              </ListItemButton>
            ))}
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
}

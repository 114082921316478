import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { toast, ToastContainer } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { gql } from '../../__generated__/gql';

import 'react-toastify/dist/ReactToastify.css';
import { Experiment } from '../../__generated__/gql/graphql';

import ExperimentMetricTable from './ExperimentMetricTable';
import ExperimentDeltaTable from './ExperimentDeltaTable';
import ChartViewModal from './ChartViewModal';
import ExperimentMetricChart from './ExperimentMetricChart';
import theme from '../../theme';
import NotFound from '../../components/NotFound';
import Timestamp from '../../components/Timestamp';
import { detailExperimentState } from '../TreeSettingsAtoms';
import TabPanel from '../../components/TabPanel';
import UploadedFilesSelector from './UploadedFilesSelector';
import EvaluationOverview from './evaluations/EvaluationOverview';
import { LoadingButton } from '@mui/lab';
import { copyToClipboard } from '../../components/clipoadUtils';

type Props = {
  projectId: string;
  experiment: Experiment | undefined;
  businessMeasurement?: any;
  gitSyncEnabled?: boolean;
};

const ADD_COMMENT_TO_EXPERIMENT = gql(`
  mutation addCommentToExperiment($experimentId: String!, $comment: String!) {
    addExperimentComment(experimentId: $experimentId, comment: $comment) {
      experiment {
        id
        comments
      }
    }
  }
`);
const EDIT_EXPERIMENT_HYPOTHESIS = gql(`
mutation editHypothesis($experimentId: String!, $hypothesis: String!) {
  editExperimentHypothesis(experimentId: $experimentId, hypothesis: $hypothesis) {
    experiment {
      id
      hypothesis
    }
  }
}
`);

const GET_EXPERIMENT_DATA = gql(`
query getExperiment($experimentId: String!) {
  experiment(id: $experimentId) {
    id
    title
    parentExperimentId
    comments
    hypothesis
    creationTimestamp
    metricDelta
    paramDelta
    gitCommitHash
    dataSetStats {
      id 
      set
    }
    artifacts {
      id
      name
      size
      contentType
      url
      tags
      fullPath
    }
    instanceRuns {
      id
      experimentId
      metrics
      parameters
      isComplete
      metricsHistory{
        metricName
        history {
          value 
          step
          timeStamp
        }
      }
    }
  }
}
`);

function ExperimentDetailViewSidebar({
  projectId,
  experiment,
  businessMeasurement,
  gitSyncEnabled = false,
}: Props) {
  const [comments, setComments] = useState('');
  const setDetailExperiment = useSetRecoilState(detailExperimentState);
  const [tab, setTab] = useState(0);
  const [isHypothesisEditOpen, setIsHypothesisEditOpen] = useState(false);
  const [chartView, setChartView] = useState<
    [string, Array<any>] | undefined
  >();
  const [selectedMetric, setSelectedMetric] = useState('');
  const [hypothesis, setHypothesis] = useState(experiment?.hypothesis ?? '');
  const { data: experimentData } = useSuspenseQuery(GET_EXPERIMENT_DATA, {
    variables: { experimentId: experiment!.id },
  });

  const [commitCommentsMutation, { loading: commentsAreUpdating }] =
    useMutation(ADD_COMMENT_TO_EXPERIMENT);
  const [commitHypothesisMutation] = useMutation(EDIT_EXPERIMENT_HYPOTHESIS);

  useEffect(() => {
    if (experimentData) {
      setDetailExperiment(experimentData?.experiment ?? undefined);
    }
  }, [experimentData, setDetailExperiment]);

  useEffect(() => {
    setComments(experiment?.comments ?? '');
  }, [experiment]);

  const instanceRuns = experiment?.instanceRuns ?? [];
  const sampleInstanceRun = instanceRuns[0];
  const param = sampleInstanceRun?.parameters ?? [];
  const fetchedHypothesis = experiment?.hypothesis ?? '';
  const metrics = sampleInstanceRun?.metrics ?? [];
  const dataSetStats = experiment?.dataSetStats ?? [];
  const paramDelta = experiment?.paramDelta ?? [];
  const metricDelta = experiment?.metricDelta ?? [];
  const metricsHistory = sampleInstanceRun?.metricsHistory ?? [];
  const gitCommitHash = experiment?.gitCommitHash ?? '';
  const businessEstimation = (() => {
    if (!businessMeasurement || !metrics) {
      return undefined;
    }
    return [
      'truePositive',
      'falsePositive',
      'trueNegative',
      'falseNegative',
    ].reduce<number | undefined>(
      (estimation: number | undefined, measurement: string) => {
        const estimatedFactor = businessMeasurement[measurement];
        const value = metrics[measurement];
        let reducedEstimation = estimation;
        if (estimatedFactor !== undefined && value !== undefined) {
          reducedEstimation =
            (reducedEstimation !== undefined ? reducedEstimation : 0) +
            estimatedFactor * value;
        }
        return reducedEstimation;
      },
      undefined
    );
  })();

  const handleCommentsChangeEvent = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setComments(e?.target?.value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleHypothesisEditCancel = () => {
    setHypothesis(fetchedHypothesis);
    setIsHypothesisEditOpen(false);
  };

  const notifyHypothesisSaveSuccess = () =>
    toast.success('Hypothesis saved.', {
      position: 'top-center',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  const notifyHypothesisSaveFailed = () =>
    toast.error('Error saving changes.', {
      position: 'top-center',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  return (
    <>
      <Grid container p="10px 20px 20px 20px" justifyContent="space-between">
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography
                variant="h4"
                component="h1"
                style={{ margin: 0, cursor: 'pointer' }}
                onClick={() =>
                  copyToClipboard(
                    experiment!.id,
                    'Experiment ID copied to clipboard!'
                  )
                }
              >
                Experiment #{experiment!.id}
              </Typography>
              <Timestamp timestamp={experiment!.creationTimestamp ?? 0} />
            </Box>
            {sampleInstanceRun?.isComplete ? (
              <Chip
                sx={{ m: '20px 0 20px 20px' }}
                icon={<CheckCircleOutlineIcon />}
                label="Complete run"
                color="success"
                variant="outlined"
              />
            ) : (
              <Tooltip title="The model run was interrupted by the user.">
                <Chip
                  sx={{ m: '20px 0 20px 20px' }}
                  icon={<ErrorOutlineIcon />}
                  label="Partial run"
                  color="warning"
                  variant="outlined"
                />
              </Tooltip>
            )}
          </Box>
          <Divider
            sx={{ borderBottomWidth: 1, bgcolor: '#FFFFFF', marginTop: '8px' }}
          />
        </Grid>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Run Facts" id="simple-tab-0" />
          <Tab label="Artifacts" id="simple-tab-1" />
          <Tab label="Data Set ID" id="simple-tab-2" />
          <Tab label="Files" id="simple-tab-4" />
          <Tab label="Evaluations" id="simple-tab-5" />
        </Tabs>
      </Grid>

      <TabPanel p="0 20px 10px 20px" value={tab} index={0}>
        <Grid container gap={2} justifyContent="space-between">
          <Grid
            item
            xs={businessEstimation ? 8 : 12}
            sx={{
              bgcolor: '#001529',
              padding: 2,
              borderRadius: theme.spacing(1),
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6">Hypothesis</Typography>
              {!isHypothesisEditOpen && (
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                  onClick={() => setIsHypothesisEditOpen(true)}
                >
                  <ModeEditIcon />
                </IconButton>
              )}
            </Box>
            {isHypothesisEditOpen ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Change Hypothesis"
                  value={hypothesis}
                  multiline
                  rows={2}
                  sx={{
                    marginTop: '14px',
                  }}
                  onChange={e => setHypothesis(e.target.value)}
                />
                <Box
                  sx={{
                    mt: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      commitHypothesisMutation({
                        variables: {
                          experimentId: experiment!.id,
                          hypothesis,
                        },
                        onCompleted: () => {
                          notifyHypothesisSaveSuccess();
                          setIsHypothesisEditOpen(false);
                        },
                        onError: () => {
                          notifyHypothesisSaveFailed();
                        },
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="warning"
                    onClick={handleHypothesisEditCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography>{hypothesis}</Typography>
            )}
          </Grid>
          {gitCommitHash ? (
            <Box
              sx={{
                bgcolor: '#001529',
                borderRadius: theme.spacing(1),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 2,
                width: '100%',
              }}
            >
              <Typography variant="h6">Git Commit Hash</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {gitCommitHash}
                </Typography>
                <IconButton
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(gitCommitHash as string);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <NotFound subject="commit-hash" />
          )}
          {businessEstimation !== undefined && (
            <Grid
              item
              xs={3}
              sx={{
                bgcolor: '#001529',
                padding: 2,
                borderRadius: theme.spacing(1),
              }}
            >
              <Typography>Business Value Estimation</Typography>
              <Typography variant="h5">
                {businessEstimation.toFixed(3)}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={3.8}
            sx={{
              bgcolor: '#001529',
              padding: 2,
              borderRadius: theme.spacing(1),
            }}
          >
            <Typography variant="h6">Parameters</Typography>
            <ExperimentMetricTable
              data={param}
              displayTrend
              delta={paramDelta}
            />
          </Grid>
          <Grid
            item
            xs={3.8}
            sx={{
              bgcolor: '#001529',
              borderRadius: theme.spacing(1),
              padding: 2,
            }}
          >
            <Typography variant="h6">Metrics</Typography>
            <ExperimentMetricTable
              data={metrics}
              displayTrend
              delta={metricDelta}
            />
          </Grid>
          <Grid
            item
            xs={3.8}
            sx={{
              bgcolor: '#001529',
              borderRadius: theme.spacing(1),
              padding: 2,
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Changed Variables
            </Typography>
            <ExperimentDeltaTable
              title="Parameter Delta"
              experimentId={experiment!.id}
              isMetric={false}
            />
            <ExperimentDeltaTable
              title="Metric Delta"
              experimentId={experiment!.id}
              isMetric
            />
          </Grid>
          {metricsHistory.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: '#001529',
                borderRadius: theme.spacing(1),
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Metrics History
                </Typography>
                <Select
                  labelId="comparing-metric-select-label"
                  id="comparing-metric-select"
                  value={selectedMetric}
                  label="Metric"
                  onChange={e => setSelectedMetric(e.target.value as string)}
                  sx={{ marginTop: '-5px' }}
                >
                  {metricsHistory.map(historyWrapper => (
                    <MenuItem
                      key={historyWrapper?.metricName}
                      value={historyWrapper?.metricName as string}
                    >
                      {historyWrapper?.metricName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                style={{
                  gap: '12px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {metricsHistory
                  .filter(
                    historyEntry => historyEntry?.metricName === selectedMetric
                  )
                  .map(historyWrapper => (
                    <Card
                      key={historyWrapper?.metricName}
                      sx={{
                        width: '90%',
                        height: 250,
                      }}
                      onClick={() =>
                        setChartView([
                          historyWrapper?.metricName as string,
                          historyWrapper?.history as Array<any>,
                        ])
                      }
                    >
                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <ExperimentMetricChart
                          metric={
                            historyWrapper?.metricName?.toLowerCase() as string
                          }
                          data={historyWrapper?.history as Array<any>}
                          preview
                        />
                      </CardContent>
                    </Card>
                  ))}
                <ChartViewModal
                  data={chartView}
                  onClose={() => setChartView(undefined)}
                />
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: '#001529',
              borderRadius: theme.spacing(1),
              padding: 2,
            }}
          >
            <TextField
              label="Comments"
              fullWidth
              multiline
              rows={4}
              value={comments}
              onChange={handleCommentsChangeEvent}
            />
            <LoadingButton
              loading={commentsAreUpdating}
              sx={{ mt: '10px' }}
              fullWidth
              variant="outlined"
              onClick={async () => {
                await commitCommentsMutation({
                  variables: {
                    experimentId: experiment!.id,
                    comment: comments,
                  },
                });
                toast.success('Comments Updated', {
                  position: 'top-center',
                  autoClose: 2500,
                });
              }}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel p="0 20px 10px 20px" height="100%" value={tab} index={1}>
        {(experiment?.artifacts?.length ?? 0) > 0 ? (
          <List>
            {experiment!.artifacts!.map(artifact => (
              <React.Fragment key={artifact?.id}>
                <ListItem>
                  <ListItemText
                    primary={artifact?.name}
                    secondary={
                      <>
                        <Typography>{`${artifact?.contentType} - ${artifact?.size} B`}</Typography>
                        {artifact?.tags && (
                          <Typography>
                            Tags: {artifact?.tags?.join(', ')}
                          </Typography>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="Download"
                      onClick={() => window.open(artifact?.url)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider
                  sx={{
                    borderBottomWidth: 1,
                    bgcolor: '#FFFFFF',
                    margin: '4px 0',
                  }}
                />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <NotFound subject="artifacts" />
        )}
      </TabPanel>
      <TabPanel p="0 20px 10px 20px" value={tab} index={2}>
        {dataSetStats ? (
          <Box
            sx={{
              borderRadius: theme.spacing(1),
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 2,
              width: '100%',
            }}
          >
            {Object.entries(dataSetStats).map(
              ([, setInfo]) =>
                setInfo && (
                  <Box
                    sx={{
                      bgcolor: '#001529',
                      margin: theme.spacing(1),
                      borderRadius: theme.spacing(1),
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 2,
                      width: '25%',
                    }}
                  >
                    <Typography variant="h6">{setInfo.set}</Typography>
                    <Typography>{'ID: ' + setInfo.id}</Typography>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(setInfo.id as string);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                )
            )}
          </Box>
        ) : (
          <NotFound subject="dataset-id" />
        )}
      </TabPanel>
      <TabPanel p="0 20px 10px 20px" height="100%" index={3} value={tab}>
        <UploadedFilesSelector
          projectId={projectId}
          experimentId={experiment!.id}
          gitSyncEnabled={gitSyncEnabled}
        />
      </TabPanel>
      <TabPanel p="0 20px 10px 20px" value={tab} index={4}>
        <EvaluationOverview experimentId={experiment!.id} />
      </TabPanel>
      <ToastContainer />
    </>
  );
}

export default ExperimentDetailViewSidebar;

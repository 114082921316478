import { Card, CardContent, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DateTime } from 'luxon';
import './jupyterStyles.css';
import Ansi from 'ansi-to-react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { colorThemes } from '../../../../../theme';
import { Artifact } from '../../../../../__generated__/gql/graphql';

const commonStyles = {
  borderRadius: '8px',
  overflow: 'hidden',
};

interface ArtifactProps {
  artifact: Artifact;
}

interface RowProps extends ListChildComponentProps {
  data: string[];
}

const Row: React.FC<RowProps> = ({ index, style, data }) => (
  <div style={style}>
    <Ansi>{data[index]}</Ansi>
  </div>
);

export default function ArtifactViewer({ artifact }: ArtifactProps) {
  const ansiLines = (artifact.ansiText ?? '').split('\n');
  const renderContent = () => {
    switch (artifact.contentType) {
      case 'text/html':
        return (
          <div
            className="jupyter-notebook"
            style={{
              width: '100%',
              height: 'auto',
              backgroundColor: 'white',
              border: 'none',
              ...commonStyles,
            }}
            dangerouslySetInnerHTML={{ __html: artifact.html ?? '' }}
          />
        );
      case 'image/png':
      case 'image/jpeg':
        return (
          <div
            style={{
              width: '100%',
              backgroundColor: 'white',
              ...commonStyles,
            }}
          >
            <img
              src={artifact.url}
              alt="Image Content"
              style={{
                maxWidth: '100%',
                height: 'auto',
                display: 'block',
                marginLeft: 0,
              }}
            />
          </div>
        );
      case 'text/plain':
        return (
          <div
            style={{
              maxHeight: '500px',
              width: '100%',
              backgroundColor: colorThemes.GREY_600,
              borderRadius: '8px',
            }}
          >
            <List
              height={500}
              itemCount={ansiLines.length}
              itemSize={50}
              width="100%"
              itemData={ansiLines}
            >
              {Row}
            </List>
          </div>
        );

      default:
        return (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Typography variant="body1" gutterBottom>
              Could not display artifact
            </Typography>
            <a
              href={artifact.url}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <CloudDownloadIcon style={{ fontSize: 'large' }} />
              <Typography variant="body2">Download</Typography>
            </a>
          </div>
        );
    }
  };

  return (
    <Card raised sx={{ width: '100%', margin: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Created on:{' '}
          {DateTime.fromSeconds(artifact.creationTimestamp!).toFormat(
            'MMM d yyyy - h:mma'
          )}
        </Typography>
        <div style={{ marginTop: '20px' }}>{renderContent()}</div>
      </CardContent>
    </Card>
  );
}

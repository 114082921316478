import { Card, CardContent, CardHeader } from '@mui/material';
import { colorThemes } from '../theme';

interface Props {
  title: string;
  children?: React.ReactNode;
}

export default function SidebarContentField({ title, children }: Props) {
  return (
    <Card sx={{ bgcolor: colorThemes.DARK_BLUE_800, boxShadow: 0 }}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>{children}</CardContent>
    </Card>
  );
}

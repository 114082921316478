import { gql, useMutation, useQuery } from '@apollo/client';
import { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ErrorIcon from '@mui/icons-material/Error';

const REFETCH_BEST_EXPERIMENT = gql(`
  query refetch_best_experiment($projectId: String!) {
  dashboardAnalytics(id: $projectId){
    bestExperiment {
      experimentId
      businessValue
    }
  }
}
`);

const REFETCH_BUSINESS_MEASUREMENT = gql(`
query refetch_business_measurement($projectId: String!) {
project(id: $projectId) {
  businessMeasurement {
    truePositive,
    trueNegative,
    falsePositive,
    falseNegative,
  }
}
}
`);

const FETCH_BEST_EXPERIMENT = gql(`
  query fetch_best_experiment($experimentId: String!) {
  experiment(id: $experimentId) {
    instanceRuns {
      metrics
    }
}
}
`);

const UPDATE_PROJECT_BUSINESS_MEASUREMENT = gql(`
mutation update_project_business_measurement($projectId: String!, $projectBusinessMeasurement: BusinessMeasurementInput) {
  updateProject(projectId: $projectId, businessMeasurement: $projectBusinessMeasurement) {
  project {
    id
  }
  }
}
`);

const BOX_STYLE = {
  width: '30%',
  height: '85px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
  border: '1px solid white',
  borderRadius: '4px',
};

const divider = <Divider sx={{ bgcolor: 'grey' }} />;

const MEASUREMENTS = [
  'truePositive',
  'trueNegative',
  'falsePositive',
  'falseNegative',
];

interface EditBusinessMeasurementProps {
  projectId: string;
  experimentId: string;
  businessMeasurement: any;
  onClose: () => void;
}

export default function EditBusinessMeasurement({
  projectId,
  experimentId,
  businessMeasurement,
  onClose,
}: EditBusinessMeasurementProps) {
  const { loading, data } = useQuery(FETCH_BEST_EXPERIMENT, {
    variables: { experimentId },
  });

  const metrics = data?.experiment?.instanceRuns?.[0]?.metrics ?? {};

  const [factors, setFactors] = useState(businessMeasurement);

  const estimationValue = useMemo(
    () =>
      MEASUREMENTS.reduce((estimation: number, measurement: string) => {
        const metricValue = metrics[measurement];
        const factor = factors[measurement];
        return estimation + (metricValue && factor ? metricValue * factor : 0);
      }, 0),
    [factors, data]
  );

  const [updateBusinessMeasurement] = useMutation(
    UPDATE_PROJECT_BUSINESS_MEASUREMENT,
    {
      refetchQueries: [
        { query: REFETCH_BEST_EXPERIMENT, variables: { projectId } },
        { query: REFETCH_BUSINESS_MEASUREMENT, variables: { projectId } },
      ],
    }
  );

  return (
    <Card sx={{ width: '50%' }}>
      <CardContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Best Experiment
            </Typography>
            <Box
              sx={{
                display: 'flex',
                marginRight: '10px',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6">{experimentId}</Typography>
              <Tooltip title="Different business measurement might lead to different best experiment">
                <PriorityHighIcon />
              </Tooltip>
            </Box>
            {divider}
            {MEASUREMENTS.map((measurement: string) => (
              <Box
                key={measurement}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={BOX_STYLE}>
                  <Typography variant="body2" color="text.secondary">
                    {measurement}
                  </Typography>
                  <Typography variant="h6">
                    {metrics[measurement] !== undefined
                      ? metrics[measurement].toFixed(3)
                      : 'unknown'}
                  </Typography>
                </Box>
                <CloseIcon />
                <Box sx={{ ...BOX_STYLE, width: '36%' }}>
                  <Typography variant="body2" color="text.secondary">
                    factor
                  </Typography>
                  <TextField
                    variant="standard"
                    inputProps={{ min: 0, style: { textAlign: 'center' } }} // eslint-disable-line
                    value={factors[measurement]}
                    onChange={(e: any) =>
                      setFactors({
                        ...factors,
                        [measurement]: e.target.value,
                      })
                    }
                    error={Number.isNaN(+factors[measurement])}
                    /* eslint-disable */
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {Number.isNaN(+factors[measurement]) && (
                            <Tooltip title="Business measurement value should be number">
                              <ErrorIcon />
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    /* eslint-enable */
                  />
                </Box>
                <DragHandleIcon />
                <Box sx={BOX_STYLE}>
                  {(metrics[measurement] * factors[measurement]).toFixed(3)}
                </Box>
              </Box>
            ))}
            {divider}
            <Box
              sx={{
                display: 'flex',
                flex: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Estimation Value:
              </Typography>
              <Typography variant="h6">{estimationValue.toFixed(3)}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={{ color: 'red' }}
                onClick={() => {
                  onClose();
                  setFactors(businessMeasurement);
                }}
              >
                CLOSE
              </Button>
              <Button
                onClick={() => {
                  const updatedBusinessMeasurement = MEASUREMENTS.reduce(
                    (update: any, measurement: string) => ({
                      ...update,
                      [measurement]: !Number.isNaN(+factors[measurement])
                        ? +factors[measurement]
                        : businessMeasurement[measurement],
                    }),
                    {}
                  );
                  updateBusinessMeasurement({
                    variables: {
                      projectId,
                      projectBusinessMeasurement: updatedBusinessMeasurement,
                    },
                    onCompleted: () => {
                      setFactors(updatedBusinessMeasurement);
                      onClose();
                    },
                  });
                }}
              >
                UPDATE
              </Button>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

import React, { useState } from 'react';
import {
  CardActions,
  Box,
  Button,
  TextField,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import {
  EvaluationOutcomeEnum,
  EvaluationProgressEnum,
  GetEvaluationsQuery,
} from '../../../__generated__/gql/graphql';
import EvaluationInformation from './EvaluationInformation';

const ADD_MANUAL_EVALUATION_RESULT_MUTATION = gql`
  mutation AddManualEvaluationResult(
    $evaluationId: String!
    $evaluator: String
    $outcome: EvaluationOutcomeEnum!
    $progress: EvaluationProgressEnum!
    $reason: String!
    $sources: [String]
  ) {
    addManualEvaluationResult(
      evaluationId: $evaluationId
      evaluator: $evaluator
      outcome: $outcome
      progress: $progress
      reason: $reason
      sources: $sources
    ) {
      result {
        id
        progress
        outcome
        reason
        sources
        endTime
      }
    }
  }
`;

interface ManualEvaluationResultInput {
  evaluator: string;
  outcome: string;
  progress: string;
  reason: string;
  sources: string[];
}

// I love typescript acrobatics
type ExperimentType = NonNullable<GetEvaluationsQuery['experiment']>;
type EvaluationsArrayType = NonNullable<ExperimentType['manualEvaluations']>;
type SingleEvaluationType = NonNullable<
  EvaluationsArrayType extends (infer U)[] ? U : never
>;

interface Props {
  evaluation: SingleEvaluationType;
  experimentId: string;
  queryCacheUpdate: TypedDocumentNode;
}

export default function ManualEvaluationCard({
  evaluation,
  experimentId,
  queryCacheUpdate,
}: Props) {
  const [openModal, setOpenModal] = useState(false);

  const [formValues, setFormValues] = useState<ManualEvaluationResultInput>({
    evaluator: '',
    outcome: '',
    progress: '',
    reason: '',
    sources: [],
  });

  const [addManualEvaluationResult, { loading }] = useMutation(
    ADD_MANUAL_EVALUATION_RESULT_MUTATION,
    {
      variables: {
        ...formValues,
        evaluationId: evaluation.id,
      },
      update: (cache, { data: { addManualEvaluationResult } }) => {
        const newResult = addManualEvaluationResult.result;

        const { experiment } = cache.readQuery({
          query: queryCacheUpdate,
          variables: { experimentId },
        }) as any;

        const updatedEvaluations = (experiment.manualEvaluations ?? []).map(
          (cachedEvaluation: any) => {
            if (cachedEvaluation.id === evaluation.id) {
              return {
                ...cachedEvaluation,
                mostRecentResult: newResult,
              };
            }
            return cachedEvaluation;
          }
        );

        cache.writeQuery({
          query: queryCacheUpdate,
          data: {
            experiment: {
              ...experiment,
              manualEvaluations: updatedEvaluations,
            },
          },
          variables: { experimentId },
        });
      },
    }
  );

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  // Handler for Select changes
  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.stopPropagation(); // Prevent card click event from firing
    e.preventDefault();
    await addManualEvaluationResult();
    handleCloseModal();
  };

  return (
    <Box>
      <EvaluationInformation
        evaluation={evaluation}
        actions={
          <CardActions>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleOpenModal}
            >
              Add Result
            </Button>
          </CardActions>
        }
      />

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add Manual Evaluation Result
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <FormGroup>
              <TextField
                margin="dense"
                id="evaluator"
                label="Evaluator"
                type="text"
                fullWidth
                name="evaluator"
                onChange={handleInputChange}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="outcome-label">Outcome</InputLabel>
                <Select
                  labelId="outcome-label"
                  id="outcome"
                  name="outcome"
                  value={formValues.outcome}
                  label="Outcome"
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value={EvaluationOutcomeEnum.Failure}>
                    Failure
                  </MenuItem>
                  <MenuItem value={EvaluationOutcomeEnum.NotApplicable}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={EvaluationOutcomeEnum.Success}>
                    Success
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="dense">
                <InputLabel id="progress-label">Progress</InputLabel>
                <Select
                  labelId="progress-label"
                  id="progress"
                  name="progress"
                  value={formValues.progress}
                  label="Progress"
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value={EvaluationProgressEnum.Aborted}>
                    Aborted
                  </MenuItem>
                  <MenuItem value={EvaluationProgressEnum.Completed}>
                    Completed
                  </MenuItem>
                  <MenuItem value={EvaluationProgressEnum.Running}>
                    Running
                  </MenuItem>
                  <MenuItem value={EvaluationProgressEnum.Scheduled}>
                    Scheduled
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                id="reason"
                label="Reason"
                type="text"
                fullWidth
                name="reason"
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                id="sources"
                label="Sources"
                type="text"
                fullWidth
                name="sources"
                helperText="Comma separated values"
                onChange={handleInputChange}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

import React from 'react';
import { Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { EvaluationOutcomeEnum } from '../__generated__/gql/graphql';
import theme from '../theme';

interface EvaluationOutcomeIconProps {
  outcome?: EvaluationOutcomeEnum;
}

const EvaluationOutcomeIcon: React.FC<EvaluationOutcomeIconProps> = ({
  outcome,
}) => {
  const getTitle = (outcome?: EvaluationOutcomeEnum): string => {
    switch (outcome) {
      case EvaluationOutcomeEnum.Success:
        return 'Success';
      case EvaluationOutcomeEnum.Failure:
        return 'Failure';
      default:
        return 'Not Applicable';
    }
  };

  const icon = (() => {
    switch (outcome) {
      case EvaluationOutcomeEnum.Success:
        return <CheckCircleOutlineIcon style={{ color: 'green' }} />;
      case EvaluationOutcomeEnum.Failure:
        return <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} />;
      default:
        return <HelpOutlineIcon sx={{ color: 'grey' }} />;
    }
  })();

  return <Tooltip title={getTitle(outcome)}>{icon}</Tooltip>;
};

export default EvaluationOutcomeIcon;

import { useMemo, useState } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import {
  Experiment,
  MetricsHistory,
  MetricsHistoryEntry,
} from '../../__generated__/gql/graphql';
import string2rbg from './String2RBG';

interface ComparisonChartProps {
  experiments: Experiment[];
}

export default function ComparisonChart({ experiments }: ComparisonChartProps) {
  const [selectedMetricId, setSelectedMetricId] = useState('');
  const [metricsHistories, metricIds, experimentColors] = useMemo(() => {
    const metricExperimentHistories = {} as {
      [metricId: string]: Map<string, MetricsHistoryEntry[]>;
    };
    const colors = {} as { [experimentId: string]: string };
    experiments.forEach((experiment: Experiment) => {
      colors[experiment.id] = string2rbg(experiment.id);
      (experiment.instanceRuns?.[0]?.metricsHistory ?? []).forEach(
        (metricHistory: MetricsHistory | null) => {
          const metricName = metricHistory?.metricName as string;
          let experimentHistories = metricExperimentHistories[metricName];
          if (!experimentHistories) {
            /* eslint-disable */
            metricExperimentHistories[metricName] = experimentHistories =
              new Map<string, any[]>();
            /* eslint-enable */
          }
          experimentHistories.set(
            experiment.id,
            metricHistory?.history as MetricsHistoryEntry[]
          );
        }
      );
    });
    const metricIDs = [] as string[];
    const commonMetricHistories = Object.entries(
      metricExperimentHistories
    ).reduce(
      (result, [metricId, experimentHistories]) => {
        if (experimentHistories.size === experiments.length) {
          const series = [] as { name: string; data: number[] }[];
          let epoches = [] as number[];
          experimentHistories.forEach(
            (histories: MetricsHistoryEntry[], exprimentId: string) => {
              const values = [] as number[];
              const steps = [] as number[];
              histories.forEach((history: MetricsHistoryEntry) => {
                values.push(history?.value as number);
                steps.push(history?.step as number);
              });
              epoches = steps.length > epoches.length ? steps : epoches;
              series.push({ name: exprimentId, data: values });
            }
          );
          /* eslint-disable */
          result[metricId] = { epoches, series };
          metricIDs.push(metricId);
          /* eslint-enable */
        }
        return result;
      },
      {} as {
        [metricId: string]: {
          epoches: number[];
          series: { name: string; data: number[] }[];
        };
      }
    );
    if (metricIDs.length > 0 && !metricIDs.includes(selectedMetricId)) {
      setSelectedMetricId(metricIDs[0]);
    }
    return [commonMetricHistories, metricIDs, colors];
  }, [experiments]);

  if (!selectedMetricId) {
    return (
      <Typography>
        No common metrics history between selected experiment
      </Typography>
    );
  }

  const option = {
    grid: {
      left: '3%',
      bottom: '10%',
      right: '7%',
      top: '15%',
    },
    toolbox: { feature: { saveAsImage: { show: true } } },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const index: number = params[0].dataIndex as number;
        const { epoches, series } = metricsHistories[selectedMetricId];
        const metricValues = series.reduce(
          (values: string, { name, data }) =>
            `${values}<br/> <b style='color:${experimentColors[name]};'>${name}:</b> ${data[index]}`,
          ''
        );
        return `Epoch ${epoches[index]} ${metricValues}`;
      },
    },
    legend: { textStyle: { color: 'white' } },
    xAxis: [
      {
        type: 'category',
        axisTick: { alignWithLabel: true },
        name: 'epoch',
        data: metricsHistories[selectedMetricId].epoches,
      },
    ],
    yAxis: [
      {
        type: 'value',
        position: 'left',
        name: selectedMetricId.toLowerCase(),
        alignTicks: true,
        show: true,
        axisLine: { show: true },
      },
    ],
    series: metricsHistories[selectedMetricId].series.map(serie => ({
      ...serie,
      type: 'line',
      color: experimentColors[serie.name],
      emphasis: {
        focus: 'series',
      },
    })),
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Select
        size="small"
        sx={{ margin: '10px', marginRight: 'auto' }}
        value={selectedMetricId}
        onChange={e => setSelectedMetricId(e.target.value as string)}
      >
        {metricIds.map((metricId: string) => (
          <MenuItem key={metricId} value={metricId}>
            {metricId}
          </MenuItem>
        ))}
      </Select>
      <ReactEcharts option={option} notMerge />
    </Box>
  );
}

import {
  Button,
  Card,
  CardContent,
  Modal,
  CardHeader,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { GET_AUDITS } from './AuditView';
import { toast } from 'react-toastify';
import type {
  CreateGovernanceAuditMutation,
  GetGovernanceFrameworksForAuditsQuery,
  GetAuditorsQuery,
} from '../__generated__/gql/graphql';

interface NewAuditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const GET_AUDIT_FRAMEWORKS = gql(`
  query getGovernanceFrameworksForAudits {
    organization {
      governanceFrameworks {
        id
        description
        name
      }
    }
  }
`);

const GET_AUDITORS = gql(`
  query GetAuditors {
    allUsers(includeAuditors: true) {
      id
      name
      email
    }
  }
`);

const CREATE_AUDIT = gql(`
  mutation CreateGovernanceAudit(
    $frameworkId: String!
    $name: String!
    $description: String
    $auditorIds: [String!]!
  ) {
    createGovernanceAudit(
      frameworkId: $frameworkId
      name: $name
      description: $description
      auditorIds: $auditorIds
    ) {
      audit {
        id
        name
        description
        status
        auditors {
          id
          name
          email
        }
      }
    }
  }
`);

export default function NewAuditModal({ isOpen, onClose }: NewAuditModalProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAuditors, setSelectedAuditors] = useState<string[]>([]);
  const [frameworkId, setFrameworkId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data: frameworksData } =
    useSuspenseQuery<GetGovernanceFrameworksForAuditsQuery>(
      GET_AUDIT_FRAMEWORKS
    );
  const { data: auditorsData } =
    useSuspenseQuery<GetAuditorsQuery>(GET_AUDITORS);

  const [createAudit] = useMutation<CreateGovernanceAuditMutation>(
    CREATE_AUDIT,
    {
      refetchQueries: [{ query: GET_AUDITS }],
      onCompleted: () => {
        setIsLoading(false);
        toast.success('Audit created successfully');
        handleClose();
      },
      onError: error => {
        setIsLoading(false);
        toast.error(`Error creating audit: ${error.message}`);
      },
    }
  );

  const handleClose = () => {
    setName('');
    setDescription('');
    setSelectedAuditors([]);
    setFrameworkId('');
    onClose();
  };

  const handleCreate = async () => {
    setIsLoading(true);
    await createAudit({
      variables: {
        name,
        description,
        frameworkId,
        auditorIds: selectedAuditors,
      },
    });
  };

  const frameworks = frameworksData?.organization?.governanceFrameworks ?? [];
  const auditors = auditorsData?.allUsers ?? [];

  const getMissingRequiredFields = () => {
    const missing = [];
    if (!name) missing.push('Audit Name');
    if (!frameworkId) missing.push('Framework');
    if (selectedAuditors.length === 0) missing.push('Auditors');
    return missing;
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          maxHeight: '90%',
          width: '50%',
          maxWidth: '600px',
          overflowY: 'auto',
        }}
      >
        <CardHeader title="Create New Audit" />
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Audit Name"
              required
              value={name}
              onChange={e => setName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              multiline
              rows={4}
              fullWidth
            />
            <FormControl fullWidth required>
              <InputLabel>Framework</InputLabel>
              <Select
                value={frameworkId}
                label="Framework"
                onChange={e => setFrameworkId(e.target.value)}
              >
                {frameworks.map(framework => (
                  <MenuItem key={framework?.id} value={framework?.id}>
                    {framework?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel>Auditors</InputLabel>
              <Select
                multiple
                value={selectedAuditors}
                label="Auditors"
                onChange={e => {
                  setSelectedAuditors(
                    typeof e.target.value === 'string'
                      ? [e.target.value]
                      : e.target.value
                  );
                }}
              >
                {auditors.map(auditor => (
                  <MenuItem key={auditor?.id} value={auditor?.id}>
                    {auditor?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Tooltip
                title={
                  getMissingRequiredFields().length > 0
                    ? `Missing required fields: ${getMissingRequiredFields().join(
                        ', '
                      )}`
                    : 'Create new audit'
                }
              >
                <span>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    onClick={handleCreate}
                    disabled={
                      !name || !frameworkId || selectedAuditors.length === 0
                    }
                  >
                    Create Audit
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}

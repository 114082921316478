import { Box, Typography } from '@mui/material';
import { colorThemes } from '../../theme';
import { FC } from 'react';
import BSIRiskSection from '../risk_assessment/BSIRiskSection';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import {
  RiskAssessmentCatalogueTypeEnum,
  RiskAssessment,
  RiskTreatment,
  User,
} from '../../__generated__/gql/graphql';
import TreatmentOptionsSection from './TreatmentOptionsSection';
import ResidualRiskSection from './ResidualRiskSection';
import { RISK_FORM_PREFIX } from '../risk_assessment/RiskAssessment';
import StandardRiskSection from '../risk_assessment/StandardRiskSection';

interface RiskTreatmentSectionProps {
  activeRiskSectionStatic: {
    label: string;
    id: string;
    description?: string;
    guidingQuestions?: string;
  };
  availableRiskTreatments: RiskTreatment[];
  allUsers: User[];
  activeRiskSectionId: number;
  riskAssessmentCatalogueType: RiskAssessmentCatalogueTypeEnum;
  methods: UseFormReturn<RiskAssessment>;
}

const RiskTreatmentSection: FC<RiskTreatmentSectionProps> = ({
  activeRiskSectionStatic,
  availableRiskTreatments,
  allUsers,
  activeRiskSectionId,
  riskAssessmentCatalogueType,
  methods,
}) => {
  const renderRiskSection = () => {
    if (
      riskAssessmentCatalogueType === RiskAssessmentCatalogueTypeEnum.Standard
    ) {
      return (
        <StandardRiskSection
          key={`TuvRiskSection-${activeRiskSectionId}`}
          name={`${RISK_FORM_PREFIX}.${activeRiskSectionId}`}
          section={activeRiskSectionStatic}
          control={methods.control}
          readOnly={true}
        />
      );
    } else if (
      riskAssessmentCatalogueType === RiskAssessmentCatalogueTypeEnum.Bsi
    ) {
      return (
        <BSIRiskSection
          key={`BSIRiskSection-${activeRiskSectionId}`}
          name={`${RISK_FORM_PREFIX}.${activeRiskSectionId}`}
          section={activeRiskSectionStatic}
          control={methods.control}
          readOnly={true}
        />
      );
    }
    return null;
  };

  return (
    <Box
      padding={4}
      bgcolor={colorThemes.DARK_BLUE_600}
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap="16px"
      overflow="auto"
      width={'100%'}
    >
      <Typography variant="h4">{activeRiskSectionStatic.label}</Typography>

      <Box
        display={'flex'}
        height={'100%'}
        flexDirection={'column'}
        gap={'16px'}
      >
        <FormProvider {...methods}>
          {renderRiskSection()}

          <TreatmentOptionsSection
            allUsers={allUsers}
            availableRiskTreatments={availableRiskTreatments}
            key={`treatment-options-${activeRiskSectionId}`}
            activeRiskSectionId={activeRiskSectionId}
            control={methods.control}
          />
          <ResidualRiskSection
            key={`ResidualRisk-${activeRiskSectionId}`}
            activeRiskSectionId={activeRiskSectionId}
            control={methods.control}
          />
        </FormProvider>
        <Box minHeight="20px"></Box>
      </Box>
    </Box>
  );
};

export default RiskTreatmentSection;

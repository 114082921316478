import { Drawer } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';

import { Suspense } from 'react';

import { Loading } from '../components/Loading';
import ProjectDetailDrawer, {
  ProjectDetailDrawerProps,
} from './ProjectDetailDrawer';

export default function ProjectDetailCardSuspenseWrapper(
  props: ProjectDetailDrawerProps
) {
  const width = '70%';

  return (
    <Drawer
      anchor="right"
      open={props.showSidebar}
      onClose={() => props.setShowSidebar(false)}
      sx={{
        width,
        right: 0,
        left: 'auto',
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
        },
      }}
    >
      <Suspense fallback={<Loading message="Loading project data ..." />}>
        <ProjectDetailDrawer {...props} />
      </Suspense>
    </Drawer>
  );
}

import { useState } from 'react';
import {
  Card,
  CardContent,
  Modal,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  IconButton,
  Chip,
  Avatar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { listStyles } from '../../theme';
import {
  Comparator,
  Filterable,
  Rule,
  ValueType,
} from '../../__generated__/gql/graphql';

const COMPARISON_TO_STRING = new Map<Comparator, string>([
  [Comparator.StartWith, 'start with'],
  [Comparator.EndWith, 'end with'],
  [Comparator.Contain, 'contain'],
  [Comparator.Equal, 'is equal'],
  [Comparator.Gt, 'greater than'],
  [Comparator.Lt, 'less than'],
  [Comparator.Gte, 'greater than equal'],
  [Comparator.Lte, 'less than equal'],
]);
const COMPARISONS = new Map<ValueType, Array<Comparator>>([
  [
    ValueType.String,
    [
      Comparator.StartWith,
      Comparator.EndWith,
      Comparator.Contain,
      Comparator.Equal,
    ],
  ],
  [
    ValueType.Number,
    [
      Comparator.Gt,
      Comparator.Lt,
      Comparator.Gte,
      Comparator.Lte,
      Comparator.Equal,
    ],
  ],
]);

interface ExperimentFilterModalProps {
  filterables: Filterable[];
  appliedRules: Rule[];
  setAppliedRules: (_: Rule[]) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function ExperimentFilterModal({
  filterables,
  appliedRules,
  setAppliedRules,
  isOpen,
  onClose,
}: ExperimentFilterModalProps) {
  const [selectedFilterable, setSelectedFilterable] = useState<Filterable>(
    filterables[0]
  );
  const [filteringValue, setFilteringValue] = useState<string | number>('');
  const [selectedComparator, setSelectedComparator] = useState<Comparator>(
    Comparator.Equal
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ minWidth: 600 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <Box
              sx={{
                ...listStyles.listContainerBorderStyle,
                minHeight: '250px',
                maxHeight: '350px',
                backgroundColor:
                  appliedRules.length === 0
                    ? listStyles.emptyListBackgroundColor
                    : '',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: appliedRules.length === 0 ? 'center' : '',
                alignItems: 'center',
                overflow: 'auto',
              }}
            >
              {appliedRules.length > 0
                ? appliedRules.map((rule: Rule, i: number) => (
                    <Card
                      key={rule.name}
                      sx={{
                        width: '94%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: '35px',
                        marginTop: '10px',
                        padding: '10px',
                        backgroundColor: listStyles.listItemBackgroundColor,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          minWidth: '90%',
                          gap: '10px',
                        }}
                      >
                        <Chip
                          sx={{ maxWidth: '30%' }}
                          avatar={<Avatar>F</Avatar>}
                          label={rule.name}
                          color="primary"
                          variant="outlined"
                        />
                        <Chip
                          sx={{ maxWidth: '30%' }}
                          avatar={<Avatar>C</Avatar>}
                          label={COMPARISON_TO_STRING.get(rule.comparator!)}
                          color="warning"
                          variant="outlined"
                        />
                        <Chip
                          sx={{ maxWidth: '30%' }}
                          avatar={<Avatar>V</Avatar>}
                          label={rule.value}
                          color="success"
                          variant="outlined"
                        />
                      </Box>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => {
                          const newRules = [...appliedRules];
                          newRules.splice(i, 1);
                          setAppliedRules(newRules);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Card>
                  ))
                : 'No filter applied yet'}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '2px',
                marginTop: '5px',
              }}
            >
              <FormControl size="small" sx={{ width: '25%' }}>
                <Select id="filter-select" value={selectedFilterable.name}>
                  {filterables.map((filterable: Filterable) => (
                    <MenuItem
                      key={filterable.name!}
                      value={filterable.name!}
                      onClick={() => {
                        setSelectedFilterable(filterable);
                        if (selectedFilterable.type !== filterable.type) {
                          setSelectedComparator(Comparator.Equal);
                        }
                      }}
                    >
                      {filterable.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ width: '25%' }}>
                <Select
                  id="comparison-select"
                  value={selectedComparator}
                  onChange={(e: any) => setSelectedComparator(e.target.value)}
                >
                  {COMPARISONS.get(selectedFilterable.type!)?.map(
                    (comparator: Comparator) => (
                      <MenuItem key={comparator!} value={comparator!}>
                        {COMPARISON_TO_STRING.get(comparator!)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <TextField
                size="small"
                sx={{ width: '40%' }}
                placeholder="Filtering value"
                value={filteringValue}
                onChange={e => setFilteringValue(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={() => {
                  if (filteringValue !== '') {
                    setAppliedRules([
                      ...appliedRules,
                      {
                        type: selectedFilterable.type,
                        name: selectedFilterable.name,
                        comparator: selectedComparator,
                        value: filteringValue.toString(),
                      },
                    ]);
                  }
                }}
              >
                APPLY
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}

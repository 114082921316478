import { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Experiment } from '../../__generated__/gql/graphql';
import string2rbg from './String2RBG';

const CELL_STYLE = {
  width: '50px',
  background: '#001529',
  align: 'right',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
};

interface ComparisonTableProps {
  experiments: Experiment[];
  metadata: 'parameters' | 'metrics';
  showAll: boolean;
}

export default function ComparisonTable({
  experiments,
  metadata,
  showAll,
}: ComparisonTableProps) {
  const [experimentIds, experimentColors, allRows, commonRows] = useMemo(() => {
    const experimentIDs = [] as string[];
    const colors = {} as { [experimentId: string]: string };
    const allRowData = {} as { [metadataId: string]: Map<string, any> };
    experiments.forEach((experiment: Experiment) => {
      experimentIDs.push(experiment.id);
      colors[experiment.id] = string2rbg(experiment.id);
      const instanceRun = experiment?.instanceRuns?.[0];
      if (instanceRun?.[metadata]) {
        Object.entries<any>(instanceRun?.[metadata]).forEach(
          ([metadataId, metadataValue]) => {
            let row = allRowData[metadataId];
            if (!row) {
              /* eslint-disable */
              allRowData[metadataId] = row = new Map<string, any>();
              /* eslint-enable */
            }
            row.set(experiment.id, metadataValue);
          }
        );
      }
    });

    const commonRowData = Object.entries(allRowData).reduce(
      (result, [metadataId, row]) => {
        if (row.size === experimentIDs.length) {
          /* eslint-disable */
          result[metadataId] = row;
          /* eslint-enable */
        }
        return result;
      },
      {} as { [metadataId: string]: Map<string, any> }
    );

    return [experimentIDs, colors, allRowData, commonRowData];
  }, [experiments]);

  return (
    <TableContainer
      sx={{
        height: '300px',
        scrollbarWidth: 'thin',
        scrollbarHeight: 'thin',
        '&::-webkit-scrollbar': {
          width: '5px',
          height: '5px',
        },
        '&::-webkit-scrollbar-corner': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                ...CELL_STYLE,
                position: 'sticky',
                left: 0,
                top: 0,
                zIndex: 2,
              }}
            >
              {metadata.toUpperCase()}
            </TableCell>
            {experimentIds.map((experiment: string) => (
              <TableCell
                sx={{
                  ...CELL_STYLE,
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  color: experimentColors[experiment],
                }}
                key={experiment}
              >
                {experiment}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(showAll ? allRows : commonRows).map(
            ([metadataId, row]) => (
              <TableRow key={metadataId}>
                <TableCell
                  sx={{
                    ...CELL_STYLE,
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                  }}
                >
                  {metadataId}
                </TableCell>
                {experimentIds.map((experimentId: string) => (
                  <TableCell
                    sx={{
                      ...CELL_STYLE,
                      zIndex: 0,
                      color: experimentColors[experimentId],
                    }}
                    key={experimentId}
                  >
                    {typeof row.get(experimentId) === 'number'
                      ? row.get(experimentId).toFixed(3)
                      : row.get(experimentId)}
                  </TableCell>
                ))}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Box, Typography } from '@mui/material';
import ExperimentTable from './experiment_table/ExperimentTable';
import NoSelectedProject from '../components/NoSelectedProject';
import { useProject } from '../contexts/project';

export default function TableView() {
  const { project } = useProject();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography variant="h4" color="white">
            Experiments
          </Typography>
          {project?.id && (
            <Typography variant="body2" color="text.secondary">
              Project Id: {project?.id}
            </Typography>
          )}
        </Box>
      </Box>
      {project?.id ? <ExperimentTable /> : <NoSelectedProject />}
    </>
  );
}

import { Box, Typography } from '@mui/material';

export const LabelAndValue = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <Box>
    <Typography
      color="text.secondary"
      variant="subtitle2"
      lineHeight={1}
      fontWeight={600}
      noWrap
    >
      {label}
    </Typography>
    <Typography
      color="text.primary"
      variant="subtitle1"
      lineHeight={1.5}
      fontWeight={700}
      noWrap
    >
      {value}
    </Typography>
  </Box>
);

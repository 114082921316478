import { atom } from 'recoil';
import { Experiment, Rule } from '../__generated__/gql/graphql';

// eslint-disable-next-line import/prefer-default-export
export const defaultMetricState = atom({
  key: 'defaultMetric',
  default: '',
});
export const defaultAppliedRules = atom({
  key: 'appliedRules',
  default: [] as Rule[],
});

export const detailExperimentState = atom<Experiment | undefined>({
  key: 'detailExperiment',
  default: undefined,
});
export const autoRefreshEnabled = atom({
  key: 'autoRefreshEnabled',
  default: false,
});

export const autoRefreshInterval = atom({
  key: 'autoRefreshInterval',
  default: 2500,
});

export const projectId = atom({
  key: 'projectId',
  default: '',
});

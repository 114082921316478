import React, { useState } from 'react';
import { Collapse, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from '@apollo/client';
import { gql } from '../../__generated__/gql';

type ExperimentMetricTableProps = {
  title: string;
  experimentId: string;
  isMetric: boolean;
};

const EXPERIMENT_DELTAS = gql(`
  query getExperimentDeltas($experimentId: String!) {
    experiment(id: $experimentId) {
     metricDelta
     paramDelta
    }
  }
`);

export default function ExperimentDeltaTable({
  title,
  experimentId,
  isMetric,
}: ExperimentMetricTableProps) {
  const [tableOpen, setTableOpen] = useState(false);
  const {
    loading: detailQueryLoading,
    error: detailQueryError,
    data: experimentDetail,
  } = useQuery(EXPERIMENT_DELTAS, {
    variables: { experimentId },
  });

  if (detailQueryLoading) {
    return <div />;
  }
  if (detailQueryError) {
    return <div />;
  }

  const deltaData = isMetric
    ? experimentDetail?.experiment?.metricDelta
    : experimentDetail?.experiment?.paramDelta;
  const entriesDeltaData: [string, any][] = deltaData
    ? Object.entries(deltaData)
    : [];

  const filteredDeltaData = entriesDeltaData.filter(([, value]) => {
    if (typeof value === 'number' && value === 0) {
      return false;
    }
    if (typeof value === 'string' && value === 'unchanged') {
      return false;
    }
    return true;
  });

  const handleClick = () => {
    setTableOpen(!tableOpen);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: tableOpen ? -1 : 0 }}>
        {title}
        <IconButton
          onClick={handleClick}
          aria-expanded={tableOpen}
          aria-label={tableOpen ? 'Collapse' : 'Expand'}
        >
          {tableOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Typography>
      <Collapse in={tableOpen}>
        {filteredDeltaData.length > 0 ? (
          <TableContainer>
            <Table
              aria-label="delta table"
              sx={{
                tableLayout: 'fixed',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">Key</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1">Value</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDeltaData.map(([key, value]) => {
                  const displayedValue =
                    typeof value === 'number' ? value.toFixed(3) : value;
                  let color = 'text.primary';
                  if (displayedValue > 0) {
                    color = 'positive.main';
                  } else if (displayedValue < 0) {
                    color = 'negative.main';
                  }

                  return (
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Tooltip title={key}>
                          <Typography
                            variant="body2"
                            sx={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {key}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={displayedValue}>
                          <Typography
                            variant="body2"
                            sx={{
                              color,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {displayedValue}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="subtitle1">No changed values.</Typography>
        )}
      </Collapse>
    </Box>
  );
}

import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  BoxProps,
  styled,
  TextField,
  InputAdornment,
  Autocomplete,
  Chip,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { colorThemes } from '../theme';
import {
  Link as ReactRouterLink,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ProgressBar } from '../components/ProgressBar';
import SearchIcon from '@mui/icons-material/Search';

import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetGovernanceFrameworkQuery,
  MaterializedCategory,
  MaterializedRequirement,
  RequirementCompletionStatusEnum,
} from '../__generated__/gql/graphql';
import ScrollToHashElement from '../components/ScrollToHashElement';
import { RequirementCard } from './RequirementCard';
import { RequirementDrawer } from './RequirementDrawer';
import { ToastContainer } from 'react-toastify';
import { ArrowRight } from '@mui/icons-material';

const CustomList = styled('ol')({
  counterReset: 'section',
  listStyleType: 'none',
});

const GET_GOVERNANCE_FRAMEWORK = gql`
  query GetGovernanceFramework($frameworkId: String!) {
    materializedGovernanceFramework(frameworkId: $frameworkId) {
      id
      name
      categories {
        categoryName
        requirements {
          id
          requirementId
          title
          description
          guidance
          approvalResponsibilities
          ownerResponsibilities
          userStatus {
            id
            completionStatus
            lastModified
            created
            evidence {
              id
            }
          }
          approvers {
            id
            name
            email
            initials
          }
          owners {
            id
            name
            email
            initials
          }
          evidenceSuggestions {
            description
          }
          specifications {
            id
            description
          }
        }
      }
    }
  }
`;

// TODO: Refactor this horrible code :P

function countCategoryRequirements(category: MaterializedCategory) {
  let completedCount = 0;
  let totalCount = 0;

  (category?.requirements ?? []).forEach(requirement => {
    totalCount++;
    if (
      requirement?.userStatus?.completionStatus ===
      RequirementCompletionStatusEnum.Approved
    ) {
      completedCount++;
    }
  });

  return {
    completedCount,
    totalCount,
  };
}

function countRequirementProgress(data: GetGovernanceFrameworkQuery) {
  let totalCompletedCount = 0;
  let totalRequirementCount = 0;

  (data?.materializedGovernanceFramework?.categories ?? []).forEach(
    category => {
      const categoryCounts = countCategoryRequirements(
        category as MaterializedCategory
      );
      totalCompletedCount += categoryCounts.completedCount;
      totalRequirementCount += categoryCounts.totalCount;
    }
  );

  return {
    completedCount: totalCompletedCount,
    totalCount: totalRequirementCount,
  };
}

type FilterOption = {
  type: 'text' | 'owner' | 'approver' | 'status';
  value: string;
  label: string;
};

const GovernancePage: React.FC = () => {
  const { frameworkId, auditId } = useParams<{
    frameworkId: string;
    auditId?: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useSuspenseQuery<GetGovernanceFrameworkQuery>(
    GET_GOVERNANCE_FRAMEWORK,
    {
      variables: { frameworkId },
    }
  );

  const queryParams = new URLSearchParams(location.search);
  const openRequirementId = queryParams.get('requirementId');

  const setOpenRequirementId = (requirementId: string | null) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (requirementId) {
      newQueryParams.set('requirementId', requirementId);
    } else {
      newQueryParams.delete('requirementId');
    }
    navigate(
      {
        pathname: location.pathname,
        search: newQueryParams.toString(),
      },
      { replace: true }
    );
  };

  const categories = React.useMemo(
    () =>
      (data?.materializedGovernanceFramework?.categories ??
        []) as MaterializedCategory[],
    [data?.materializedGovernanceFramework?.categories]
  );

  const [filterOptions, setFilterOptions] = React.useState<FilterOption[]>([]);

  const { owners, approvers } = React.useMemo(() => {
    const ownerSet = new Set<string>();
    const approverSet = new Set<string>();
    categories.forEach(category => {
      category.requirements?.forEach(req => {
        req?.approvers?.forEach(
          approver => approver?.name && approverSet.add(approver.name)
        );
        req?.owners?.forEach(owner => owner?.name && ownerSet.add(owner.name));
      });
    });
    return {
      owners: Array.from(ownerSet),
      approvers: Array.from(approverSet),
    };
  }, [categories]);

  const allStatuses = React.useMemo(() => {
    const statusSet = new Set<RequirementCompletionStatusEnum>();
    categories.forEach(category => {
      category.requirements?.forEach(req => {
        if (req?.userStatus?.completionStatus) {
          statusSet.add(req.userStatus.completionStatus);
        }
      });
    });
    return Array.from(statusSet);
  }, [categories]);

  const autocompleteOptions: FilterOption[] = React.useMemo(
    () => [
      { type: 'owner', value: 'unassigned', label: 'Owner: Unassigned' },
      ...owners.map(user => ({
        type: 'owner' as const,
        value: user,
        label: `Owner: ${user}`,
      })),
      { type: 'approver', value: 'unassigned', label: 'Approver: Unassigned' },
      ...approvers.map(user => ({
        type: 'approver' as const,
        value: user,
        label: `Approver: ${user}`,
      })),
      ...allStatuses.map(status => ({
        type: 'status' as const,
        value: status,
        label: `Status: ${status}`,
      })),
    ],
    [owners, approvers, allStatuses]
  );

  const filteredCategories = React.useMemo(() => {
    if (filterOptions.length === 0) return categories;
    return categories
      .map(category => ({
        ...category,
        requirements: category.requirements?.filter(req =>
          filterOptions.every(option => {
            switch (option.type) {
              case 'text':
                return (
                  req?.title
                    ?.toLowerCase()
                    .includes(option.value.toLowerCase()) ||
                  req?.description
                    ?.toLowerCase()
                    .includes(option.value.toLowerCase())
                );
              case 'owner':
                if (option.value === 'unassigned') {
                  return !req?.owners || req.owners.length === 0;
                }
                return req?.owners?.some(owner => owner?.name === option.value);
              case 'approver':
                if (option.value === 'unassigned') {
                  return !req?.approvers || req.approvers.length === 0;
                }
                return req?.approvers?.some(
                  approver => approver?.name === option.value
                );
              case 'status':
                return req?.userStatus?.completionStatus === option.value;
              default:
                return true;
            }
          })
        ),
      }))
      .filter(
        category => category.requirements && category.requirements.length > 0
      );
  }, [categories, filterOptions]);

  const handleFilterChange = (
    _event: React.SyntheticEvent,
    newValue: (string | FilterOption)[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reason: string // needed for interface
  ) => {
    setFilterOptions(
      newValue.map(item =>
        typeof item === 'string'
          ? { type: 'text', value: item, label: item }
          : item
      )
    );
  };

  const generateTypesafeUrl = (name: string) =>
    name
      ?.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]/g, '');

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Typography variant="h4" marginBottom={3}>
        {auditId
          ? `Audit for ${data?.materializedGovernanceFramework?.name}`
          : data?.materializedGovernanceFramework?.name}
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        flex="1"
        color={colorThemes.GREY_100}
        overflow={'hidden'}
      >
        <ScrollToHashElement />
        <Box
          gridColumn="span 2"
          sx={{
            backgroundColor: colorThemes.DARK_BLUE_600,
            borderRadius: '12px',
            padding: '24px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            overflow: 'auto',
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            marginBottom="24px"
            color="white"
          >
            Chapters
          </Typography>

          <List disablePadding>
            {categories.map(category => (
              <ListItem
                key={category?.categoryName}
                disablePadding
                sx={{
                  marginBottom: '12px',
                }}
              >
                <ListItemButton
                  component={ReactRouterLink}
                  to={`#${generateTypesafeUrl(category?.categoryName)}`}
                  sx={{
                    borderRadius: '8px',
                    padding: '4px',
                    backgroundColor: colorThemes.DARK_BLUE_600,
                    '&:hover': {
                      backgroundColor: colorThemes.DARK_BLUE_400,
                    },
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <ArrowRight color="inherit" />
                  </ListItemIcon>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" fontWeight="600" color="white">
                      {category?.categoryName}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          gridColumn="span 10"
          display="flex"
          flexDirection="column"
          overflow="auto"
          gap={'16px'}
        >
          <Box display="flex" gap={1}>
            <SummaryCard
              flex={1}
              description="Requirement Progress"
              progressLabel="Requirements fulfilled"
              totalNumber={countRequirementProgress(data).totalCount}
              fulfilledNumber={countRequirementProgress(data).completedCount}
            />
          </Box>
          <Autocomplete<FilterOption, true, false, true>
            multiple
            options={autocompleteOptions}
            freeSolo
            renderTags={(value: (string | FilterOption)[], getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={typeof option === 'string' ? option : option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option) => (
              <li {...props}>
                <ListItemText primary={option.label} />
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  params.InputProps.startAdornment
                    ? ''
                    : 'Search requirements, users, or status...'
                }
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={handleFilterChange}
            isOptionEqualToValue={(option, value) =>
              option.type === value.type && option.value === value.value
            }
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.label
            }
            filterOptions={(options, params) => {
              const filtered = options.filter(option =>
                option.label
                  .toLowerCase()
                  .includes(params.inputValue.toLowerCase())
              );
              if (
                params.inputValue !== '' &&
                !filtered.some(option => option.value === params.inputValue)
              ) {
                filtered.push({
                  type: 'text',
                  value: params.inputValue,
                  label: `Search title or description for: ${params.inputValue}`,
                });
              }
              return filtered;
            }}
            sx={{
              backgroundColor: colorThemes.DARK_BLUE_600,
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
          <CustomList
            sx={{
              padding: 4,
              bgcolor: colorThemes.DARK_BLUE_600,
              borderRadius: '8px',
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              overflow: 'auto',
              marginBlock: '0px',
            }}
          >
            {filteredCategories.map(category => (
              <Category
                category={category}
                key={category.categoryName}
                openRequirementId={openRequirementId}
                setOpenRequirementId={setOpenRequirementId}
                auditId={auditId}
              />
            ))}
          </CustomList>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

const Category: React.FC<{
  category: MaterializedCategory;
  openRequirementId: string | null;
  setOpenRequirementId: (_requirementId: string | null) => void;
  auditId?: string;
}> = ({ category, openRequirementId, setOpenRequirementId, auditId }) => {
  const generateIdFromName = (name: string) =>
    name
      ?.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]/g, '');

  return (
    <Box component="li" sx={{ marginBottom: 4 }}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 200px"
        alignItems="center"
        paddingRight="8px"
        width="100%"
        marginBottom={2}
      >
        <Typography
          id={generateIdFromName(category?.categoryName)}
          component="h2"
          fontWeight={800}
          fontSize="26px"
          display="inline"
          pt={2}
        >
          {category?.categoryName}
        </Typography>
        <ProgressBar
          value={
            (countCategoryRequirements(category).completedCount /
              countCategoryRequirements(category).totalCount) *
            100
          }
          variant="determinate"
        />
      </Box>
      <CustomList sx={{ display: 'grid', gap: '8px', paddingLeft: 0 }}>
        {category?.requirements?.map(requirement => (
          <Requirement
            key={requirement?.id}
            requirement={requirement!}
            totalNumberOfSpecs={requirement?.specifications?.length ?? 0}
            openRequirementId={openRequirementId}
            setOpenRequirementId={setOpenRequirementId}
            auditId={auditId}
          />
        ))}
      </CustomList>
    </Box>
  );
};

const SummaryCard: React.FC<
  {
    progressLabel: string;
    description: string;
    totalNumber: number;
    fulfilledNumber: number;
  } & BoxProps
> = ({
  progressLabel,
  description,
  totalNumber,
  fulfilledNumber,
  ...props
}) => {
  return (
    <Box
      padding={4}
      bgcolor={colorThemes.DARK_BLUE_600}
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap="4px"
      {...props}
    >
      <Typography variant="h4" component="h2">
        {fulfilledNumber}/{totalNumber}
      </Typography>
      <Typography component="p">{progressLabel}</Typography>
      <ProgressBar
        variant="determinate"
        value={(fulfilledNumber / totalNumber) * 100}
      />
      <Typography component="p">{description}</Typography>
    </Box>
  );
};

const Requirement: React.FC<{
  requirement: MaterializedRequirement;
  totalNumberOfSpecs: number;
  openRequirementId: string | null;
  setOpenRequirementId: (_requirementId: string | null) => void;
  auditId?: string;
}> = ({
  requirement,
  totalNumberOfSpecs,
  openRequirementId,
  setOpenRequirementId,
  auditId,
}) => {
  const isDrawerOpen = requirement.requirementId === openRequirementId;

  const handleRequirementClick = () => {
    if (isDrawerOpen) {
      setOpenRequirementId(null);
    } else {
      setOpenRequirementId(requirement.requirementId || null);
    }
  };

  const totalNumberOfEvidences = requirement.userStatus?.evidence?.length ?? 0;

  return (
    <>
      <RequirementCard
        requirement={requirement}
        specificationNumber={totalNumberOfSpecs}
        evidenceNumber={totalNumberOfEvidences}
        onClick={handleRequirementClick}
      />
      {isDrawerOpen && (
        <RequirementDrawer
          requirement={requirement}
          open={isDrawerOpen}
          onClose={() => setOpenRequirementId(null)}
          auditId={auditId}
        />
      )}
    </>
  );
};

export default GovernancePage;

import React, { useState, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

interface StringListInputProps {
  items: string[];
  setItems: (items: string[]) => void;
}

export default function ChipInputList({
  items,
  setItems,
}: StringListInputProps) {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newItem = inputValue.trim();

      if (newItem) {
        if (!items.includes(newItem)) {
          setItems([...items, newItem]);
          setInputValue('');
          setError(false);
          setHelperText('');
        } else {
          setError(true);
          setHelperText('Duplicate item. Please enter a unique value.');
        }
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    // Optionally reset error state on user input change
    if (error) {
      setError(false);
      setHelperText('');
    }
  };

  const handleDelete = (itemToDelete: string) => {
    setItems(items.filter(item => item !== itemToDelete));
  };

  return (
    <Stack direction="column" spacing={2}>
      <TextField
        sx={{ width: 'fit-content' }}
        label="Add Item"
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fullWidth
        error={error}
        helperText={helperText}
      />
      <Stack direction="row" spacing={1}>
        {items.map((item, index) => (
          <Chip
            variant="outlined"
            key={index}
            label={item}
            onDelete={() => handleDelete(item)}
            color="primary"
          />
        ))}
      </Stack>
    </Stack>
  );
}

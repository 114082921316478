import { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { copyToClipboard } from '../components/clipoadUtils';
import NewExperimentModal from './NewExprimentModal';
import { useParams } from 'react-router-dom';

type Props = {
  nodeId: string;
};

export default function RootNodeCard({ nodeId }: Props) {
  const projectId = useParams().projectId;
  const [isHovered, setIsHovered] = useState(false);
  const [showNewExperimentModal, setShowNewExperimentModal] = useState(false);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '150px',
          minHeight: '100px',
          overflow: 'visible',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Card
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
          onClick={() =>
            copyToClipboard(nodeId, 'Experiment ID copied to clipboard!')
          }
        >
          <CardContent>
            <Typography variant="h5" component="div">
              Root Node
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {nodeId}
            </Typography>
          </CardContent>
        </Card>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '-20px',
            transform: 'translateY(-50%)',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 0.2s ease-in-out',
          }}
        >
          <IconButton
            onClick={e => {
              e.stopPropagation();
              setShowNewExperimentModal(true);
            }}
            aria-label="add"
            sx={{
              backgroundColor: 'grey.800',
              padding: '8px',
              width: '100%',
              height: '100%',
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <NewExperimentModal
        open={showNewExperimentModal}
        onClose={() => setShowNewExperimentModal(false)}
        projectId={projectId ?? ''}
        parentExperimentId={nodeId}
      />
    </>
  );
}

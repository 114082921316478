import { gql, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  RiskAssessmentCatalogueTypeEnum,
  BsiRiskSection,
  RiskAssessmentFrameworkMaterializedQuery,
  StandardRiskSection,
} from '../../__generated__/gql/graphql';
import RiskAssessment from './RiskAssessment';

const GET_ASSESSMENT_FRAMEWORK = gql`
  query RiskAssessmentFrameworkMaterialized(
    $riskAssessmentFrameworkMaterializedId: String!
  ) {
    riskAssessmentFrameworkMaterialized(
      riskAssessmentFrameworkMaterializedId: $riskAssessmentFrameworkMaterializedId
    ) {
      id
      name
      riskAssessmentCatalogueType
      riskAssessmentReference {
        id
        riskSections {
          ... on StandardRiskSection {
            frequency
            impactSystem
            impactUser
            impactBusiness
            severity
            residualRisk
            acceptRiskWithoutTreatment
          }
          ... on BSIRiskSection {
            frequency
            severity
            residualRisk
            acceptRiskWithoutTreatment
          }
        }
      }
      categories {
        categoryName
        sections {
          id
          label
          description
          guidingQuestions
        }
      }
    }
  }
`;

export default function RiskAssessmentLoader() {
  const { assessmentFrameworkId } = useParams<{
    assessmentFrameworkId: string;
  }>();

  const { data: frameworkCatalogue } =
    useSuspenseQuery<RiskAssessmentFrameworkMaterializedQuery>(
      GET_ASSESSMENT_FRAMEWORK,
      {
        variables: {
          riskAssessmentFrameworkMaterializedId: assessmentFrameworkId,
        },
      }
    );

  const type: RiskAssessmentCatalogueTypeEnum =
    frameworkCatalogue?.riskAssessmentFrameworkMaterialized
      ?.riskAssessmentCatalogueType ?? RiskAssessmentCatalogueTypeEnum.Standard;

  let formValues: StandardRiskSection[] | BsiRiskSection[] | [] = [];
  switch (type) {
    case RiskAssessmentCatalogueTypeEnum.Standard:
      formValues =
        frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.riskAssessmentReference?.riskSections?.filter(
          (section): section is StandardRiskSection =>
            section !== null && section.__typename === 'StandardRiskSection'
        ) ?? [];
      break;
    case RiskAssessmentCatalogueTypeEnum.Bsi:
      formValues =
        frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.riskAssessmentReference?.riskSections?.filter(
          (section): section is BsiRiskSection =>
            section !== null && section.__typename === 'BSIRiskSection'
        ) ?? [];
      break;
    default:
      formValues = [];
      break;
  }

  return (
    <RiskAssessment
      frameworkCatalogue={
        frameworkCatalogue.riskAssessmentFrameworkMaterialized
      }
      formValues={formValues}
      type={type}
    />
  );
}

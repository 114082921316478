import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Card,
  Divider,
  Grid,
  Skeleton,
  Typography,
  IconButton,
  Modal,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { colorThemes } from '../theme';
import { useProject } from '../contexts/project';
import { Link, useNavigate } from 'react-router-dom';
import { DocumentationSummaryFragment } from '../__generated__/gql/graphql';
import { LabelAndValue } from '../components/LabelAndValue';
import { useResetDocumentState } from './documentation_view/DocumentationPageStates';
import DeleteIcon from '@mui/icons-material/Delete';
import { gql, useMutation } from '@apollo/client';

export enum GridCategory {
  ORG = 'ORG',
  PROJECT = 'PROJECT',
  EXPERIMENT = 'EXPERIMENT',
}

const GridTitle = {
  ORG: 'Organizational Documents',
  PROJECT: 'Project Specific Documents',
  EXPERIMENT: 'Experiment Specific Documents',
};

interface CollapsableGridViewProps {
  documents: DocumentationSummaryFragment[];
  gridCategory: GridCategory;
  loading?: boolean;
  onDelete?: (documentId: string) => void;
}

const DELETE_DOCUMENTATION = gql(`
  mutation deleteDocumentation($documentationId: String!) {
    deleteDocumentation(documentationId: $documentationId) {
      documentationId
    }
  }
`);

export default function CollapsableGridView({
  documents,
  gridCategory,
  loading = true,
  onDelete,
}: CollapsableGridViewProps) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Divider sx={{ marginTop: '2rem' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end', gap: '0.5rem' }}>
          <Typography
            variant="h6"
            sx={{ marginTop: '0.5rem', ':hover': { cursor: 'pointer' } }}
            onClick={() => setExpanded(!expanded)}
          >
            {documents.length} {GridTitle[gridCategory]}
          </Typography>
          {gridCategory !== GridCategory.ORG ? <ProjectDetails /> : <> </>}
        </Box>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {loading ? (
        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          {[...Array(4)].map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={200}
                sx={{ borderRadius: '0.5rem' }}
              />
            </Grid>
          ))}
        </Grid>
      ) : expanded ? (
        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          {documents.map(document => (
            <Grid item xs={12} sm={6} md={3} key={document.id}>
              <DocumentCard document={document} onDelete={onDelete} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            marginTop: '2rem',
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {documents.map(document => (
            <Box
              key={document.id}
              sx={{
                minWidth: 'calc(25% - 12px)',
                paddingBottom: '0.5rem',
              }}
            >
              <DocumentCard document={document} onDelete={onDelete} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

function DocumentCard({
  document,
  onDelete,
}: {
  document: DocumentationSummaryFragment;
  onDelete?: (documentId: string) => void;
}) {
  const navigate = useNavigate();
  const resetAll = useResetDocumentState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteDocumentation] = useMutation(DELETE_DOCUMENTATION, {
    update(cache, { data }) {
      const deletedId = data?.deleteDocumentation.documentationId;
      cache.modify({
        fields: {
          allDocumentations(existingDocs = [], { readField }) {
            return existingDocs.filter(
              (docRef: any) => readField('id', docRef) !== deletedId
            );
          },
        },
      });
    },
    onCompleted: () => {
      onDelete?.(document.id);
      setDeleteModalOpen(false);
    },
  });

  return (
    <>
      <Card
        sx={{
          padding: '24px',
          width: 'full',
          height: 200,
          transition: 'all .2s',
          border: '2px solid #334E68',
          position: 'relative',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: colorThemes.DARK_BLUE_400,
          },
        }}
        onClick={() => {
          resetAll();
          navigate(`/documentation/${document.id}`);
        }}
      >
        <LabelAndValue label="Document Title" value={document.name} />
        <Grid container spacing={3} sx={{ marginTop: '0.1rem' }}></Grid>
        <IconButton
          className="delete-icon"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={e => {
            e.stopPropagation();
            setDeleteModalOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Card>

      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: 400,
          }}
        >
          <Typography
            id="delete-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Confirm Deletion
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Are you sure you want to delete this documentation? This will also
            delete all associated documentation results.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                deleteDocumentation({
                  variables: { documentationId: document.id },
                });
              }}
              sx={{
                backgroundColor: 'error.main',
                color: 'white',
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function ProjectDetails() {
  const { project } = useProject();
  if (!project) {
    return (
      <Box
        component={Link}
        to={'/projects'}
        sx={{ ':hover': { cursor: 'pointer' } }}
      >
        <Typography variant="subtitle1" color={colorThemes.YELLOW_200}>
          Please Select a Project
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      component={Link}
      to={'/projects'}
      sx={{ ':hover': { cursor: 'pointer' } }}
    >
      <Typography variant="subtitle1" color={colorThemes.YELLOW_200}>
        Project Id: {project?.id}
      </Typography>
    </Box>
  );
}

import { gql, useQuery } from '@apollo/client';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { defaultMetricState } from './TreeSettingsAtoms';

const GET_METRICS = gql(`
  query TreeVisualizer_metrics($project_id: String!) {
    project(id: $project_id) {
      experiments {
        instanceRuns {
          metrics
        }
      }
    }
  }
`);
export default function MetricSelector() {
  const { projectId } = useParams();
  const [defaultMetric, setDefaultMetric] = useRecoilState(defaultMetricState);
  const { loading, data } = useQuery(GET_METRICS, {
    variables: projectId ? { project_id: projectId } : undefined,
  });

  useEffect(() => {
    setDefaultMetric('');
    return () => {};
  }, [projectId, setDefaultMetric]);

  if (loading) {
    return <CircularProgress />;
  }

  const metricsSet: Set<string> = new Set();

  (data?.project?.experiments ?? []).forEach(
    (experiment: { instanceRuns: any[] }) => {
      (experiment?.instanceRuns ?? []).forEach(instanceRun => {
        const metrics = instanceRun?.metrics;
        Object.keys(metrics).forEach(metricName => {
          metricsSet.add(metricName);
        });
      });
    }
  );

  const uniqueMetrics: string[] = Array.from(metricsSet);
  if (defaultMetric === '' && uniqueMetrics.length > 0) {
    setDefaultMetric(uniqueMetrics[0]);
  }

  return uniqueMetrics.length > 0 ? (
    <Select
      labelId="default-metrics-select-label"
      id="default-metric-select"
      defaultValue=""
      value={defaultMetric}
    >
      {uniqueMetrics.map((metric: string) => (
        <MenuItem
          key={metric}
          onClick={e =>
            setDefaultMetric(e.currentTarget.dataset.value as string)
          }
          value={metric}
        >
          {metric}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <Select
      labelId="default-metrics-select-label"
      id="default-metric-select"
      value="na"
      IconComponent={() => null}
      disabled
      sx={{ color: 'white' }}
    >
      <MenuItem value="na">No Metrics Available</MenuItem>
    </Select>
  );
}

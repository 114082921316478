import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface TableHeadProps {
  setSearchTerm: (searchTerm: string) => void;
  searchTerm: string;
  disableSearch?: boolean;
}

export default function TableHead({
  setSearchTerm,
  searchTerm,
  disableSearch = false,
}: TableHeadProps) {
  const fontWeightConst = 600;
  return (
    <Box
      sx={{
        width: '100%',
        paddingY: '0.5rem',
        borderRadius: '0.5rem',
      }}
    >
      <Grid container>
        <Grid item xs={5}>
          <Box display={'flex'} gap={'10px'}>
            <Typography
              variant="h6"
              sx={{ fontWeight: fontWeightConst }}
              flexDirection={'row'}
            >
              Person
            </Typography>
            {!disableSearch && (
              <TextField
                size="small"
                placeholder="Search Name"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'end' }}>
          <Typography variant="h6" sx={{ fontWeight: fontWeightConst }}>
            Responsibilities
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'end' }}>
          <Typography variant="h6" sx={{ fontWeight: fontWeightConst }}>
            Proof of Qualification
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

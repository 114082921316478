import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { GetCurrentUserQuery } from '../__generated__/gql/graphql';
import { colorThemes } from '../theme';
import { GET_USER_DETAILS } from './ProfilePage';
import { ApiKeysTable } from './ApiKeysTable';

const GENERATE_API_KEY_MUTATION = gql`
  mutation GenerateApiKey($name: String!) {
    generateApiKey(name: $name) {
      apiKey
    }
  }
`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
};

export default function ManageApiKeys() {
  const { data: userData, refetch } =
    useQuery<GetCurrentUserQuery>(GET_USER_DETAILS);
  const [generateApiKey, { loading: apiKeyLoading }] = useMutation(
    GENERATE_API_KEY_MUTATION,
    {
      onError: error => {
        toast.error(`Error generating API key: ${error.message}`);
      },
      onCompleted: data => {
        setNewApiKey(data.generateApiKey.apiKey);
        setOpenModal(true);
        toast.success('API key generated successfully.');
        refetch(); // Refetch user data to get the updated list of API keys
      },
    }
  );

  const [openModal, setOpenModal] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [apiKeyName, setApiKeyName] = useState('');

  const handleGenerateApiKey = () => {
    if (apiKeyName.trim() === '') {
      toast.error('API Key Name cannot be empty.');
      return;
    }
    generateApiKey({ variables: { name: apiKeyName } });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(newApiKey)
      .then(() => {
        toast.success('API key copied to clipboard!');
      })
      .catch(err => {
        console.error('Error copying text: ', err);
        toast.error('Failed to copy API key.');
      });
  };

  const apiKeyDetails = userData?.currentUser?.apiKeys;

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', height: '100%' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            API Keys
          </Typography>
          {apiKeyDetails && apiKeyDetails.length > 0 ? (
            <ApiKeysTable
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore-next-line - apiKeys won't ever be null here
              apiKeys={apiKeyDetails}
              refetchApiKeys={refetch}
            />
          ) : (
            <Typography>No API Keys generated yet.</Typography>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <TextField
            label="API Key Name"
            variant="outlined"
            size="small"
            value={apiKeyName}
            onChange={e => setApiKeyName(e.target.value)}
            sx={{ mr: 2, width: '250px' }}
          />
          <LoadingButton
            loading={apiKeyLoading}
            variant="outlined"
            onClick={handleGenerateApiKey}
          >
            Generate new API Key
          </LoadingButton>
        </CardActions>
      </Card>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="new-api-key-modal"
        aria-describedby="new-api-key-modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            id="new-api-key-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            New API Key Generated
          </Typography>
          <Typography id="new-api-key-modal-description" textAlign="center">
            Please save this key somewhere safe; it will not be accessible
            again.
          </Typography>
          <Typography
            sx={{
              wordWrap: 'break-word',
              backgroundColor: colorThemes.DARK_BLUE_800,
              p: 1,
              borderRadius: '8px',
              textAlign: 'center',
              fontFamily: 'monospace',
            }}
          >
            {newApiKey}
          </Typography>
          <Box textAlign="center">
            <Button variant="contained" onClick={handleCopyToClipboard}>
              Copy API Key
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface MenuButtonItem {
  label: string;
  onClick: () => void;
}

interface MenuButtonProps extends ButtonProps {
  label: string;
  items: MenuButtonItem[];
}

export default function MenuButton(props: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickItem = (item: MenuButtonItem) => {
    item.onClick();
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...props}
      >
        {props.label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.items.map((item, index) => (
          <MenuItem key={index} onClick={() => onClickItem(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

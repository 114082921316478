import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ApiKey } from '../__generated__/gql/graphql';
import { Delete } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { gql } from '../__generated__/gql';
import { useMutation } from '@apollo/client';

const DELETE_API_KEY_MUTATION = gql(`
  mutation DeleteApiKey($name: String!) {
    deleteApiKey(name: $name) {
      name
    }
  }
`);

const convertTimestampToHumanReadable = (
  timestamp: ApiKey['creationTimestamp']
) => {
  const date = new Date(timestamp);
  const humanReadableTime = date.toLocaleString();
  return humanReadableTime;
};

export const ApiKeysTable = ({
  apiKeys,
  refetchApiKeys,
}: {
  apiKeys: ApiKey[];
  refetchApiKeys: () => void;
}) => {
  const [deleteApiKey] = useMutation(DELETE_API_KEY_MUTATION, {
    onCompleted: () => {
      toast.success('API key deleted successfully.');
      refetchApiKeys();
    },
    onError: error => {
      toast.error(`Error deleting API key: ${error.message}`);
    },
  });

  const handleDeleteKey = (name: string) => {
    deleteApiKey({
      variables: {
        name,
      },
    });
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created on</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys.map(apiKey => (
            <TableRow key={apiKey.name}>
              <TableCell>{apiKey.name}</TableCell>
              <TableCell>
                {convertTimestampToHumanReadable(apiKey.creationTimestamp)}
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteKey(apiKey.name)}
                    sx={{
                      marginLeft: 1,
                      ':hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08);',
                      },
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

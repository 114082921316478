import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { User } from '../__generated__/gql/graphql';
import {
  ResponsibilityDisplayMap,
  RoleDisplayMap,
} from '../user_management/UserRow';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const UPDATE_USER_ATTRIBUTES = gql`
  mutation UpdateUserAttributes($name: String) {
    updateUserAttributes(name: $name) {
      user {
        id
        name
        email
      }
    }
  }
`;

export const Profile = ({ user }: { user: User }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(user.name || '');
  const [updateUserAttributes] = useMutation(UPDATE_USER_ATTRIBUTES);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await updateUserAttributes({ variables: { name } });
      setIsEditing(false);
      toast.success('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
      toast.error('Failed to update name');
    }
  };

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="h5" component="div">
            Profile
          </Typography>
          {!isEditing && (
            <Button variant="outlined" onClick={() => setIsEditing(true)}>
              Edit Profile
            </Button>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: '32px' }}>
          <Box sx={{ flex: '0 0 auto' }}>
            <Avatar sx={{ width: 128, height: 128, fontSize: '64px' }}>
              {user.name?.slice(0, 1)}
            </Avatar>
          </Box>
          <Box
            sx={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {isEditing ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                  fullWidth
                />
                <Button onClick={handleSubmit} variant="contained">
                  Save
                </Button>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              </Box>
            ) : (
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                  Name:
                </Typography>
                <Typography>{user.name}</Typography>
              </Box>
            )}
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                Email:
              </Typography>
              <Typography>{user.email}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                Organization:
              </Typography>
              <Typography>{user.organizationName}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
                Roles:
              </Typography>
              <Typography>
                {user.roles && user.roles.length > 0
                  ? user.roles
                      .map(role => (role ? RoleDisplayMap[role] : ''))
                      .filter(Boolean)
                      .join(', ')
                  : 'None'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: '700', mb: 1 }}>
                Responsibilities:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {user.responsibilities?.map(
                  (responsibility, index) =>
                    responsibility && (
                      <Chip
                        label={ResponsibilityDisplayMap[responsibility]}
                        key={index}
                        color="info"
                      />
                    )
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

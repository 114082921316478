import { ReactNode, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Collapse,
  Box,
  Grid,
  Chip,
  Stack,
} from '@mui/material';

import { GetEvaluationsQuery } from '../../../__generated__/gql/graphql';
import EvaluationOutcomeIcon from '../../../components/EvaluationOutcomeIcon';
import FormattedDateTime from '../../../components/FormattedDateTime';

type ExperimentType = NonNullable<GetEvaluationsQuery['experiment']>;
type EvaluationsArrayType = NonNullable<ExperimentType['evaluations']>;
type SingleEvaluationType = NonNullable<
  EvaluationsArrayType extends (infer U)[] ? U : never
>;
type ManualEvaluationsArrayType = NonNullable<
  ExperimentType['manualEvaluations']
>;
type ManualEvaluationType = NonNullable<
  ManualEvaluationsArrayType extends (infer U)[] ? U : never
>;

interface Props {
  evaluation: SingleEvaluationType | ManualEvaluationType;
  actions: ReactNode;
}

export default function EvaluationInformation({ evaluation, actions }: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleCardClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      variant="outlined"
      onClick={handleCardClick}
      sx={{ minWidth: '400px', maxWidth: '400px' }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="div">
            {evaluation.name}
          </Typography>
          <EvaluationOutcomeIcon
            outcome={evaluation.mostRecentResult?.outcome}
          />
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography paragraph>{evaluation.description}</Typography>
            </Grid>
            {evaluation.mostRecentResult ? (
              <>
                <Grid item xs={6}>
                  <Typography>
                    Progress: {evaluation.mostRecentResult.progress}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Outcome: {evaluation.mostRecentResult.outcome}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Reason: {evaluation.mostRecentResult.reason}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Start Time:{' '}
                    <FormattedDateTime
                      isoDate={evaluation.mostRecentResult?.startTime}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    End Time:{' '}
                    <FormattedDateTime
                      isoDate={evaluation.mostRecentResult?.endTime}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Sources:</Typography>
                  {evaluation.mostRecentResult.sources?.length ? (
                    <Stack direction="row" spacing={1}>
                      {evaluation.mostRecentResult.sources.map(
                        (source, index) => (
                          <Chip key={index} label={source || 'N/A'} />
                        )
                      )}
                    </Stack>
                  ) : (
                    <Typography>N/A</Typography>
                  )}
                </Grid>
              </>
            ) : (
              <Typography sx={{ mt: 2, ml: 2 }}>
                This evaluation has not been completed yet.
              </Typography>
            )}
          </Grid>
        </Collapse>
      </CardContent>
      <CardActions disableSpacing>
        {expanded && (
          <Box display="flex" justifyContent="flex-end" width="100%">
            {actions}
          </Box>
        )}
      </CardActions>
    </Card>
  );
}

import { Box, Divider, Link, Typography } from '@mui/material';

export default function NoSelectedProject() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="60vh"
      width="100%"
      gap={2}
    >
      <Divider
        orientation="vertical"
        style={{ backgroundColor: 'white', height: '70px', width: '4px' }}
      />
      <Box>
        <Typography variant="h6" fontWeight={600}>
          No selected project
        </Typography>
        <Typography variant="caption" gutterBottom>
          Go to <Link href="/projects">projects page</Link> to select a project
        </Typography>
      </Box>
    </Box>
  );
}

import { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';

export default function ExperimentMetricChart(props: {
  metric: string;
  data: Array<any>;
  preview: boolean;
}) {
  const { metric, data, preview } = props;

  const metricsData: Map<string, Array<number>> = useMemo(() => {
    const values: Array<number> = [];
    const timestamps: Array<number> = [];
    const epoches: Array<number> = [];
    data.forEach((dataEntry: any) => {
      values.push(dataEntry.value as number);
      timestamps.push(parseInt(dataEntry.timeStamp, 10) as number);
      epoches.push(dataEntry.step as number);
    });
    return new Map<string, Array<number>>([
      ['epoches', epoches],
      ['values', values],
      ['timestamps', timestamps],
    ]);
  }, [data]);

  const getFields = () => {
    const epoches = metricsData.get('epoches') as number[];
    const values = metricsData.get('values') as number[];
    const timestamps = metricsData.get('timestamps') as number[];
    return [epoches, values, timestamps];
  };

  const option = {
    tooltip: preview
      ? {}
      : {
          trigger: 'axis',
          formatter: (params: any) => {
            const index: number = params[0].dataIndex as number;
            const [epoches, values, timestamps] = getFields();
            return `Epoch ${epoches[index]}<br/>🔵 ${metric}: <b>${
              Math.round(values[index] * 1000) / 1000
            }</b> <br/>🔴 timestamp: <b>${
              new Date(timestamps[index]).toTimeString().split(' ')[0]
            }</b>`;
          },
        },
    grid: preview
      ? {
          left: '15%',
          bottom: '5%',
          right: '5%',
          top: '20%',
        }
      : {
          left: '10%',
          bottom: '10%',
          right: '5%',
          top: '15%',
        },
    toolbox: preview
      ? {}
      : {
          feature: {
            dataView: {
              show: true,
              readOnly: false,
              backgroundColor: '#13375a',
              textColor: '#d7cfcf',
              optionToContent(options: any) {
                // eslint-disable-next-line
                const [epoches, _, timestamps] = getFields();
                let body = '';
                options.series[0].data.forEach((value: number, idx: number) => {
                  body += `
              <tr>
                <td>${epoches[idx]}</td>
                <td>${value}</td>
                <td>${timestamps[idx]}</td>
              </tr>
              `;
                });
                return `
            <table style="width: 100%;text-align: center; color: #d7cfcf">
              <tbody>
                <tr>
                  <th>epoch</th>
                  <th>${metric}</th>
                  <th>timestamp</th>
                </tr>
                ${body}
              </tbody>
            </table>
            `;
              },
            },
            saveAsImage: { show: true },
          },
        },
    xAxis: [
      {
        type: 'category',
        axisTick: { alignWithLabel: true },
        name: 'epoch',
        data: metricsData.get('epoches'),
        show: true,
      },
    ],
    yAxis: preview
      ? {
          type: 'value',
          position: 'left',
          name: metric.toLowerCase(),
          alignTicks: true,
          show: true,
          min:
            Math.floor(
              Math.min(...(metricsData.get('values') as number[])) * 100
            ) / 100,
        }
      : {
          type: 'value',
          position: 'left',
          name: metric.toLowerCase(),
          alignTicks: true,
          show: true,
        },
    series: [
      {
        type: 'line',
        name: metric,
        silent: preview,
        data: metricsData.get('values'),
      },
    ],
  };
  return <ReactEcharts option={option} />;
}

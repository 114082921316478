import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import NoSelectedProject from './NoSelectedProject';

interface ProjectWrapperProps {
  children: ReactNode;
}

const ProjectWrapper: React.FC<ProjectWrapperProps> = ({ children }) => {
  const { projectId } = useParams<{ projectId?: string }>();

  return projectId ? <>{children}</> : <NoSelectedProject />;
};

export default ProjectWrapper;

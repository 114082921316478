import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Grid, Chip } from '@mui/material';
import { colorThemes } from '../theme';
import { ReactNode } from 'react';
import {
  RiskClassCategoryEnum,
  Stage,
  User,
} from '../__generated__/gql/graphql';
import { StageDisplayMap } from './project_creation_modal/UserFacingModalStage';
import UserTag from '../components/UserTag';

type ProjectDetailCardProps = {
  id: string;
  title: string;
  description: string;
  onClick: () => void;
  isSelected?: boolean;
  department: string;
  stage: Stage | null | undefined;
  user: User;
  riskClass: RiskClassCategoryEnum | string;
};

const InfoItem = ({
  title,
  content,
}: {
  title: string;
  content: ReactNode;
}) => (
  <Box mt={2}>
    <Typography variant="subtitle2" color="text.secondary" noWrap>
      {title}:
    </Typography>
    {content}
  </Box>
);

export default function ProjectDetailCard({
  title,
  onClick,
  isSelected,
  department,
  stage,
  user,
  riskClass,
}: ProjectDetailCardProps) {
  return (
    <Card
      sx={{
        width: 400,
        height: 200,
        transition: 'all .2s',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_400,
        },
        ...(isSelected && {
          border: `2px solid ${colorThemes.YELLOW_600}`,
        }),
      }}
      onClick={event => {
        event.stopPropagation();
        onClick();
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            noWrap
            textOverflow="ellipsis"
            color="text.main"
            variant="h6"
          >
            {title}
          </Typography>
        </Box>

        <Grid container spacing={8}>
          <Grid item xs={6}>
            <InfoItem
              title="Department"
              content={
                <Chip
                  variant="outlined"
                  size="small"
                  label={department === '' ? 'Not Defined' : department}
                />
              }
            />
            <InfoItem
              title="Stage"
              content={
                <Chip
                  variant="outlined"
                  size="small"
                  label={
                    stage != null && stage !== undefined
                      ? StageDisplayMap[stage]
                      : 'Not Defined'
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InfoItem
              title="Responsible Person"
              content={
                <UserTag
                  key={user.id}
                  user={user}
                  variant={'text'}
                  size={'medium'}
                  noWrap={true}
                />
              }
            />
            <InfoItem
              title="Risk Class"
              content={
                <Chip variant="outlined" size="small" label={riskClass} />
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

import { Box, Divider, Typography } from '@mui/material';

export default function NotFound(props: { subject: string }) {
  const { subject } = props;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      gap={2}
    >
      <Divider
        orientation="vertical"
        style={{ backgroundColor: 'white', height: '50px', width: '4px' }}
      />
      <Typography>
        No
        {` ${subject} `}
        found
      </Typography>
    </Box>
  );
}

import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Button,
  TextField,
  Box,
  Typography,
  Modal,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { RemoteGitSync } from '../__generated__/gql/graphql';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface RemoteGitSyncProps {
  projectId: string;
  initialData?: RemoteGitSync;
}

interface RemoteGitSyncFormValues {
  repoName: string;
  repoUrl: string;
  branch: string;
  syncIntervalMinutes: number;
  projectId: string;
}

const CREATE_REMOTE_GIT_SYNC = gql`
  mutation UpserRemoteGitSync(
    $repoName: String!
    $repoUrl: String!
    $branch: String!
    $syncIntervalMinutes: Int!
    $projectId: ID!
  ) {
    createRemoteGitSync(
      repoName: $repoName
      repoUrl: $repoUrl
      branch: $branch
      syncIntervalMinutes: $syncIntervalMinutes
      projectId: $projectId
    ) {
      remoteGitSync {
        id
        repoName
        repoUrl
        branch
        syncIntervalMinutes
        publicDeploymentKey
        lastSyncedAt
        lastCommitHash
        lastSyncStatus
      }
    }
  }
`;

const RemoteGitSyncComponent: React.FC<RemoteGitSyncProps> = ({
  projectId,
  initialData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createRemoteGitSync] = useMutation(CREATE_REMOTE_GIT_SYNC);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RemoteGitSyncFormValues>({
    defaultValues: {
      repoName: initialData?.repoName || '',
      repoUrl: initialData?.repoUrl || '',
      branch: initialData?.branch || 'main',
      syncIntervalMinutes: initialData?.syncIntervalMinutes || 60,
    },
  });

  const onSubmit: SubmitHandler<RemoteGitSyncFormValues> = async data => {
    try {
      await createRemoteGitSync({
        variables: { ...data, projectId },
        update: (cache, { data: { createRemoteGitSync } }) => {
          if (!createRemoteGitSync) return;

          const cacheId = cache.identify({
            __typename: 'Project',
            id: projectId,
          });

          cache.modify({
            id: cacheId,
            fields: {
              remoteGitSync() {
                return createRemoteGitSync;
              },
            },
          });
        },
      });

      toast.success('Remote Git Sync updated successfully');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Failed to update remote git sync:', error);
      toast.error('Failed to update remote git sync');
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const formatLastSyncedDate = (dateString?: string) => {
    if (!dateString) return 'Not synced yet';
    const lastSynced = DateTime.fromISO(dateString);
    const now = DateTime.now();
    const diff = now.diff(lastSynced, ['days', 'hours', 'minutes']);

    if (diff.days > 0) {
      return `Last synced ${diff.days} day${diff.days > 1 ? 's' : ''} ago`;
    } else if (diff.hours > 0) {
      return `Last synced ${diff.hours} hour${diff.hours > 1 ? 's' : ''} ago`;
    } else if (diff.minutes > 0) {
      return `Last synced ${Math.floor(diff.minutes)} minute${
        Math.floor(diff.minutes) > 1 ? 's' : ''
      } ago`;
    } else {
      return 'Synced just now';
    }
  };

  const downloadDeploymentKey = () => {
    if (initialData?.publicDeploymentKey) {
      const element = document.createElement('a');
      const file = new Blob([initialData.publicDeploymentKey], {
        type: 'text/plain',
      });
      element.href = URL.createObjectURL(file);
      element.download = 'deployment_key.id_rsa';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const deploymentKeyTooltip = `
    This is your deployment SSH key. To use it:
    1. Download the key by clicking the button.
    2. Go to your Git repository settings (e.g., on GitHub or Bitbucket).
    3. Look for 'Deploy Keys' or 'Access Keys' section.
    4. Add a new key and paste the contents of the downloaded file.
    5. Give the key a meaningful name (e.g., 'Trail Sync key').
    6. Ensure you grant read-only access.
    This allows secure, automated syncing between your repo and our system.
  `;

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Repository:</strong> {initialData?.repoName || 'Not set'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatLastSyncedDate(initialData?.lastSyncedAt)}
        </Typography>
        {initialData?.lastCommitHash && (
          <Typography variant="body2" color="text.secondary">
            <strong>Last Commit:</strong>{' '}
            {initialData.lastCommitHash.substring(0, 7)}
          </Typography>
        )}
        {initialData?.lastSyncStatus && (
          <Typography
            variant="body2"
            color={
              initialData.lastSyncStatus.startsWith('Failed')
                ? 'error'
                : 'success'
            }
          >
            <strong>Sync Status:</strong> {initialData.lastSyncStatus}
          </Typography>
        )}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Button
            onClick={handleOpenModal}
            variant="outlined"
            color="primary"
            size="small"
          >
            {initialData ? 'Update Git Sync' : 'Add Git Sync'}
          </Button>
          {initialData?.publicDeploymentKey && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={downloadDeploymentKey}
                size="small"
              >
                Download Deployment Key
              </Button>
              <Tooltip title={deploymentKeyTooltip} arrow>
                <IconButton size="small">
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Stack>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="remote-git-sync-modal"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <Typography
            id="remote-git-sync-modal"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {initialData ? 'Update Remote Git Sync' : 'Create Remote Git Sync'}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
          >
            <TextField
              label="Repository Name"
              {...register('repoName', {
                required: 'Repository name is required',
              })}
              error={!!errors.repoName}
              helperText={errors.repoName?.message}
              fullWidth
            />
            <TextField
              label="Repository URL"
              {...register('repoUrl', {
                required: 'Repository URL is required',
              })}
              error={!!errors.repoUrl}
              helperText={errors.repoUrl?.message}
              fullWidth
            />
            <TextField label="Branch" {...register('branch')} fullWidth />
            <TextField
              label="Sync Interval Minutes"
              type="number"
              {...register('syncIntervalMinutes', {
                required: 'Sync interval is required',
                min: 1,
              })}
              error={!!errors.syncIntervalMinutes}
              helperText={errors.syncIntervalMinutes?.message}
              fullWidth
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
            >
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button type="submit" variant="outlined" color="primary">
                {initialData ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RemoteGitSyncComponent;

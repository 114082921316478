import { gql, useMutation } from '@apollo/client';

import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal } from '@mui/material';

export const ADD_EVALUATION = gql`
  mutation AddEvaluation(
    $description: String!
    $evaluationClassType: String!
    $experimentId: String!
    $name: String!
  ) {
    addEvaluation(
      description: $description
      evaluationClassType: $evaluationClassType
      experimentId: $experimentId
      name: $name
    ) {
      evaluation {
        id
        name
        description
        evaluationClassType
      }
    }
  }
`;

export interface EvaluationForm {
  name: string;
  evaluationClassType: string;
  description: string;
}

interface AddEvaluationModalProps {
  open: boolean;
  experimentId: string;
  onClose: () => void;
}

const AddEvaluationModal: React.FC<AddEvaluationModalProps> = ({
  open,
  experimentId,
  onClose,
}) => {
  const [formValues, setFormValues] = useState<EvaluationForm>({
    name: '',
    evaluationClassType: '',
    description: '',
  });
  const [addEvaluation, { loading, error }] = useMutation(ADD_EVALUATION);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      await addEvaluation({
        variables: {
          description: formValues.description,
          evaluationClassType: formValues.evaluationClassType,
          experimentId,
          name: formValues.name,
        },
      });
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          padding: '16px 32px 24px',
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: '16px' }}>
          Add Evaluation
        </Typography>
        <TextField
          fullWidth
          name="name"
          label="Name"
          variant="outlined"
          margin="normal"
          value={formValues.name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          name="evaluationClassType"
          label="Evaluation Class Type"
          variant="outlined"
          margin="normal"
          value={formValues.evaluationClassType}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          margin="normal"
          value={formValues.description}
          onChange={handleChange}
        />
        {error && <Typography color="error">Error: {error.message}</Typography>}
        <Box
          sx={{
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEvaluationModal;

import { CardContent, Typography } from '@mui/material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Standard } from '../../../../../__generated__/pydantic_parsers';
import { MarkdownRendering } from '../../../../../components/MarkdownRendering';

interface TextResultCardProps {
  parsedOutput: Standard;
}

export default function TextResultCard({ parsedOutput }: TextResultCardProps) {
  const enableDefaultMarkdownFormatting =
    useFeatureFlagEnabled('markdown_rendering');

  return (
    <CardContent>
      {enableDefaultMarkdownFormatting ? (
        <MarkdownRendering mdString={parsedOutput.text} />
      ) : (
        <Typography>{parsedOutput.text}</Typography>
      )}
    </CardContent>
  );
}

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { colorThemes } from '../../theme';
import FormNavigationSidebar from '../risk_assessment/FormNavigationSidebar';
import { scrollbarSx } from '../../components/StyledScrollable';
import RiskOverview from './RiskOverview';
import RiskTreatmentSection from './RiskTreatmentSection';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  BsiRiskSection,
  UserResponsibilityEnum,
  UsersItemsQuery,
  RiskAssessment,
  GetAllRiskTreatmentsQuery,
  GetCurrentUserForRiskTreatmentQuery,
  type RiskTreatment,
  RiskAssessmentFrameworkMaterializedForTreatmentQuery,
  StandardRiskSection,
  RiskSection,
  User,
} from '../../__generated__/gql/graphql';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { calculateBSIRiskRank } from '../risk_assessment/BSIRiskSection';
import { useUnsavedChanges } from '../../hooks/useUnsavedChanges';
import { calculateStandardRiskRank } from '../risk_assessment/StandardRiskSection';

const GET_RISK_ASSESSMENT_FRAMEWORK_MATERIALIZED = gql`
  query RiskAssessmentFrameworkMaterializedForTreatment(
    $riskAssessmentFrameworkMaterializedId: String!
  ) {
    riskAssessmentFrameworkMaterialized(
      riskAssessmentFrameworkMaterializedId: $riskAssessmentFrameworkMaterializedId
    ) {
      id
      name
      riskAssessmentCatalogueType
      riskAssessmentReference {
        id
        riskSections {
          ... on StandardRiskSection {
            frequency
            impactSystem
            impactUser
            impactBusiness
            severity
            treatments {
              id
              treatment
              responsiblePersonId
              description
            }
            residualRisk
            acceptRiskWithoutTreatment
          }
          ... on BSIRiskSection {
            frequency
            severity
            treatments {
              id
              treatment
              responsiblePersonId
              description
            }
            residualRisk
            acceptRiskWithoutTreatment
          }
        }
      }
      categories {
        categoryName
        sections {
          id
          label
          description
          guidingQuestions
        }
      }
    }
  }
`;

const GET_ALL_RISK_TREATMENTS = gql`
  query GetAllRiskTreatments {
    riskTreatments {
      id
      treatment
      responsiblePersonId
      description
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUserForRiskTreatment {
    currentUser {
      responsibilities
    }
  }
`;

const GET_USERS = gql`
  query getallUsers {
    allUsers {
      id
      email
      name
      initials
    }
  }
`;

const UPDATE_RISK_SECTIONS = gql`
  mutation UpdateRiskSections(
    $frameworkId: ID!
    $riskAssessmentId: ID
    $riskAssessmentCatalogueType: RiskAssessmentCatalogueTypeEnum!
    $riskSections: [RiskSectionInput]!
  ) {
    upsertRiskAssessment(
      frameworkId: $frameworkId
      riskAssessmentId: $riskAssessmentId
      riskAssessmentCatalogueType: $riskAssessmentCatalogueType
      riskSections: $riskSections
    ) {
      riskAssessment {
        id
        riskSections {
          ... on StandardRiskSection {
            frequency
            impactSystem
            impactUser
            impactBusiness
            severity
            treatments {
              id
              treatment
              responsiblePersonId
              description
            }
            residualRisk
            acceptRiskWithoutTreatment
          }
          ... on BSIRiskSection {
            frequency
            severity
            treatments {
              id
              treatment
              responsiblePersonId
              description
            }
            residualRisk
            acceptRiskWithoutTreatment
          }
        }
      }
    }
  }
`;

const SUBMIT_RISK_ASSESSMENT = gql`
  mutation SubmitRiskAssessment(
    $riskAssessmentId: ID!
    $projectId: ID!
    $riskAssessmentFrameworkMaterializedId: ID!
    $riskAssessmentFrameworkName: RiskAssessmentNameEnum
  ) {
    submitRiskAssessment(
      riskAssessmentId: $riskAssessmentId
      projectId: $projectId
      riskAssessmentFrameworkMaterializedId: $riskAssessmentFrameworkMaterializedId
      riskAssessmentFrameworkName: $riskAssessmentFrameworkName
    ) {
      riskAssessment {
        id
      }
    }
  }
`;

const DECLINE_RISK_ASSESSMENT = gql`
  mutation DeclineRiskAssessment(
    $projectId: ID!
    $riskAssessmentId: ID!
    $riskAssessmentFrameworkMaterializedId: ID!
    $riskAssessmentFrameworkName: RiskAssessmentNameEnum
    $feedback: String
  ) {
    declineRiskAssessment(
      projectId: $projectId
      riskAssessmentId: $riskAssessmentId
      feedback: $feedback
      riskAssessmentFrameworkName: $riskAssessmentFrameworkName
      riskAssessmentFrameworkMaterializedId: $riskAssessmentFrameworkMaterializedId
    ) {
      riskAssessment {
        id
      }
    }
  }
`;

const APPROVE_RISK_ASSESSMENT = gql`
  mutation ApproveRiskAssessment($riskAssessmentId: ID!) {
    approveRiskAssessment(riskAssessmentId: $riskAssessmentId) {
      riskAssessment {
        id
      }
    }
  }
`;

export default function RiskTreatment() {
  const { assessmentFrameworkId, projectId } = useParams<{
    assessmentFrameworkId: string;
    projectId: string;
  }>();
  const { data: frameworkCatalogue } =
    useSuspenseQuery<RiskAssessmentFrameworkMaterializedForTreatmentQuery>(
      GET_RISK_ASSESSMENT_FRAMEWORK_MATERIALIZED,
      {
        variables: {
          riskAssessmentFrameworkMaterializedId: assessmentFrameworkId,
        },
      }
    );
  const { data: allRiskTreatments } =
    useSuspenseQuery<GetAllRiskTreatmentsQuery>(GET_ALL_RISK_TREATMENTS);

  const riskAssessmentCatalogueType =
    frameworkCatalogue?.riskAssessmentFrameworkMaterialized
      ?.riskAssessmentCatalogueType;
  const riskCatalogue =
    frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.categories ?? [];

  const formValues: (StandardRiskSection | BsiRiskSection)[] =
    frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.riskAssessmentReference?.riskSections?.filter(
      (section): section is StandardRiskSection | BsiRiskSection =>
        section !== null
    ) ?? [];

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  const activeCategory = riskCatalogue?.[activeCategoryIndex] ?? null;
  const activeRiskSectionStatic = activeCategory?.sections?.[activeSectionIndex]
    ? {
        label: activeCategory.sections[activeSectionIndex]?.label ?? '',
        id: activeCategory.sections[activeSectionIndex]?.id ?? '',
        description:
          activeCategory.sections[activeSectionIndex]?.description ?? '',
        guidingQuestions:
          activeCategory.sections[activeSectionIndex]?.guidingQuestions ?? '',
      }
    : {
        label: '',
        id: '',
        description: '',
        guidingQuestions: '',
      };

  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDeclineDialog(false);
  };
  const getRiskTreatmentsById = (
    treatmentIds: ({ id: string } | null)[]
  ): RiskTreatment[] => {
    const ids = treatmentIds.filter(Boolean).map(item => item!.id);
    return (
      allRiskTreatments?.riskTreatments?.filter(treatment =>
        ids.includes(treatment.id)
      ) || []
    );
  };

  function processRiskAssessment(riskSections: RiskSection[]) {
    const riskCounts = { low: 0, medium: 0, high: 0, critical: 0 };
    let totalCount = 0;
    let selectedTreatments = 0;

    function incrementRiskCount(rank: number): void {
      if (rank === 0) return;
      if (rank <= 25) riskCounts.low++;
      else if (rank <= 50) riskCounts.medium++;
      else if (rank <= 100) riskCounts.high++;
      else riskCounts.critical++;
    }

    riskSections?.forEach(section => {
      if (!section) return;

      let rank = 0;

      switch (section.__typename) {
        case 'StandardRiskSection':
          rank = calculateStandardRiskRank(
            section.frequency,
            section.impactSystem,
            section.impactBusiness,
            section.impactUser,
            section.severity
          );
          break;
        case 'BSIRiskSection':
          rank = calculateBSIRiskRank(section.frequency, section.severity);
          break;
        default:
          console.warn(`Unknown section type: ${section.__typename}`);
      }

      if (section.treatments?.length || section.acceptRiskWithoutTreatment) {
        selectedTreatments++;
      }

      incrementRiskCount(rank);
      totalCount++;
    });

    return { riskCounts, totalCount, selectedTreatments };
  }

  const { riskCounts, totalCount, selectedTreatments } = processRiskAssessment(
    frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.riskAssessmentReference?.riskSections?.filter(
      (section): section is RiskSection => section !== null
    ) ?? []
  );

  const methods = useForm<RiskAssessment>({
    defaultValues: {
      riskSections: formValues.map(section => ({
        ...section,
        treatments: getRiskTreatmentsById(section.treatments || []),
      })),
    },
  });

  const { isDirty } = methods.formState;

  const [updateRiskSections, { loading }] = useMutation(UPDATE_RISK_SECTIONS);
  const handleSave = async () => {
    try {
      const data = methods.getValues();
      await updateRiskSections({
        variables: {
          frameworkId: assessmentFrameworkId,
          riskAssessmentId:
            frameworkCatalogue?.riskAssessmentFrameworkMaterialized
              ?.riskAssessmentReference?.id,
          riskAssessmentCatalogueType,
          riskSections: data.riskSections,
        },
      });
      methods.reset(data);
      toast.success('Data saved successfully!');
    } catch (error) {
      toast.error(`An error occurred while saving data: ${error}`);
    }
  };

  const { data: getCurrentUser } =
    useSuspenseQuery<GetCurrentUserForRiskTreatmentQuery>(GET_CURRENT_USER);
  const currentUser = getCurrentUser?.currentUser;

  const [submitRiskTreatment] = useMutation(SUBMIT_RISK_ASSESSMENT, {
    refetchQueries: ['GetCurrentUser'],
  });
  const [approveRiskTreatment] = useMutation(APPROVE_RISK_ASSESSMENT, {
    refetchQueries: ['GetCurrentUser'],
  });
  const [declineRiskTreatment] = useMutation(DECLINE_RISK_ASSESSMENT, {
    refetchQueries: ['GetCurrentUser'],
  });

  const sendForApproval = async () => {
    if (isDirty) {
      await handleSave();
    }
    await submitRiskTreatment({
      variables: {
        riskAssessmentId:
          frameworkCatalogue?.riskAssessmentFrameworkMaterialized
            ?.riskAssessmentReference?.id,
        riskAssessmentFrameworkMaterializedId:
          frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.id,
        riskAssessmentFrameworkName:
          frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.name,
        projectId,
      },
    });
    toast.success('Risk Management Sent for Approval Successfully!');
  };

  const handleDecline = async () => {
    if (isDirty) {
      await handleSave();
    }
    setOpenDeclineDialog(true);
  };

  const handleApprove = async () => {
    if (isDirty) {
      await handleSave();
    }
    await approveRiskTreatment({
      variables: {
        riskAssessmentId:
          frameworkCatalogue?.riskAssessmentFrameworkMaterialized
            ?.riskAssessmentReference?.id,
      },
    });
    toast.success('Risk Management Approved!');
  };

  const { data: allUsers } = useSuspenseQuery<UsersItemsQuery>(GET_USERS);

  const absoluteSectionIndex =
    riskCatalogue
      ?.slice(0, activeCategoryIndex)
      ?.reduce((acc, category) => acc + (category?.sections?.length || 0), 0) +
    activeSectionIndex;

  useUnsavedChanges({ isDirty });

  const handleSidebarNavigation = (
    categoryIndex: number,
    sectionIndex: number
  ) => {
    setActiveCategoryIndex(categoryIndex);
    setActiveSectionIndex(sectionIndex);
  };

  return (
    <>
      <Box
        display="flex"
        height="100%"
        flexDirection={'column'}
        overflow={'hidden'}
      >
        <Box
          sx={{ marginBottom: '20px' }}
          display={'flex'}
          justifyContent={'space-between'}
          height={'40px'}
        >
          <Typography variant="h4" marginBottom={3}>
            Risk Treatment
          </Typography>
          <Box display={'flex'} gap={2}>
            <ButtonGroup>
              {currentUser?.responsibilities?.includes(
                UserResponsibilityEnum.RiskManagement
              ) && <Button onClick={sendForApproval}>Send for Approval</Button>}
              {currentUser?.responsibilities?.includes(
                UserResponsibilityEnum.Approval
              ) && (
                <ButtonGroup>
                  <Button onClick={handleApprove}>Approve</Button>
                  <Button onClick={handleDecline}>Decline</Button>
                </ButtonGroup>
              )}
            </ButtonGroup>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Dialog
          open={openDeclineDialog}
          onClose={handleDialogClose}
          PaperProps={{
            component: 'form',
            onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              const feedback = formJson.description;
              await declineRiskTreatment({
                variables: {
                  projectId,
                  riskAssessmentId:
                    frameworkCatalogue?.riskAssessmentFrameworkMaterialized
                      ?.riskAssessmentReference?.id,
                  riskAssessmentFrameworkMaterializedId:
                    frameworkCatalogue?.riskAssessmentFrameworkMaterialized?.id,
                  riskAssessmentFrameworkName:
                    frameworkCatalogue?.riskAssessmentFrameworkMaterialized
                      ?.name,
                  feedback,
                },
              });
              toast.success('Risk Management Declined!');
              handleDialogClose();
            },
          }}
        >
          <DialogTitle>Decline Risk Management</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <DialogContentText>
              Please provide a detailed reason for declining the risk management
              proposal.
            </DialogContentText>
            <TextField
              autoFocus
              id="name"
              name="description"
              label="Reason for Decline"
              fullWidth
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button type="submit">Decline Risk Management</Button>
          </DialogActions>
        </Dialog>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
          color={colorThemes.GREY_100}
          gridTemplateRows="auto 1fr"
          height="100%"
          overflow="hidden"
        >
          <RiskOverview
            criticalRisks={riskCounts.critical}
            majorRisks={riskCounts.high}
            seriousRisks={riskCounts.medium}
            moderateRisks={riskCounts.low}
            selectedForTreatment={selectedTreatments}
            totalRisks={totalCount}
          />
          <Box
            gridColumn="span 9"
            gridRow="span 2"
            display="flex"
            overflow="auto"
            minHeight="100%"
            flexDirection="row"
          >
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height="100%"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <RiskTreatmentSection
                methods={methods}
                allUsers={allUsers.allUsers as User[]}
                activeRiskSectionStatic={activeRiskSectionStatic}
                availableRiskTreatments={
                  allRiskTreatments?.riskTreatments as RiskTreatment[]
                }
                activeRiskSectionId={absoluteSectionIndex}
                riskAssessmentCatalogueType={riskAssessmentCatalogueType!}
              />
            )}
          </Box>
          <Box
            gridColumn="span 3"
            sx={{ backgroundColor: colorThemes.DARK_BLUE_600 }}
            borderRadius="8px"
            padding="24px"
          >
            <Typography variant="h5">Risks</Typography>
            <Box maxHeight="58vh" overflow={'auto'} sx={{ ...scrollbarSx }}>
              <FormNavigationSidebar
                categories={
                  riskCatalogue?.map(cat => ({
                    categoryName: cat?.categoryName ?? '',
                    sections:
                      cat?.sections?.map(s => ({
                        label: s?.label ?? '',
                      })) ?? [],
                  })) ?? []
                }
                activeSection={`${activeCategoryIndex}-${activeSectionIndex}`}
                onNavigate={handleSidebarNavigation}
              />
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Box>
    </>
  );
}

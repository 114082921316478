import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { colorThemes } from '../theme';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  UserResponsibilityEnum,
  UserRoleEnum,
} from '../__generated__/gql/graphql';
import { UserProps } from './UserManagementPage';

export default function UserRow({
  user,
  index,
  toggleRole,
  removeResponsibility,
  addResponsibility,
  onWrite,
  availableResponsibilities,
}: {
  user: UserProps;
  index: number;
  toggleRole: (role: UserRoleEnum, index: number) => void;
  removeResponsibility: (
    index: number,
    responsibility: UserResponsibilityEnum
  ) => void;
  addResponsibility: (
    index: number,
    responsibility: UserResponsibilityEnum
  ) => void;
  onWrite: (index: number, name: string) => void;
  availableResponsibilities: UserResponsibilityEnum[];
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (user.roles === undefined || user.roles === null) {
    user.roles = [];
  }
  const handleRoleChange = (event: SelectChangeEvent<UserRoleEnum[]>) => {
    const selectedRole = event.target.value as UserRoleEnum;
    toggleRole(selectedRole, index);
  };
  const handleDeleteResponsibilitiy = (
    responsibility: UserResponsibilityEnum
  ) => {
    removeResponsibility(index, responsibility);
  };

  const handleAddResponsibility = (responibility: UserResponsibilityEnum) => {
    addResponsibility(index, responibility);
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: colorThemes.DARK_BLUE_400,
        paddingX: '1.5rem',
        paddingY: '1rem',
        borderRadius: '0.5rem',
      }}
      key={index}
    >
      <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          item
          xs={5}
          sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
        >
          <Typography
            variant="h6"
            sx={{
              width: '40%',
              fontSize: '1rem',
              overflowX: 'hidden',
            }}
          >
            {user.name || user.email}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
              marginX: '2rem',
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={
                  user.roles?.filter(role => role !== null) as UserRoleEnum[]
                }
                onChange={handleRoleChange}
                input={<OutlinedInput label="Roles" />}
                renderValue={selected =>
                  selected.map(role => RoleDisplayMap[role]).join(', ')
                }
              >
                {Object.values(UserRoleEnum).map(role => (
                  <MenuItem key={role} value={role}>
                    <Checkbox checked={user.roles?.includes(role)} />
                    <ListItemText primary={RoleDisplayMap[role]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ paddingX: '1rem' }}>
          {user.responsibilities?.map((responsibility, index) => (
            <Chip
              label={ResponsibilityDisplayMap[responsibility]}
              key={index}
              onDelete={() => handleDeleteResponsibilitiy(responsibility)}
              sx={{ margin: '0.2rem' }}
              color="info"
            />
          ))}
          {availableResponsibilities.length > 0 && (
            <Chip
              label={'Add'}
              onClick={handleClickOpen}
              variant="outlined"
              sx={{ margin: '0.2rem' }}
              icon={<AddIcon color="action" />}
            />
          )}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Responsibilities</DialogTitle>
            <DialogContent>
              <Box>
                {user.responsibilities?.map((responsibility, index) => (
                  <Chip
                    label={ResponsibilityDisplayMap[responsibility]}
                    key={index}
                    onDelete={() => handleDeleteResponsibilitiy(responsibility)}
                    sx={{ margin: '0.2rem' }}
                    color="info"
                  />
                ))}
                {availableResponsibilities
                  .filter(resp => !user.responsibilities?.includes(resp))
                  .map((responsibility, index) => (
                    <Chip
                      label={ResponsibilityDisplayMap[responsibility]}
                      key={index}
                      onClick={() => handleAddResponsibility(responsibility)}
                      sx={{ margin: '0.2rem' }}
                      variant="outlined"
                    />
                  ))}
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={'Proof of Qualification'}
            sx={{ width: '100%' }}
            value={user.proofOfQualification}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onWrite(index, event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export const RoleDisplayMap = {
  AI_LEAD: 'AI Lead',
  COMPLIANCE: 'Compliance',
  DEVELOPER: 'Developer',
  MANAGEMENT: 'Management',
  PROJECT_MANAGEMENT: 'Project Management',
};

export const ResponsibilityDisplayMap = {
  AI_SYSTEM: 'AI System',
  APPROVAL: 'Approval',
  ASSET_RSC_MANAGE: 'Asset & Resource Management',
  DATA_QA: 'Data Quality Management',
  DEVELOPMENT: 'Development',
  HUMAN_OVER: 'Human Oversight',
  LEGAL_REQ: 'Fulfil Legal Requirements',
  PERFORMANCE: 'Performance',
  PRIVACY: 'Privacy',
  RISK_MANAGEMENT: 'Risk Management',
  SAFETY: 'Safety',
  SECURITY: 'Security',
  SUPPLIER_REL: 'Supplier Relationships',
  ORG_CONTEXT: 'Organizational Context',
  AI_POLICY: 'AI Policy',
  ROLES_RESPONSIBILITIES: 'Roles & Responsibilities',
  AI_SYSTEM_LIFECYCLE: 'Ai System Lifecycle',
  AI_USAGE: 'AI Usage',
  INFORMATION_TRANSPARENCY: 'Information Transparency',
  MANAGEMENT_SYSTEM_PERFORMANCE: 'Management System Performance',
  SUPPORT: 'Support',
  LEADERSHIP: 'Leadership',
};

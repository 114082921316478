export const scrollbarSx = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(136, 136, 136, 0.5)',
    borderRadius: '10px',
    border: '3px solid transparent',
    backgroundClip: 'padding-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(85, 85, 85, 0.5)',
  },
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(136, 136, 136, 0.5) transparent',
};

import { DateTime } from 'luxon';

export const formatDateTime = (timestamp: string): string => {
  const dateTime = DateTime.fromISO(timestamp, { zone: 'utc' });
  return dateTime.setZone('local').toFormat('MMM d yyyy - h:mma');
};

export const formatDate = (timestamp: string): string => {
  const dateTime = DateTime.fromISO(timestamp, { zone: 'utc' });
  return dateTime.setZone('local').toFormat('dd.mm.yyyy');
};

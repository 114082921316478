import { useCallback, useContext, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { ConfirmCtx } from '../components/unsaved_changes/UnsavedChangesProvider';

export const useUnsavedChanges = ({
  isDirty = false,
}: {
  isDirty?: boolean;
}) => {
  const blocker = useBlocker(isDirty);
  const confirmContext = useContext(ConfirmCtx);

  if (!confirmContext) {
    throw new Error(
      'useUnsavedChanges must be used within a UnsavedChangesProvider'
    );
  }

  const { show } = confirmContext;

  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);

    return new Promise<boolean>(resolve => {
      show({
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }, [isDirty, show]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm().then(result => {
        if (result) blocker.proceed();
        else blocker.reset();
      });
    }
  }, [blocker, confirm]);

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () =>
        'You have unsaved changes. Do you really want to leave?';
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  return { confirm };
};

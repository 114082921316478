import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

interface ExpandableCardProps {
  title: string;
  titleIcon?: React.ReactNode;
  children: React.ReactNode;
  initiallyExpanded?: boolean;
  sx?: object;
}

const ExpandableCard: FC<ExpandableCardProps> = ({
  title,
  titleIcon,
  children,
  initiallyExpanded = false,
  sx,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={sx}>
      <CardHeader
        title={title}
        onClick={handleExpandClick}
        action={
          <>
            {titleIcon && <IconButton>{titleIcon}</IconButton>}
            <IconButton>
              {expanded ? (
                <ExpandLess sx={{ color: 'black' }} />
              ) : (
                <ExpandMore sx={{ color: 'black' }} />
              )}
            </IconButton>
          </>
        }
        sx={{ cursor: 'pointer' }}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent sx={{ paddingTop: 0 }}>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default ExpandableCard;

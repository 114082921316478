import React, { FC } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { colorThemes } from '../../theme';
import UserTag from '../../components/UserTag';
import { User } from '../../__generated__/gql/graphql';

interface TreatmentBoxProps {
  checked: boolean;
  personId: string;
  title: string;
  description: Maybe<string>;
  id?: string;
  setExpanded?: (expanded: boolean) => void;
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange?: (event: SelectChangeEvent<string>) => void;
  onFormSubmit?: (event: React.FormEvent) => void;
  allUsers: User[];
  riskAssessmentId?: string;
  activeRiskSectionId?: number;
  addible?: boolean;
  deletable?: boolean;
  onAdd?: () => void;
  onDelete?: () => void;
}

const TreatmentBox: FC<TreatmentBoxProps> = ({
  checked,
  title,
  personId,
  description,
  handleInputChange,
  handleSelectChange,
  onFormSubmit,
  allUsers,
  addible,
  deletable,
  onAdd,
  onDelete,
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleAddClick = () => {
    if (onFormSubmit) {
      onFormSubmit(new Event('submit') as unknown as React.FormEvent);
    } else if (onAdd) {
      onAdd();
    }
  };

  return (
    <Box
      bgcolor={'white'}
      border={2}
      borderRadius={'8px'}
      borderColor={colorThemes.GREY_200}
      p={3}
      display={'flex'}
      flexDirection={'column'}
      gap={'16px'}
      alignItems={'flex-start'}
      width={'100%'}
      component={onFormSubmit ? 'form' : 'div'}
      onSubmit={onFormSubmit}
      ref={formRef}
    >
      {!onFormSubmit && (
        <Typography color={'black'} fontWeight={'bold'}>
          {title}
        </Typography>
      )}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          gap={'16px'}
        >
          {onFormSubmit && (
            <TextField
              id={`treatment-title-${title}`}
              label="Title"
              name="treatment"
              value={title}
              disabled={checked}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  color: 'black',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&.Mui-disabled fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                  color: 'black',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
                width: '200px',
              }}
            />
          )}
          <Box display={'flex'} gap={'16px'}>
            <FormControl sx={{ width: '200px' }}>
              <InputLabel
                sx={{
                  color: 'black',
                  '&.Mui-focused': {
                    color: 'black',
                  },
                }}
                htmlFor={`responsible-person-${title}`}
              >
                Responsible Person
              </InputLabel>
              <Select
                id={`responsible-person-${title}`}
                name="responsiblePersonId"
                value={personId ?? ''}
                disabled={checked}
                onChange={handleSelectChange}
                label="Responsible Person"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: 'white',
                    },
                  },
                }}
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '& .MuiSelect-icon': {
                    color: 'black',
                  },
                  '&.Mui-disabled': {
                    bgcolor: 'white',
                    '& .MuiSelect-select': {
                      opacity: 1,
                      WebkitTextFillColor: 'black !important',
                    },
                    '& .MuiSelect-icon': {
                      display: 'none',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: 'black',
                    },
                  },
                }}
              >
                {allUsers.map((user, userIndex) => (
                  <MenuItem
                    key={userIndex}
                    value={user.id}
                    sx={{
                      color: 'black',
                      bgcolor: 'white',
                      '&:hover': { bgcolor: colorThemes.GREY_300 },
                      '&.Mui-selected': {
                        bgcolor: 'white',
                      },
                    }}
                  >
                    <UserTag
                      key={user.id}
                      user={user}
                      variant={'text'}
                      size={'medium'}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id={`description-${title}`}
              label="Description"
              name="description"
              value={description || ''}
              disabled={checked}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  color: 'black',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                  },
                  '&.Mui-disabled fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'black',
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                  color: 'black',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
                width: addible ? '400px' : '500px',
              }}
            />
          </Box>
        </Box>
        {addible && (
          <Button
            variant={'contained'}
            color={'primary'}
            sx={{ height: '40px' }}
            onClick={handleAddClick}
          >
            Add
          </Button>
        )}
        {deletable && (
          <Button
            variant={'contained'}
            color={'primary'}
            sx={{ height: '40px' }}
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TreatmentBox;

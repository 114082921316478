import React, {
  useRef,
  useState,
  useEffect,
  MouseEvent,
  ReactNode,
} from 'react';

interface HorizontalDragNavigationProps {
  children: ReactNode;
  scrollingSpeed?: number;
  onScroll?: () => void;
}

const HorizontalDragNavigation: React.FC<HorizontalDragNavigationProps> = ({
  children,
  scrollingSpeed = 2,
  onScroll,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [showHandIcon, setShowHandIcon] = useState<boolean>(false);
  const handIconTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
    setShowHandIcon(true);
    if (handIconTimeoutRef.current) {
      clearTimeout(handIconTimeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
      scheduleHideHandIcon();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    scheduleHideHandIcon();
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !containerRef.current) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * scrollingSpeed;
    containerRef.current.scrollLeft = scrollLeft - walk;
    if (onScroll) onScroll();
  };

  const scheduleHideHandIcon = () => {
    if (handIconTimeoutRef.current) {
      clearTimeout(handIconTimeoutRef.current);
    }
    handIconTimeoutRef.current = setTimeout(() => {
      setShowHandIcon(false);
    }, 1000); // Change the timeout duration as needed
  };

  useEffect(() => {
    const handleScroll = () => {
      if (onScroll) onScroll();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      if (handIconTimeoutRef.current) {
        clearTimeout(handIconTimeoutRef.current);
      }
    };
  }, [onScroll]);

  return (
    <div
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{
        overflow: 'auto',
        whiteSpace: 'nowrap',
        cursor: showHandIcon ? (isDragging ? 'grabbing' : 'grab') : 'default',
        userSelect: 'none',
        position: 'relative',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
    >
      <style>
        {`
          div::-webkit-scrollbar {
            display: none; /* Hide scrollbar for WebKit-based browsers */
          }
        `}
      </style>
      {children}
    </div>
  );
};

export default HorizontalDragNavigation;

import {
  LinearProgress,
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material';
import { colorThemes } from '../theme';

export function ProgressBar(props: LinearProgressProps) {
  return (
    <LinearProgress
      color={'secondary'}
      sx={{
        height: 8,
        borderRadius: 5,
        backgroundColor: colorThemes.GREY_100,
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: colorThemes.ACTION_BLUE,
        },
      }}
      {...props}
    />
  );
}

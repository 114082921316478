import React, { useState } from 'react';
import { Modal, Box, Typography, TextField } from '@mui/material';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { GetAtlassianAccountQuery } from '../__generated__/gql/graphql';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import TooltipWithIcon from './TooltipWithIcon';

const ADD_ATLASSIAN_ACCOUNT = gql`
  mutation AddAtlassianAccount(
    $apiToken: String!
    $atlassianServerUrl: String!
    $email: String!
    $projectId: String!
  ) {
    addAtlassianAccount(
      apiToken: $apiToken
      atlassianServerUrl: $atlassianServerUrl
      email: $email
      projectId: $projectId
    ) {
      __typename
    }
  }
`;

const GET_ATLASSIAN_ACCOUNT = gql`
  query GetAtlassianAccount($id: String!) {
    project(id: $id) {
      atlassianAccount {
        email
        serverUrl
        apiToken
        confluenceLink {
          workspace
          pageParentId
        }
      }
    }
  }
`;

const SET_CONFLUENCE_LINK = gql`
  mutation SetConfluenceLink(
    $projectId: String!
    $workspace: String!
    $pageParentId: String!
  ) {
    setConfluenceLink(
      projectId: $projectId
      workspace: $workspace
      pageParentId: $pageParentId
    ) {
      confluenceLink {
        workspace
        pageParentId
      }
    }
  }
`;

type AtlassianCredentials = {
  email: string;
  apiToken: string;
  serverUrl: string;
};

type ConfluenceLink = {
  workspace: string;
  pageParentId: string;
};

type AtlassianAuthModalProps = {
  projectID: string;
  open: boolean;
  onClose: () => void;
};

export default function AtlassianAuthModal({
  projectID,
  open,
  onClose,
}: AtlassianAuthModalProps): JSX.Element {
  const { data: accountData } = useSuspenseQuery<GetAtlassianAccountQuery>(
    GET_ATLASSIAN_ACCOUNT,
    {
      variables: { id: projectID },
    }
  );
  const [addAccountData, { loading: isSaving }] = useMutation(
    ADD_ATLASSIAN_ACCOUNT
  );

  const [confluenceLink, setConfluenceLink] = useState<ConfluenceLink>(
    accountData?.project?.atlassianAccount?.confluenceLink ?? {
      workspace: '',
      pageParentId: '',
    }
  );

  const [saveConfluenceLink, { loading: isSavingConfluenceLink }] =
    useMutation(SET_CONFLUENCE_LINK);

  const [credentials, setCredentials] = useState<AtlassianCredentials>(
    accountData?.project?.atlassianAccount ?? {
      email: 'trail_atlassian_account',
      apiToken: 'trail_atlassian_account',
      serverUrl: 'trail_atlassian_account',
    }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const onSave = async (credentials: AtlassianCredentials) => {
    try {
      await addAccountData({
        variables: {
          apiToken: credentials.apiToken,
          atlassianServerUrl: credentials.serverUrl,
          email: credentials.email,
          projectId: projectID,
        },
      });
      console.log('Account data saved successfully!');
      toast.success('Account data saved successfully!');
    } catch (error) {
      console.error('Error saving account data:', error);
      toast.error('Account data could not be saved!');
    }
  };

  const handleSaveConfluenceLink = async () => {
    try {
      await saveConfluenceLink({
        variables: {
          projectId: projectID,
          workspace: confluenceLink.workspace,
          pageParentId: confluenceLink.pageParentId,
        },
      });
      toast.success('Confluence link saved successfully!');
    } catch (error) {
      console.error('Error saving confluence link:', error);
      toast.error('Failed to save confluence link.');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px',
          gap: '32px',
        }}
      >
        <Box>
          <Typography variant="h6" component="h2">
            Enter Jira Credentials
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              margin="normal"
              label="Server URL"
              name="serverUrl"
              value={credentials.serverUrl}
              onChange={handleChange}
              sx={{ mr: 1 }}
            />
            <TooltipWithIcon
              title="Where do I find the server URL?"
              href="https://community.atlassian.com/t5/Jira-Software-questions/How-do-I-find-the-base-URL-for-my-server-instance/qaq-p/2249704"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              sx={{ mr: 1 }}
              margin="normal"
              label="Email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
            />
            <TooltipWithIcon title="Email address of the trail Atlassian account" />
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              margin="normal"
              label="API Token"
              name="apiToken"
              value={credentials.apiToken}
              onChange={handleChange}
              type="password"
              sx={{ mr: 1 }}
            />
            <TooltipWithIcon
              title="How to get an API token"
              href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
            />
          </Box>
          <LoadingButton
            loading={isSaving}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={() => onSave(credentials)}
          >
            Save
          </LoadingButton>
        </Box>
        <Box>
          <Typography variant="h6" component="h2">
            Confluence Link
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              margin="normal"
              label="Workspace"
              name="workspace"
              value={confluenceLink.workspace}
              onChange={e => {
                setConfluenceLink({
                  ...confluenceLink,
                  workspace: e.target.value,
                });
              }}
              sx={{ mr: 1 }}
            />
            <TooltipWithIcon
              title="Where do I find the workspace name?"
              href="https://www.atlassian.com/software/confluence/resources/guides/get-started/set-up"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              margin="normal"
              label="ParentPageId"
              name="parentPageId"
              value={confluenceLink.pageParentId}
              onChange={e => {
                setConfluenceLink({
                  ...confluenceLink,
                  pageParentId: e.target.value,
                });
              }}
              sx={{ mr: 1 }}
            />
            <TooltipWithIcon
              title="Where do I find the parent page id?"
              href="https://confluence.atlassian.com/confkb/how-to-get-confluence-page-id-648380445.html"
            />
          </Box>
          <LoadingButton
            loading={isSavingConfluenceLink}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={handleSaveConfluenceLink}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

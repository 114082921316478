import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import FlagIcon from '@mui/icons-material/Flag';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import SettingsIcon from '@mui/icons-material/Settings';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TrashIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useMutation, gql, useSuspenseQuery } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import theme, { colorThemes } from '../theme';
import generateLink from '../utils/genericHelpers';
import IosShareIcon from '@mui/icons-material/IosShare';
import MenuButton from '../components/MenuButton';
import { GET_PROJECTS } from './ProjectView';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Risk } from './RisksTable';
import {
  GetAllUsersForSelectQuery,
  GetCurrentUserForConcernReportQuery,
  Project,
  ProjectDetailDrawerQueryQuery,
  ProjectGovernanceFrameworkTemplate,
  RemoteGitSync,
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
  Stage,
} from '../__generated__/gql/graphql';
import RiskClassTag from './RiskClassTag';
import ProjectGridItem from './ProjectGridItem';
import StackEditor from './StackEditor';
import EditableTypography from './EditableTypography';
import ProjectSettingsModal from './ProjectSettingsModal';
import ReportConcernDialog from './ReportConcernDialog';
import { TabContext, TabPanel } from '@mui/lab';
import { Link, Link as RouterLink } from 'react-router-dom';
import NewGovernanceFrameworkModal from './NewGovernanceFrameworkModal';
import AddNewItemCard from '../components/AddNewItemCard';
import RemoteGitSyncForm from './RemoteGitSyncModal';
import { copyToClipboard } from '../components/clipoadUtils';
import UserTag from '../components/UserTag';

const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: String!) {
    deleteProject(projectId: $projectId) {
      id
      success
    }
  }
`;

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $description: String
    $intendedUseCases: [String]
    $limitations: [String]
    $projectId: String!
    $title: String
    $unintendedUseCases: [String]
    $department: String
    $dataSource: String
    $stage: String
    $risks: [RisksInput]
    $usagePolicy: String
    $responsiblePerson: String
    $riskClass: RiskClassInput
    $targetQueryMutation: String!
    $action: String!
  ) {
    updateProject(
      description: $description
      intendedUseCases: $intendedUseCases
      limitations: $limitations
      projectId: $projectId
      title: $title
      unintendedUseCases: $unintendedUseCases
      department: $department
      dataSource: $dataSource
      stage: $stage
      risks: $risks
      usagePolicy: $usagePolicy
      responsiblePerson: $responsiblePerson
      riskClass: $riskClass
    ) {
      __typename
      project {
        __typename
        stage
        id
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
        }
        allowedDataTypes
        allowedTaskTypes
        title
        limitations
        description
        intendedUseCases
        remoteGitSync {
          branch
          publicDeploymentKey
          id
          lastSyncedAt
          repoName
          repoUrl
          syncIntervalMinutes
        }
        responsible {
          __typename
          id
          name
          email
          initials
        }
        numberOfDocumentations
        numberOfExperiments
        risks {
          __typename
          impact
          likelihood
          mitigation
          riskDescription
        }
        projectGovernanceFrameworks {
          __typename
          id
          name
        }
        recommendedGovernanceFrameworks {
          __typename
          id
          name
        }
        mostRecentExperiment {
          __typename
          instanceRuns {
            __typename
            metrics
          }
        }
        department
        dataSource
        usagePolicy
        checkPermission(
          targetQueryMutation: $targetQueryMutation
          action: $action
        ) {
          hasPermission
          userId
        }
      }
    }
  }
`;

const GET_PROJECT = gql`
  query ProjectDetailDrawerQuery(
    $projectId: String!
    $targetQueryMutation: String!
    $action: String!
  ) {
    project(id: $projectId) {
      __typename
      title
      limitations
      description
      intendedUseCases
      riskClass {
        __typename
        riskClassCategory
        riskClassRole
      }
      allowedDataTypes
      allowedTaskTypes
      remoteGitSync {
        __typename
        branch
        publicDeploymentKey
        id
        lastSyncedAt
        repoName
        repoUrl
        syncIntervalMinutes
      }
      responsible {
        __typename
        id
        name
        email
        initials
      }
      numberOfDocumentations
      numberOfExperiments
      risks {
        __typename
        impact
        likelihood
        mitigation
        riskDescription
      }
      projectGovernanceFrameworks {
        id
        name
      }
      recommendedGovernanceFrameworks {
        id
        name
      }
      mostRecentExperiment {
        instanceRuns {
          metrics
        }
      }
      stage
      department
      dataSource
      usagePolicy
      checkPermission(
        targetQueryMutation: $targetQueryMutation
        action: $action
      ) {
        hasPermission
        userId
      }
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUserForConcernReport {
    currentUser {
      email
      name
      initials
    }
  }
`;

const GET_USERS = gql`
  query GetAllUsersForSelect {
    allUsers {
      id
      email
      initials
      name
    }
  }
`;

const DELETE_PROJECT_GOVERNANCE_FRAMEWORK = gql`
  mutation DeleteProjectGovernanceFramework(
    $projectId: String!
    $materializedFrameworkId: String!
  ) {
    deleteProjectGovernanceFramework(
      projectId: $projectId
      materializedFrameworkId: $materializedFrameworkId
    ) {
      success
    }
  }
`;

export interface ProjectDetailDrawerProps {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProject: Project;
  setProject: (project: Project | undefined) => void;
}

export default function ProjectDetailDrawer({
  setShowSidebar,
  selectedProject,
  setProject,
}: ProjectDetailDrawerProps) {
  const { id: projectId } = selectedProject;
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    variables: { projectId },
    refetchQueries: [{ query: GET_PROJECTS }],
  });

  const { data: drawerDetail, refetch } =
    useSuspenseQuery<ProjectDetailDrawerQueryQuery>(GET_PROJECT, {
      variables: {
        projectId,
        targetQueryMutation: 'deleteProjectGovernanceFramework',
        action: 'mutation',
      },
    });

  const [deleteProjectGovernanceFramework] = useMutation(
    DELETE_PROJECT_GOVERNANCE_FRAMEWORK
  );

  const handleDeleteProject = async () => {
    try {
      await deleteProject();
      setShowSidebar(false);
      const storedProject = localStorage.getItem('selectedProject');
      const parsedStoredProject = storedProject
        ? JSON.parse(storedProject)
        : undefined;
      if (parsedStoredProject.id === projectId) {
        setProject(undefined);
        localStorage.removeItem('selectedProject');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const confirmDelete = async (
    materializedFrameworkId: string,
    projectId: string
  ) => {
    if (!materializedFrameworkId) return;

    try {
      const { data } = await deleteProjectGovernanceFramework({
        variables: { projectId, materializedFrameworkId },
      });

      const message = data.deleteProjectGovernanceFramework.success
        ? 'Successfully deleted project governance framework'
        : 'Failed to delete project governance framework';

      toast[
        data.deleteProjectGovernanceFramework.success ? 'success' : 'error'
      ](message);

      await refetch();
    } catch (error) {
      console.error('Error deleting project governance framework:', error);
      toast.error('Failed to delete project governance framework');
    } finally {
      setDeleteConfirmOpen(false);
    }
  };

  const handleExport = async (format: string) => {
    try {
      const res = await generateLink(`export_project_${format}/${projectId}`);
      const data = await res.blob();
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `project_${projectId}.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      toast.error('An error occurred while exporting the project.');
      console.error(error);
    }
  };
  const { data: allUsersUnsorted } =
    useSuspenseQuery<GetAllUsersForSelectQuery>(GET_USERS);

  const [responsiblePerson, setResponsiblePerson] = useState<string>(
    drawerDetail!.project?.responsible?.id ?? ''
  );
  const [stage, setStage] = useState<Stage>(
    drawerDetail?.project?.stage ?? Stage.Develop
  );
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [riskClassCategory, setRiskClassCategory] = useState<
    RiskClassCategoryEnum | undefined
  >(drawerDetail?.project?.riskClass?.riskClassCategory ?? undefined);
  const [riskClassRole, setRiskClassRole] = useState<
    RiskClassRoleEnum | undefined
  >(drawerDetail?.project?.riskClass?.riskClassRole ?? undefined);
  const [department, setDepartment] = useState<string>(
    drawerDetail?.project?.department ?? ''
  );
  const [limitations, setLimitations] = useState(
    (drawerDetail?.project?.limitations as string[]) ?? []
  );
  const [title, setTitle] = useState(drawerDetail?.project?.title);
  const [intendedUseCases, setIntendedUseCases] = useState(
    (drawerDetail?.project?.intendedUseCases as string[]) ?? []
  );

  const [dataSource, setDataSource] = useState<string>(
    drawerDetail?.project?.dataSource ?? ''
  );

  const [description, setDescription] = useState(
    drawerDetail?.project?.description
  );

  const [usagePolicy, setUsagePolicy] = useState<string>(
    drawerDetail?.project?.usagePolicy ?? ''
  );
  const [tab, setTab] = useState('0');
  const metrics =
    drawerDetail.project?.mostRecentExperiment?.instanceRuns?.[0]?.metrics;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  let mostRecentExperimentMetricName = 'n/a';
  let mostRecentExperimentMetricValue = 'n/a';
  if (metrics && Object.keys(metrics).length > 0) {
    mostRecentExperimentMetricName = Object.keys(metrics)[0];
    mostRecentExperimentMetricValue = metrics[mostRecentExperimentMetricName]
      .toString()
      .substring(0, 4);
  }

  const risksWithoutTypename = (drawerDetail?.project?.risks ?? [])
    .filter((row): row is Risk => row !== null)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ __typename, ...rest }) => ({
      ...rest,
      id: uuidv4(),
    })) as Risk[]; // thank appollo for adding this field

  const recommendedGovernanceFrameworks =
    drawerDetail?.project?.recommendedGovernanceFrameworks?.filter(
      (framework): framework is ProjectGovernanceFrameworkTemplate =>
        framework !== null
    );

  const [risks, setRisks] = useState<Risk[]>(risksWithoutTypename);

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      limitations,
      dataSource,
      stage,
      responsiblePerson,
      intendedUseCases,
      description,
      department,
      risks,
      usagePolicy,
      title,
      riskClass: {
        riskClassCategory,
        riskClassRole,
      },
      targetQueryMutation: 'deleteProjectGovernanceFramework',
      action: 'mutation',
    },
    update(cache, { data }) {
      if (!data?.updateProject?.project) return;
      const updatedProject = {
        ...data.updateProject.project,
        __typename: 'Project',
        intendedUseCases: data.updateProject.project.intendedUseCases || [],
        limitations: data.updateProject.project.limitations || [],
        risks: data.updateProject.project.risks || [],
      };

      cache.writeQuery({
        query: GET_PROJECT,
        variables: { projectId },
        data: {
          project: updatedProject,
          __typename: 'Query',
        },
      });

      const existingData = cache.readQuery({ query: GET_PROJECTS });
      if (existingData?.allProjects) {
        cache.writeQuery({
          query: GET_PROJECTS,
          data: {
            allProjects: existingData.allProjects.map(project =>
              project?.id === projectId ? updatedProject : project
            ),
            __typename: 'Query',
          },
        });
      }
    },
    onError: error => {
      toast.error('Failed to update project');
      console.error('Update project error:', error);
    },
  });

  const submitUpdateProject = async () => {
    try {
      await updateProject();
      toast.success("Successfully updated project's details");
    } catch (error) {
      console.error('Error updating project:', error);
      toast.error('Project update was not successful');
    }
  };

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] =
    useState<HTMLElement | null>(null);
  const open = Boolean(moreOptionsAnchorEl);

  const handleMoreOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setMoreOptionsAnchorEl(null);
  };
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const [isSettingsModalOpen, setIsSettingsModalOpen] =
    useState<boolean>(false);
  const [isGovernanceFrameworkModelOpen, setIsGovernanceFrameworkModelOpen] =
    useState<boolean>(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const showConcernDialog = () => {
    setOpenReportDialog(true);
  };
  const { data } =
    useSuspenseQuery<GetCurrentUserForConcernReportQuery>(GET_CURRENT_USER);
  const currentUser = data?.currentUser;
  const pgfDeletePerms =
    drawerDetail.project?.checkPermission?.hasPermission ?? false;

  const resetState = () => {
    setTitle(drawerDetail?.project?.title ?? '');
    setResponsiblePerson(drawerDetail!.project?.responsible?.id ?? '');
    setStage(drawerDetail?.project?.stage ?? Stage.Develop);
    setDepartment(drawerDetail?.project?.department ?? '');
    setLimitations((drawerDetail?.project?.limitations as string[]) ?? []);
    setIntendedUseCases(
      (drawerDetail?.project?.intendedUseCases as string[]) ?? []
    );
    setDataSource(drawerDetail?.project?.dataSource ?? '');
    setDescription(drawerDetail?.project?.description ?? '');
    setUsagePolicy(drawerDetail?.project?.usagePolicy ?? '');
    setRisks(risksWithoutTypename);
    setRiskClassCategory(
      drawerDetail?.project?.riskClass?.riskClassCategory ?? undefined
    );
    setRiskClassRole(
      drawerDetail?.project?.riskClass?.riskClassRole ?? undefined
    );
  };

  return (
    <>
      <Grid
        sx={{
          width: '100%',
          py: '30px',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Box sx={{ px: '30px', width: '100%' }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'space-between', pb: '10px' }}
          >
            <Box sx={{ flexGrow: 1, minWidth: 0, mr: 2 }}>
              <EditableTypography
                variant="h4"
                multiline={false}
                isEditable={isEditable}
                onChange={e => setTitle(e as string)}
              >
                {title}
              </EditableTypography>
            </Box>
            <Box display="flex" marginLeft="auto" gap={2}>
              <Button
                onClick={() => {
                  setProject(selectedProject);
                  setShowSidebar(false);
                }}
                variant="contained"
                startIcon={
                  <CheckIcon style={{ color: colorThemes.DARK_BLUE_800 }} />
                }
              >
                Select as current project
              </Button>
              <MenuButton
                variant="contained"
                startIcon={
                  <EditIcon style={{ color: colorThemes.DARK_BLUE_800 }} />
                }
                label={isEditable ? 'Cancel' : 'Edit'}
                onClick={() => {
                  if (isEditable) {
                    resetState();
                  }
                  setIsEditable(!isEditable);
                }}
                items={[]}
              />
              {isEditable && (
                <MenuButton
                  variant="contained"
                  startIcon={
                    <SaveIcon style={{ color: colorThemes.DARK_BLUE_800 }} />
                  }
                  label={'Save'}
                  onClick={() => {
                    submitUpdateProject();
                    setIsEditable(false);
                  }}
                  items={[]}
                />
              )}

              <Button
                aria-controls={open ? 'simple-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                startIcon={<MoreVertIcon sx={{ color: '#000' }} />}
                onClick={handleMoreOptionsClick}
              >
                more options
              </Button>
            </Box>

            <Box
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Menu
                id="project-menu"
                anchorEl={moreOptionsAnchorEl}
                open={open}
                onClose={handleMoreOptionsClose}
                MenuListProps={{
                  'aria-labelledby': 'more-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDeleteProject();
                    handleMoreOptionsClose();
                  }}
                >
                  <TrashIcon />
                  Delete Project
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    showConcernDialog();
                  }}
                >
                  <FlagIcon />
                  report a concern
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExport('json');
                    handleMoreOptionsClose();
                  }}
                >
                  <IosShareIcon />
                  Export as .json
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSettingsModalOpen(true);
                    handleMoreOptionsClose();
                  }}
                >
                  <SettingsIcon />
                  Settings
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Typography
            align="left"
            variant="body2"
            color="text.secondary"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              copyToClipboard(projectId, 'Project ID copied to clipboard!')
            }
          >
            Project ID: {projectId}
          </Typography>
        </Box>
      </Grid>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: 'white' }} />
      <TabContext value={tab}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
            }}
          >
            <Tab
              label="User Facing"
              id="user-facing-tab"
              value={'0'}
              sx={{ flexGrow: 1 }}
            />
            <Tab
              label="Development"
              id="development-tab"
              value={'1'}
              sx={{ flexGrow: 1 }}
            />
            <Tab
              label="AI Policy"
              id="ai-policy-tab"
              value={'2'}
              sx={{ flexGrow: 1 }}
            />
            <Tab
              label="Project Governance"
              id="project-gov-tab"
              value={'3'}
              sx={{ flexGrow: 1 }}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={'0'}
          sx={{ height: '100%', overflow: 'auto', pb: '20px' }}
        >
          <Box
            sx={{
              marginLeft: '20px',
              marginRight: '20px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ display: 'flex', alignItems: 'stretch' }}
            >
              <Grid item xs={3} sm={3} height={'270px'}>
                <ProjectGridItem
                  title="Stage"
                  content={
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CheckCircleIcon />
                      <FormControl fullWidth variant="filled">
                        <Select
                          value={stage}
                          label="Current Stage"
                          onChange={event =>
                            setStage(event.target.value as Stage)
                          }
                          disabled={!isEditable}
                          inputProps={{ 'aria-label': 'Without label' }}
                          sx={{
                            width: '100%',
                            pt: '0px',
                            pb: '4px',
                            '& .MuiSelect-select': {
                              whiteSpace: 'normal',
                            },
                            '&.Mui-disabled': {
                              '& .MuiSelect-select': {
                                WebkitTextFillColor: 'rgb(255,255,255)',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                pl: '16%',
                                pt: '10px',
                                pb: '6px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '& .MuiSvgIcon-root': {
                                display: 'none',
                              },
                            },
                          }}
                        >
                          {Object.values(Stage).map((stageName, stageIndex) => (
                            <MenuItem key={stageIndex} value={stageName}>
                              {stageName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} height={'270px'}>
                <ProjectGridItem
                  title="Responsible"
                  content={
                    <TableContainer sx={{ overflow: 'hidden' }}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              Department
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              <TextField
                                variant="standard"
                                size="medium"
                                sx={{ borderBottom: '1px solid' }}
                                value={department}
                                onChange={e => setDepartment(e.target.value)}
                                InputProps={{
                                  readOnly: !isEditable,
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              Responsible Person
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              <FormControl variant="filled">
                                <Select
                                  value={responsiblePerson}
                                  label="Responsible Person"
                                  onChange={event =>
                                    setResponsiblePerson(
                                      event.target.value as string
                                    )
                                  }
                                  disabled={!isEditable}
                                  sx={{
                                    width: '100%',
                                    pt: '0px',
                                    pb: '4px',
                                    px: '4px',
                                    '& .MuiSelect-select': {
                                      whiteSpace: 'normal',
                                    },
                                    '&.Mui-disabled': {
                                      '& .MuiSelect-select': {
                                        WebkitTextFillColor: 'rgb(255,255,255)',
                                        whiteSpace: 'nowrap',
                                        pl: '2px',
                                        pt: '10px',
                                        pb: '6px',
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        display: 'none',
                                      },
                                    },
                                  }}
                                >
                                  {(allUsersUnsorted?.allUsers ?? []).map(
                                    user => (
                                      <MenuItem key={user?.id} value={user?.id}>
                                        <UserTag
                                          key={user?.id}
                                          user={user ?? undefined}
                                          variant={'text'}
                                          size={'medium'}
                                        />
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                />
              </Grid>
              <Grid
                item
                display={'flex'}
                xs={6}
                sm={5}
                gap={'20px'}
                flexDirection={'column'}
              >
                <Box display={'flex'} gap={'20px'} height={'100%'}>
                  <ProjectGridItem
                    title="Role"
                    content={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '50px',
                        }}
                      >
                        <RiskClassTag
                          riskClassRole={riskClassRole}
                          isEditable={isEditable}
                          setRiskClassRole={setRiskClassRole}
                        />
                      </Box>
                    }
                  />
                  <ProjectGridItem
                    title="Risk Class"
                    content={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '50px',
                        }}
                      >
                        <RiskClassTag
                          riskClassCategory={riskClassCategory}
                          riskClassRole={riskClassRole}
                          isEditable={isEditable}
                          setRiskClassCategory={setRiskClassCategory}
                        />
                      </Box>
                    }
                  />
                </Box>
                {isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '95%',
                      p: 1,
                      bgcolor: '#001529',
                      borderRadius: theme.spacing(1),
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      component={Link}
                      to={`/risk_classification/${projectId}`}
                    >
                      {riskClassRole
                        ? 'Redo Classification Questionnaire'
                        : 'Do Classification Questionnaire'}
                    </Button>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} height={'200px'}>
                <ProjectGridItem
                  title="Description"
                  content={
                    <EditableTypography
                      isEditable={isEditable}
                      onChange={e => setDescription(e as string)}
                    >
                      {description}
                    </EditableTypography>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <ProjectGridItem
                  title="Intended use cases"
                  content={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                      }}
                    >
                      <StackEditor
                        stacks={intendedUseCases}
                        isEditable={isEditable}
                        setStacks={setIntendedUseCases}
                      />
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <ProjectGridItem
                  title="Usage Policy"
                  content={
                    <EditableTypography
                      isEditable={isEditable}
                      onChange={e => setUsagePolicy(e as string)}
                    >
                      {usagePolicy}
                    </EditableTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} minHeight={'150px'} mb={'40px'}>
                <ProjectGridItem
                  title="Limitations"
                  content={
                    <StackEditor
                      stacks={limitations}
                      isEditable={isEditable}
                      setStacks={setLimitations}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={'1'}>
          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', alignItems: 'stretch' }}
          >
            <Grid item xs={3} sm={3} height={'200px'}>
              <ProjectGridItem
                title="Stage"
                content={
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CheckCircleIcon />
                    <FormControl fullWidth variant="filled">
                      <Select
                        value={stage}
                        label="Current Stage"
                        onChange={event =>
                          setStage(event.target.value as Stage)
                        }
                        disabled={!isEditable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          width: '100%',
                          pt: '0px',
                          pb: '4px',
                          '& .MuiSelect-select': {
                            whiteSpace: 'normal',
                          },
                          '&.Mui-disabled': {
                            '& .MuiSelect-select': {
                              WebkitTextFillColor: 'rgb(255,255,255)',
                              whiteSpace: 'nowrap',
                              textAlign: 'center',
                              pl: '16%',
                              pt: '10px',
                              pb: '6px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiSvgIcon-root': {
                              display: 'none',
                            },
                          },
                        }}
                      >
                        {Object.values(Stage).map((stageName, stageIndex) => (
                          <MenuItem key={stageIndex} value={stageName}>
                            {stageName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ProjectGridItem
                title="Last Experiment"
                content={
                  <Box sx={{ width: '100%' }}>
                    <Typography variant="h4">
                      {mostRecentExperimentMetricValue}
                    </Typography>
                    <Typography>{mostRecentExperimentMetricName}</Typography>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ProjectGridItem
                title="Documentation"
                content={
                  <Box sx={{ width: '100%' }}>
                    <Typography variant="h4">
                      {drawerDetail?.project?.numberOfDocumentations}
                    </Typography>
                    <Typography>Documentations</Typography>
                  </Box>
                }
                icon={
                  <RouterLink to={`/documentation/${projectId}`}>
                    <ArrowOutwardIcon />
                  </RouterLink>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ProjectGridItem
                title="Audit Trail"
                content={
                  <Box sx={{ width: '100%' }}>
                    <Typography variant="h4">
                      {drawerDetail?.project?.numberOfExperiments}
                    </Typography>
                    <Typography>Experiments</Typography>
                  </Box>
                }
                icon={
                  <RouterLink to={`/trail_graph/${projectId}/`}>
                    <ArrowOutwardIcon />
                  </RouterLink>
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ProjectGridItem
                key={drawerDetail?.project?.remoteGitSync?.id}
                title="Git Integration"
                content={
                  <RemoteGitSyncForm
                    projectId={projectId}
                    initialData={
                      drawerDetail?.project?.remoteGitSync as RemoteGitSync
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} height={'200px'}>
              <ProjectGridItem
                title="Data Source"
                content={
                  <EditableTypography
                    isEditable={isEditable}
                    onChange={e => setDataSource(e as string)}
                  >
                    {dataSource}
                  </EditableTypography>
                }
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={'2'}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '50vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Divider
                orientation="vertical"
                style={{
                  backgroundColor: 'white',
                  height: '50px',
                  width: '4px',
                }}
              />
              No Policy created yet
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={'3'}>
          <Grid item xs={1} sm={1}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="h5">
                Project Governance Frameworks
              </Typography>
              {drawerDetail?.project?.projectGovernanceFrameworks?.map(
                frameworkData => {
                  return (
                    <Fragment key={frameworkData?.id}>
                      <Dialog
                        open={deleteConfirmOpen}
                        onClose={() => setDeleteConfirmOpen(false)}
                      >
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to delete this project
                            governance framework? This action cannot be undone.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setDeleteConfirmOpen(false)}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              confirmDelete(frameworkData?.id ?? '', projectId);
                              setDeleteConfirmOpen(false);
                            }}
                            color="error"
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <ProjectGridItem
                        title=""
                        content={
                          <Box
                            sx={{
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <RouterLink
                              to={`/project_governance/${projectId}/${frameworkData?.id}`}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                display: 'block',
                                height: '100%',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  style={{ marginBottom: '4px' }}
                                >
                                  {frameworkData?.name}
                                </Typography>
                                <Typography>{frameworkData?.id}</Typography>
                              </Box>
                            </RouterLink>
                          </Box>
                        }
                        icon={
                          <ButtonGroup>
                            <Tooltip
                              title={
                                !pgfDeletePerms
                                  ? 'Must be an admin or manager to perform this action.'
                                  : ''
                              }
                            >
                              <span>
                                {pgfDeletePerms && (
                                  <TrashIcon
                                    onClick={() => setDeleteConfirmOpen(true)}
                                    sx={{
                                      color: theme.palette.error.dark,
                                      '&:hover': {
                                        color: theme.palette.error.main,
                                        cursor: 'pointer',
                                      },
                                      transition: 'color 0.3s',
                                      marginRight: '5px',
                                      marginTop: '5px',
                                    }}
                                  />
                                )}
                              </span>
                            </Tooltip>
                          </ButtonGroup>
                        }
                      />
                    </Fragment>
                  );
                }
              )}

              <AddNewItemCard
                onClick={() => {
                  setIsGovernanceFrameworkModelOpen(true);
                  handleMoreOptionsClose();
                }}
                sx={{
                  width: '100%',
                  height: '100%',
                  marginTop: '15px',
                }}
              />
              <NewGovernanceFrameworkModal
                open={isGovernanceFrameworkModelOpen}
                onClose={() => setIsGovernanceFrameworkModelOpen(false)}
                projectId={projectId}
                recommendedGovernanceFrameworks={
                  recommendedGovernanceFrameworks ?? []
                }
              />
            </Box>
          </Grid>
        </TabPanel>
      </TabContext>
      <ToastContainer />
      <ProjectSettingsModal
        open={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        projectId={projectId}
      />
      <ReportConcernDialog
        open={openReportDialog}
        getModelName={title}
        getPerson={currentUser?.email || ''}
        onClose={() => setOpenReportDialog(false)}
      />
    </>
  );
}

export function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${window._env_.AUTH_URL}:trail-lux`
  );
  return oidcStorage ? JSON.parse(oidcStorage) : null;
}

const generateLink = async (link: string) => {
  const token = getUser()?.access_token;
  const requestOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const uri = import.meta.env.REACT_APP_TERRA_URL || 'http://localhost:5002';
  const res = await fetch(`${uri}/${link}`, requestOptions);

  return res;
};

export default generateLink;

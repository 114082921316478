import {
  Step,
  StepLabel,
  Stepper,
  StepProps,
  StepLabelProps,
  StepperProps,
} from '@mui/material';

interface SignUpStepperProps {
  step: number;
  stepLabels: string[];
  skippedSteps?: number[];
  stepperProps?: StepperProps;
  stepProps?: StepProps;
  stepLabelProps?: StepLabelProps;
  highlightSkippedSteps?: boolean;
}

export default function HorizontalLinearStepper({
  step,
  stepLabels,
  skippedSteps = [],
  stepperProps,
  stepProps,
  stepLabelProps,
  highlightSkippedSteps = true,
}: SignUpStepperProps) {
  if (step >= stepLabels.length) {
    step = stepLabels.length - 1;
  }
  return (
    <Stepper
      activeStep={step}
      alternativeLabel
      sx={{ marginBottom: '2rem', width: '100%' }}
      {...stepperProps}
    >
      {stepLabels.map((label, index) => (
        <Step
          {...stepProps}
          key={label}
          active={
            step === index ||
            (highlightSkippedSteps &&
              skippedSteps.includes(index) &&
              index < step)
          }
          completed={!skippedSteps.includes(index) && index < step}
        >
          <StepLabel {...stepLabelProps}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

import React from 'react';
import { colorThemes, riskColors } from '../../theme';
import { Box, Typography } from '@mui/material';

interface RiskOverviewProps {
  criticalRisks: number;
  majorRisks: number;
  seriousRisks: number;
  moderateRisks: number;
  selectedForTreatment: number;
  totalRisks: number;
}

const Dot: React.FC<{ visible: boolean; color: string }> = ({
  visible,
  color,
}) => (
  <Box
    component="span"
    sx={{
      width: '10px',
      height: '10px',
      backgroundColor: visible ? color : 'transparent',
      borderRadius: '50%',
      display: 'inline-block',
    }}
  />
);

const RiskOverview: React.FC<RiskOverviewProps> = ({
  criticalRisks,
  majorRisks,
  seriousRisks,
  moderateRisks,
  selectedForTreatment,
  totalRisks,
}) => {
  const risks = [
    {
      type: 'Critical Risks',
      count: criticalRisks,
      color: riskColors.critical,
    },
    { type: 'Major Risks', count: majorRisks, color: riskColors.major },
    { type: 'Serious Risks', count: seriousRisks, color: riskColors.serious },
    {
      type: 'Moderate Risks',
      count: moderateRisks,
      color: riskColors.moderate,
    },
  ];

  return (
    <Box
      gridColumn="span 3"
      sx={{ backgroundColor: colorThemes.DARK_BLUE_600 }}
      borderRadius="8px"
      padding="24px"
      color="white"
      display="flex"
      flexDirection="column"
      gap="8px"
    >
      {risks.map((risk, index) => (
        <Box key={index} display="flex" alignItems="center" gap="8px">
          <Dot visible={risk.count > 0} color={risk.color} />
          <Typography variant="body1">{`${risk.count}/${totalRisks} ${risk.type}`}</Typography>
        </Box>
      ))}
      <Box paddingTop="20px">
        <Typography variant="body1">{`Treatments Selected: ${selectedForTreatment}/${totalRisks}`}</Typography>
      </Box>
    </Box>
  );
};

export default RiskOverview;

import { useRef } from 'react';
import { Card, CardContent, Modal, Box, Button } from '@mui/material';
import ExperimentTableHeaderEditor, {
  Header,
} from './ExperimentTableHeaderEditor';

interface HeaderEditorModalProps {
  headers: Header[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (_: Header[]) => void;
}

export default function HeaderEditorModal({
  headers,
  isOpen,
  onClose,
  onSubmit,
}: HeaderEditorModalProps) {
  const editorHeadersRef = useRef([] as Header[]);
  const setEditorHeaders = (updatedHeaders: Header[]) => {
    editorHeadersRef.current = updatedHeaders;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '90vh',
        margin: '30px',
      }}
    >
      <Card sx={{ minWidth: 600, backgroundColor: '#0c2238' }}>
        <CardContent>
          <Box display="flex" flexDirection="column" gap="20px">
            <ExperimentTableHeaderEditor
              headers={headers}
              setEditorHeaders={setEditorHeaders}
            />
            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              justifyContent="space-between"
            >
              <Button variant="contained" onClick={onClose}>
                CLOSE
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onSubmit(editorHeadersRef.current);
                  onClose();
                }}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}

import { Box } from '@mui/material';
import { FC } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { colorThemes } from '../theme';

interface QuestionnaireAnswerCheckboxProps {
  option: { name: string; value: number };
  handleCheckboxValue: (value: number) => void;
  isChecked: boolean;
}

const QuestionnaireAnswerCheckbox: FC<QuestionnaireAnswerCheckboxProps> = ({
  option,
  handleCheckboxValue,
  isChecked,
}) => {
  const handleChange = () => {
    handleCheckboxValue(option.value);
  };

  return (
    <Box
      border={2}
      borderColor={
        isChecked ? colorThemes.ACTION_BLUE : colorThemes.DARK_BLUE_100
      }
      pl={3}
      component={'label'}
      height="120px"
      display="flex"
      alignItems="center"
      justifyContent="left"
      gap={3}
      borderRadius={5}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          background: 'rgba(0, 123, 255, 0.2)',
        },
      }}
    >
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: isChecked ? colorThemes.ACTION_BLUE : 'transparent',
          border: `2px solid ${colorThemes.DARK_BLUE_100}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0px',
          position: 'relative',
        }}
      >
        <Box
          component="input"
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          sx={{
            width: '100%',
            height: '100%',
            opacity: 0,
            position: 'absolute',
            cursor: 'pointer',
            margin: 0,
            padding: 0,
          }}
        />
        {isChecked && (
          <CheckIcon
            sx={{
              color: '#fff',
              fontSize: '18px',
            }}
          />
        )}
      </Box>
      <Box
        width={'85%'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        component="span"
        color="white"
        sx={{
          fontSize: {
            xs: '12px',
            lg: '15px',
          },
        }}
      >
        {option.name}
      </Box>
    </Box>
  );
};

export default QuestionnaireAnswerCheckbox;

import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, CardContent, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { GET_DETAILS } from '../DashboardDetails';
import 'react-toastify/dist/ReactToastify.css';

const EDIT_TITLE = gql(`
mutation update_project_title($projectId: String!, $title: String) {
  updateProject(projectId: $projectId, title: $title) {
    project {
      id
    }
  }
}
`);

interface Props {
  title: string;
  projectID: string;
  onClose: () => void;
}
export default function EditProjectTitle({ title, projectID, onClose }: Props) {
  const [commitTitleChange] = useMutation(EDIT_TITLE, {
    refetchQueries: [
      { query: GET_DETAILS, variables: { projectId: projectID } },
    ],
  });
  const [projectTitle, setProjectTitle] = useState(title);
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectTitle(event.target.value);
  };

  return (
    <Card sx={{ width: '40%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            label="Project Title"
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            value={projectTitle}
            onChange={handleTitle}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button sx={{ color: 'red' }} onClick={onClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                commitTitleChange({
                  variables: {
                    projectId: projectID,
                    title: projectTitle,
                  },
                  onCompleted: () => {
                    onClose();
                  },
                  onError: () => {
                    toast.error('Error saving changes.', {
                      position: 'top-center',
                      autoClose: 2500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: 'dark',
                    });
                  },
                });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CardContent>
      <ToastContainer />
    </Card>
  );
}

import { Grid } from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import NumberDisplay from '../../risk_management/dashboards/NumberDisplay';
import { GetGovernanceFrameworkDashboardQuery } from '../../__generated__/gql/graphql';
import RequirementStatusDistributionChart from './RequirementStatusDistributionChart';

const GET_RISK_ASSESSMENT_DASHBOARD = gql(`
 query getGovernanceFrameworkDashboard {
  governanceFrameworksDashboard {
    numEvidence
    numAssignedReq
    requirementStageStatistics {
      numNotStarted
      numAssigned
      numCompleted
      numApproved
    }
  }
}
`);

export default function GovernanceFrameworkDashboard() {
  const { data } = useSuspenseQuery<GetGovernanceFrameworkDashboardQuery>(
    GET_RISK_ASSESSMENT_DASHBOARD
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <RequirementStatusDistributionChart
          data={data.governanceFrameworksDashboard}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberDisplay
          title="Number of Evidences"
          value={data.governanceFrameworksDashboard?.numEvidence || 0}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberDisplay
          title="Number of Assigned Requirements"
          value={data.governanceFrameworksDashboard?.numAssignedReq || 0}
        />
      </Grid>
    </Grid>
  );
}

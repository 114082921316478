import { Box, Button, Grid, Typography } from '@mui/material';
import SidebarContentField from '../../../components/SidebarContentField';
import { gql, useSuspenseQuery } from '@apollo/client';
import { GetEvaluationsQuery } from '../../../__generated__/gql/graphql';
import EvaluationCard from './AutomatedEvaluationCard';
import ManualEvaluationCard from './ManualEvaluationCard';
import AddEvaluationModal from './AddEvaluationModal';
import { useState } from 'react';

const GET_EVALUATION_QUERY = gql(`
query getEvaluations($experimentId: String!) {
  experiment(id: $experimentId) {
    id
    manualEvaluations {
      id
      name
      description
      mostRecentResult {
        id
        progress
        outcome
        reason
        sources
        startTime
        endTime
      }
    }
    evaluationStatistics{
      total
      successful
      failed
      notApplicable
      inProgress
      notStarted
    }
    evaluations {
      id
      name
      description
      mostRecentResult {
        id
        progress
        outcome
        reason
        sources
        startTime
        endTime
      }
    } 
  }
}
`);

export interface EvaluationOverviewProps {
  experimentId: string;
}

export default function EvaluationOverview({
  experimentId,
}: EvaluationOverviewProps) {
  const { data: evaluations } = useSuspenseQuery<GetEvaluationsQuery>(
    GET_EVALUATION_QUERY,
    {
      variables: {
        experimentId,
      },
    }
  );
  const stats = evaluations?.experiment?.evaluationStatistics;
  const total = stats?.total || 0;

  const notStartedOrAborted = total - (stats!.successful! + stats!.failed!);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box display={'flex'} gap={'20px'} flexDirection={'column'}>
      <SidebarContentField title={'Overview'}>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item xs>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h6"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                {stats?.successful} / {total}
              </Typography>
              <Typography>passed</Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h6"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                {stats?.failed} / {total}
              </Typography>
              <Typography>failed</Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h6"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                {notStartedOrAborted} / {total}
              </Typography>
              <Typography>not started/aborted</Typography>
            </Box>
          </Grid>
        </Grid>
      </SidebarContentField>
      <SidebarContentField title={'Automated Evaluations'}>
        <Box display={'flex'} gap={'20px'} flexWrap={'wrap'}>
          {(evaluations?.experiment?.evaluations ?? []).map(evaluation => (
            <EvaluationCard
              key={evaluation!.id}
              evaluation={evaluation!}
              experimentId={experimentId}
              queryCacheUpdate={GET_EVALUATION_QUERY}
            />
          ))}
        </Box>
      </SidebarContentField>
      <SidebarContentField title={'Manual Evaluations'}>
        <Box display={'flex'} gap={'20px'} flexWrap={'wrap'}>
          {(evaluations?.experiment?.manualEvaluations ?? []).map(
            evaluation => (
              <ManualEvaluationCard
                key={evaluation!.id}
                evaluation={evaluation!}
                experimentId={experimentId}
                queryCacheUpdate={GET_EVALUATION_QUERY}
              />
            )
          )}
        </Box>
      </SidebarContentField>

      <Button onClick={() => setIsModalOpen(true)}>Add Evaluation</Button>
      <AddEvaluationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        experimentId={experimentId}
      />
    </Box>
  );
}

import { Box } from '@mui/material';
import { useState } from 'react';
import AtlassianAuthModal from '../components/AtlassianAuthModal';
import { IntegrationButton } from './IntegrationButton';
import IMAGES from '../images/Images';

export interface ProjectIntegrationsProps {
  projectID: string;
}

export default function ProjectIntegrations({
  projectID,
}: ProjectIntegrationsProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-around">
        <IntegrationButton
          logo={IMAGES.atlassianLogo}
          label="Atlassian"
          action={handleOpenModal}
        />
        <IntegrationButton logo={IMAGES.mlFlowLogo} label="Mlflow" />
        <IntegrationButton logo={IMAGES.gdriveLogo} label="GDrive" />
      </Box>
      <AtlassianAuthModal
        open={isModalOpen}
        onClose={handleCloseModal}
        projectID={projectID}
      />
    </>
  );
}

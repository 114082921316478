import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { EnabledFeatures, enabledFeaturesAtom } from '../EnabledFeatures';
import { toast } from 'react-toastify';

const UPDATE_ENABLED_FEATURES = gql`
  mutation UpdateOrganizationEnabledFeatures(
    $showFrameworkManagement: Boolean
    $sidebarShowCompliance: Boolean
    $sidebarShowDevelop: Boolean
    $sidebarShowQuestionnaire: Boolean
    $sidebarShowReports: Boolean
    $sidebarShowRiskManagement: Boolean
    $sidebarShowRolesAndPermissions: Boolean
  ) {
    updateOrganizationEnabledFeatures(
      showFrameworkManagement: $showFrameworkManagement
      sidebarShowCompliance: $sidebarShowCompliance
      sidebarShowDevelop: $sidebarShowDevelop
      sidebarShowQuestionnaire: $sidebarShowQuestionnaire
      sidebarShowReports: $sidebarShowReports
      sidebarShowRiskManagement: $sidebarShowRiskManagement
      sidebarShowRolesAndPermissions: $sidebarShowRolesAndPermissions
    ) {
      organization {
        enabledFeatures {
          showFrameworkManagement
          sidebarShowCompliance
          sidebarShowReports
          sidebarShowQuestionnaire
          sidebarShowRolesAndPermissions
          sidebarShowDevelop
          sidebarShowRiskManagement
        }
      }
    }
  }
`;

const featureDisplayNames = {
  showFrameworkManagement: 'Framework Management',
  sidebarShowCompliance: 'Compliance',
  sidebarShowReports: 'Reports',
  sidebarShowQuestionnaire: 'Questionnaire',
  sidebarShowRolesAndPermissions: 'Roles and Permissions',
  sidebarShowDevelop: 'Development',
  sidebarShowRiskManagement: 'Risk Management',
};

const FeatureToggleManagement: React.FC = () => {
  const [enabledFeatures, setEnabledFeatures] =
    useRecoilState(enabledFeaturesAtom);
  const [updateEnabledFeatures] = useMutation(UPDATE_ENABLED_FEATURES);

  const handleToggle = async (feature: keyof EnabledFeatures) => {
    const updatedFeatures = {
      ...enabledFeatures,
      [feature]: !enabledFeatures[feature],
    };
    try {
      await updateEnabledFeatures({
        variables: updatedFeatures,
      });
      setEnabledFeatures(updatedFeatures);
      const action = updatedFeatures[feature] ? 'enabled' : 'disabled';
      const featureName = featureDisplayNames[feature] || feature;
      toast.success(`${featureName} ${action} successfully`, {
        autoClose: 1000,
      });
    } catch (error) {
      console.error('Error updating feature toggle:', error);
      toast.error(
        `Failed to update ${featureDisplayNames[feature] || feature}`,
        { autoClose: 3000 }
      );
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Features
        </Typography>
        <FormGroup>
          {Object.entries(enabledFeatures).map(([feature, enabled]) => (
            <FormControlLabel
              key={feature}
              control={
                <Switch
                  checked={enabled}
                  onChange={() =>
                    handleToggle(feature as keyof EnabledFeatures)
                  }
                  name={feature}
                />
              }
              label={
                featureDisplayNames[feature as keyof EnabledFeatures] || feature
              }
            />
          ))}
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default FeatureToggleManagement;

import { useQuery, useSuspenseQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import ProjectDetailCard from './ProjectDetailCard';
import { gql } from '../__generated__/gql';
import ProjectCreationModal from './project_creation_modal/ProjectCreationModal.react';
import { Loading } from '../components/Loading';
import AddNewItemCard from '../components/AddNewItemCard';
import { useProject } from '../contexts/project';
import { useSearchParams } from 'react-router-dom';
import {
  OrganizationDepartmentsQuery,
  Project,
  RiskClassRoleEnum,
  UsersBasicInfoQuery,
} from '../__generated__/gql/graphql';
import ProjectDetailDrawerSuspenseWrapper from './ProjectDetailDrawerSuspenseWrapper';
import { ToastContainer } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import ProjectFilter, {
  filterProjects,
  ProjectBaseFilter,
  ProjectFilterProps,
} from './ProjectFilter';
import { getRiskClassCategoryText } from '../risk_classification/QuestionnaireResult';

export const GET_PROJECTS = gql(`
  query ProjectView_items {
    allProjects {
      id
      title
      description
      allowedDataTypes
      allowedTaskTypes
      department
      riskClass {
        riskClassCategory
        riskClassRole
      }
      stage
      responsible {
        id
        name
        email
        initials
      }
    }
  }
`);

export const GET_USERS = gql(`
  query UsersBasicInfo{
  allUsers{
    id
    name
    email
  }
}
`);

const GET_DEPARTMENTS = gql(`
  query OrganizationDepartments {
    organization {
      id
      departments
    }
  }`);

export default function ProjectView() {
  const {
    loading: loadingProjects,
    error: errorLoadingProjects,
    data: projectsData,
  } = useQuery(GET_PROJECTS);
  const [params] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [projectFilters, setProjectFilters] =
    useState<ProjectFilterProps>(ProjectBaseFilter);

  const { data: userData } = useSuspenseQuery<UsersBasicInfoQuery>(GET_USERS);
  const { data: departmentsData } =
    useSuspenseQuery<OrganizationDepartmentsQuery>(GET_DEPARTMENTS);

  const selectedProjectDetailsForParam = params.get('projectDetailsFor');

  const projectFromParam = projectsData?.allProjects?.find(
    p => p?.id === selectedProjectDetailsForParam
  ) as Project;

  const [showSidebar, setShowSidebar] = useState(
    !!selectedProjectDetailsForParam
  );
  const [selectedProject, setSelectedProject] = useState<Project>();

  useEffect(() => {
    if (projectFromParam) {
      setSelectedProject(projectFromParam);
      setShowSidebar(true);
    }
  }, [projectFromParam]);

  const { project: currentlyActiveProject, setProject } = useProject();

  const [showCreationModal, setShowCreationModal] = useState(false);
  const filteredProjects =
    projectsData?.allProjects
      ?.filter(projectData =>
        projectData!.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter(projectData =>
        filterProjects({
          responsible: projectData?.responsible?.id,
          stage: projectData?.stage,
          department: projectData?.department,
          riskClass: projectData?.riskClass?.riskClassCategory,
          filters: projectFilters,
        })
      ) || [];

  if (loadingProjects) {
    return <Loading message="Loading projects ..." />;
  }
  if (errorLoadingProjects) {
    return <p>Error :{errorLoadingProjects.message}</p>;
  }
  return (
    <>
      <Box>
        <Typography variant="h4" marginBottom={3}>
          AI Registry
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            gap: '12px',
          }}
        >
          <TextField
            id="search"
            sx={{ width: 700 }}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search for a project ..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <ProjectFilter
            projectFilters={projectFilters}
            setProjectFilters={setProjectFilters}
            userData={userData!}
            departmentsData={departmentsData!}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ minWidth: '1230px', marginBottom: 2 }}>
            <Typography variant="h4">
              {filteredProjects.length === 0
                ? 'No Project'
                : filteredProjects.length > 1
                ? filteredProjects.length + ' Projects'
                : '1 Project'}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          style={{
            gap: '12px',
            justifyContent: 'center',
          }}
        >
          {filteredProjects &&
            filteredProjects.map(projectData => {
              return (
                <ProjectDetailCard
                  key={projectData!.id}
                  id={projectData!.id}
                  department={projectData!.department ?? 'Not Defined'}
                  stage={projectData?.stage}
                  user={projectData?.responsible ?? { name: 'Not Defined' }}
                  riskClass={
                    projectData!.riskClass?.riskClassRole ===
                    RiskClassRoleEnum.OutOfScope
                      ? 'Out of Scope'
                      : projectData!.riskClass?.riskClassCategory === undefined
                      ? 'Not Defined'
                      : getRiskClassCategoryText(
                          projectData!.riskClass.riskClassCategory
                        )
                  }
                  onClick={() => {
                    setSelectedProject(projectData!);
                    setShowSidebar(!showSidebar);
                  }}
                  title={projectData!.title}
                  description={projectData?.description ?? ''}
                  isSelected={currentlyActiveProject?.id === projectData?.id}
                />
              );
            })}
          <AddNewItemCard
            onClick={() => setShowCreationModal(true)}
            sx={{ width: 400, height: 200 }}
          />
          {Array.from({ length: 3 }).map((_, index) => (
            <Card sx={{ width: 400, height: 200, opacity: 0 }} key={index} />
          ))}
          {showCreationModal ? (
            <ProjectCreationModal
              isOpen={showCreationModal}
              onClose={() => {
                setShowCreationModal(false);
              }}
              userData={userData!}
              departmentsData={departmentsData!}
            />
          ) : (
            <></>
          )}
        </Box>
        {selectedProject && (
          <ProjectDetailDrawerSuspenseWrapper
            key={selectedProject.id}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            selectedProject={selectedProject}
            setProject={setProject}
          />
        )}
        <ToastContainer />
      </Box>
    </>
  );
}

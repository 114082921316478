import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { colorThemes } from '../theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  OrganizationObjectiveEnum,
  OrganizationPrincipleEnum,
} from '../__generated__/gql/graphql';

export default function AIObjectivesStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsObjectives,
    append: appendObjective,
    remove: removeObjective,
  } = useFieldArray({
    control,
    name: 'objectives',
  });
  const addFieldObjective = () => {
    appendObjective({ objectiveName: '', description: '', measure: '' });
  };

  const {
    fields: fieldsPrinciples,
    append: appendPrinciple,
    remove: removePrinciple,
  } = useFieldArray({
    control,
    name: 'principles',
  });
  const addFieldPrinciple = () => {
    appendPrinciple({ principleName: '', description: '', measure: '' });
  };

  const switchGroupLabels = [
    {
      name: 'areObjectsConsistent',
      text: 'The objectives are consistent with policy',
    },
    {
      name: 'areObjectsApplicable',
      text: 'The objectives take applicable requirements into account',
    },
    {
      name: 'areObjectsMonitored',
      text: 'The objectives are monitored',
    },
    {
      name: 'areObjectsCommunicated',
      text: 'The ojectives are communicated',
    },
  ];

  return (
    <>
      <Typography variant="h6" marginBottom={'5px'} fontWeight={600}>
        Objectives
      </Typography>
      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
          gap: '4px',
          paddingRight: '1rem',
          backgroundColor: colorThemes.ACTION_BLUE,
        }}
        onClick={addFieldObjective}
      >
        <AddIcon />
        Add Objective
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {fieldsObjectives.map((item, index) => (
          <Grid
            container
            spacing={2}
            marginTop={'1.5rem'}
            key={index + '_objective'}
          >
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Controller
                name={`objectives.${index}.objectiveName`}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel>Objective #{index + 1}</InputLabel>
                    <Select
                      labelId="simple-select-objective"
                      label="Objective200"
                      sx={{ width: '100%' }}
                      value={value}
                      onChange={onChange}
                    >
                      {Object.entries(OrganizationObjectiveEnum).map(
                        ([key, val]) => (
                          <MenuItem key={val} value={val}>
                            {ObjectiveEnumMapping[key] || key}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
                control={control}
                rules={{ required: false }}
              />
              <IconButton
                onClick={() => removeObjective(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <Tooltip
                title={descriptionTooltipText}
                sx={{ position: 'absolute', marginLeft: '-25px' }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
              <TextField
                id="outlined-basic-description"
                label="Description"
                variant="outlined"
                multiline
                rows={5}
                {...register(`objectives.${index}.description`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <Tooltip
                title={measueTooltipText}
                sx={{ position: 'absolute', marginLeft: '-25px' }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
              <TextField
                id="outlined-basic-measure"
                label="Measure"
                variant="outlined"
                {...register(`objectives.${index}.measure`)}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Considerations
      </Typography>
      {switchGroupLabels.map((item, index) => (
        <Box
          sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          key={index + '_' + item.name}
        >
          <Controller
            name={item.name}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
          <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
        </Box>
      ))}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'10px'}
        fontWeight={600}
      >
        Principles
      </Typography>
      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
          gap: '4px',
          paddingRight: '1rem',
          backgroundColor: colorThemes.ACTION_BLUE,
        }}
        onClick={addFieldPrinciple}
      >
        <AddIcon />
        Add Principle
      </Button>
      {fieldsPrinciples.map((item, index) => (
        <Grid
          container
          spacing={2}
          marginTop={'1.5rem'}
          key={index + '_principle'}
        >
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Controller
              name={`principles.${index}.principleName`}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                  <InputLabel>Principle #{index + 1}</InputLabel>
                  <Select
                    labelId="simple-select-objective"
                    label="Objective200"
                    sx={{ width: '100%' }}
                    value={value}
                    onChange={onChange}
                  >
                    {Object.entries(OrganizationPrincipleEnum).map(
                      ([key, val]) => (
                        <MenuItem key={val} value={val}>
                          {PrincipleEnumMapping[key] || key}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
              control={control}
              rules={{ required: false }}
            />
            <IconButton
              onClick={() => removePrinciple(index)}
              sx={{ height: '100%' }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Tooltip
              title={descriptionTooltipText}
              sx={{ position: 'absolute', marginLeft: '-25px' }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
            <TextField
              id="outlined-basic-description"
              label="Description"
              variant="outlined"
              multiline
              rows={5}
              {...register(`principles.${index}.description`)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Tooltip
              title={measueTooltipText}
              sx={{ position: 'absolute', marginLeft: '-25px' }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
            <TextField
              id="outlined-basic-measure"
              label="Measure"
              variant="outlined"
              {...register(`principles.${index}.measure`)}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
}

const descriptionTooltipText =
  'Describe this objective in the context of your organization and how your are planning to achieve it';

const measueTooltipText =
  'How are you planning to achieve this, If possible state a measurable KPI.';

const ObjectiveEnumMapping: { [key: string]: string } = {
  EfficientProcesses: 'Efficient processes',
  TechnicalInnovation: 'Technical Innovation / R&D',
  NewBusiness: 'New business Fields / competitive advantage',
};

const PrincipleEnumMapping: { [key: string]: string } = {
  Accesibility: 'Accesibility',
  Accountability: 'Accountability',
  Explainability: 'Explainability',
  Fairness: 'Fairness',
  PrivacySecurity: 'Privacy & Security',
  Reliability: 'Reliability',
  RobustRedundant: 'Robustness & Redundancy',
  Safety: 'Safety',
  Transparency: 'Transparency',
};

import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useState } from 'react';
import * as React from 'react';

export interface EditableTypographyProps {
  onChange?: (value: string) => void;
  isEditable?: boolean;
  multiline?: boolean;
}

const Styles = {
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
  WebkitFontSmoothing: 'antialiased',
};
const InputBaseWithChildren = ({
  children,
  ...props
}: InputBaseProps & { children?: React.ReactNode }) => {
  let value = '';
  if (children) {
    if (typeof children === 'string' || typeof children === 'number') {
      value = children.toString();
    }
  }

  return (
    <InputBase
      {...props}
      value={value}
      inputProps={{ className: props.className }}
      sx={Styles}
    />
  );
};

const EditableTypography = ({
  onChange: propsOnChange,
  isEditable = true,
  multiline = true,
  ...props
}: EditableTypographyProps & TypographyProps) => {
  const [internalValue, setInternalValue] = useState('');

  const value = props.children || internalValue;

  const onChange = (value: string) => {
    if (propsOnChange) {
      propsOnChange(value);
    }
    setInternalValue(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  if (!isEditable) {
    return <Typography {...props} children={value} />;
  }

  return (
    <Typography
      {...props}
      sx={Styles}
      multiline={multiline}
      width={'100%'}
      children={value}
      component={InputBaseWithChildren}
      onChange={handleChange}
    />
  );
};

export default EditableTypography;

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

type ExperimentMetricTableProps = {
  data: any;
  displayTrend?: boolean;
  delta?: any;
};

export default function ExperimentMetricTable({
  data,
  displayTrend = false,
  delta = null,
}: ExperimentMetricTableProps) {
  const entriesData: [string, any][] = Object.entries(data);
  const entriesDelta: [string, any][] = delta ? Object.entries(delta) : [];

  return (
    <TableContainer component={Paper} sx={{ bgcolor: '#001529', height: 400 }}>
      <Table
        aria-label="metric table"
        sx={{
          tableLayout: 'fixed',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Key</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1">Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entriesData.map(([key, value]) => {
            let trendIcon = null;

            if (displayTrend) {
              const deltaValue = entriesDelta.find(
                ([deltaKey]) => deltaKey === key
              )?.[1];

              if (deltaValue !== undefined) {
                if (typeof value === 'number') {
                  if (deltaValue > 0) {
                    trendIcon = (
                      <ArrowDropUpIcon sx={{ color: 'positive.main' }} />
                    );
                  } else if (deltaValue < 0) {
                    trendIcon = (
                      <ArrowDropDownIcon sx={{ color: 'negative.main' }} />
                    );
                  } else {
                    trendIcon = <ArrowRightIcon />;
                  }
                } else if (
                  typeof deltaValue === 'string' &&
                  deltaValue === 'changed'
                ) {
                  // in this case we need to sx explicitly, otherwise it will be ugly
                  trendIcon = (
                    <ChangeCircleIcon
                      sx={{ marginRight: '5px', fontSize: '14px' }}
                    />
                  );
                }
              }
            }
            return (
              <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Tooltip title={key}>
                    <Typography
                      variant="body2"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {key}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'nowrap',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {trendIcon}
                    <Tooltip
                      title={
                        typeof value === 'number' ? value.toFixed(3) : value
                      }
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {typeof value === 'number' ? value.toFixed(3) : value}
                      </Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

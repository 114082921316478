import { Typography, Box } from '@mui/material';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { ProgressBar } from '../../components/ProgressBar';
import { RISK_FORM_PREFIX } from './RiskAssessment';

interface ProgressBarProps {
  category: {
    categoryName: string;
    sections: {
      label: string;
      id: string;
      description?: string;
      guidingQuestions?: string;
    }[];
  };
  sectionFields: string[];
  sectionIndexCounter: number;
}
const CategoryProgressBar: FC<ProgressBarProps> = ({
  category,
  sectionFields,
  sectionIndexCounter,
}) => {
  const ids = category.sections.flatMap((_, index) =>
    sectionFields.map(
      field => `${RISK_FORM_PREFIX}.${index + sectionIndexCounter}.${field}`
    )
  );
  const watchedValues = useWatch({
    name: ids,
  });

  const filledCount = watchedValues.filter(
    value => value !== undefined && value !== 0
  ).length;
  const totalFields = ids.length;
  const progress = totalFields > 0 ? (filledCount / totalFields) * 100 : 0;

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box width="100%" mr={1} minWidth={'200px'}>
        <ProgressBar variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${filledCount}/${totalFields}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CategoryProgressBar;

import { useState, useEffect } from 'react';
import { List, ListItem, Box, Checkbox, IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

export interface Header {
  name: string;
  show: boolean;
  subHeaders?: Header[];
  open?: boolean;
}

interface ExperimentTableHeaderEditorProps {
  headers: Header[];
  setEditorHeaders: (updatedHeaders: Header[]) => void;
}

export default function ExperimentTableHeaderEditor({
  headers,
  setEditorHeaders,
}: ExperimentTableHeaderEditorProps) {
  const [stateHeaders, setStateHeaders] = useState([] as Header[]);
  useEffect(() => {
    setStateHeaders(
      headers.map((header: Header) => {
        const updatedHeader = { ...header, open: false } as Header;
        if (updatedHeader.subHeaders) {
          updatedHeader.subHeaders = updatedHeader.subHeaders.map(
            (subHeader: Header) => ({ ...subHeader })
          );
        }

        return updatedHeader;
      })
    );
  }, [headers]);

  setEditorHeaders(stateHeaders);

  /* eslint-disable */
  const changeHeaderOrder = (
    headerList: Header[],
    index: number,
    direction: 1 | -1
  ) => {
    const toIndex = index + direction;
    const to = headerList[toIndex];
    headerList[toIndex] = headerList[index];
    headerList[index] = to;
    setStateHeaders([...stateHeaders]);
  };

  const toggleHeaderVisibility = (
    parentPosition: number,
    childPosition?: number
  ) => {
    if (childPosition !== undefined) {
      const parentHeader = stateHeaders[parentPosition];
      if (parentHeader.subHeaders) {
        const childHeader = parentHeader.subHeaders[childPosition];
        childHeader.show = !childHeader.show;
      }
    } else {
      const header = stateHeaders[parentPosition];
      header.show = !header.show;
    }

    setStateHeaders([...stateHeaders]);
  };

  const toggleHeaderState = (position: number) => {
    stateHeaders[position].open = !stateHeaders[position].open;
    setStateHeaders([...stateHeaders]);
  };
  /* eslint-enable */

  return (
    <List>
      {stateHeaders.map((header: Header, headerIndex: number) => (
        <List key={headerIndex} onClick={() => toggleHeaderState(headerIndex)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#0d2b48',
              padding: '10px',
              paddingLeft: header.subHeaders ? '20px' : '45px',
              borderRadius: '4px',
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#021e38' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {header.subHeaders &&
                (header.open ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
              {header.name}
              <Checkbox
                checked={header.show}
                onClick={e => {
                  e.stopPropagation();
                  toggleHeaderVisibility(headerIndex);
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {headerIndex !== 0 && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    changeHeaderOrder(stateHeaders, headerIndex, -1);
                  }}
                >
                  <NorthIcon sx={{ color: 'white', width: '20px' }} />
                </IconButton>
              )}
              {headerIndex !== stateHeaders.length - 1 && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    changeHeaderOrder(stateHeaders, headerIndex, 1);
                  }}
                >
                  <SouthIcon sx={{ color: 'white', width: '20px' }} />
                </IconButton>
              )}
            </Box>
          </Box>
          {header.subHeaders && (
            <List
              sx={{
                listStyleType: 'none',
                padding: '0px',
                margin: '5px 0px 0px 20px',
                transition: 'max-height 0.3s',
                maxHeight: header.open ? '150px' : '0px',
                overflow: 'auto',
              }}
            >
              {header.subHeaders.map(
                (subHeader: any, subHeaderIndex: number) => (
                  <ListItem key={subHeaderIndex}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: '4px',
                        padding: '0px 10px 0px 20px',
                        backgroundColor: '#0d2b48',
                        '&:hover': { backgroundColor: '#021e38' },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {subHeader.name}
                        <Checkbox
                          checked={subHeader.show}
                          disabled={!header.show}
                          onClick={e => {
                            toggleHeaderVisibility(headerIndex, subHeaderIndex);
                            e.stopPropagation();
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {subHeaderIndex !== 0 && (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              changeHeaderOrder(
                                header.subHeaders as Header[],
                                subHeaderIndex,
                                -1
                              );
                            }}
                          >
                            <NorthIcon sx={{ color: 'white', width: '15px' }} />
                          </IconButton>
                        )}
                        {subHeaderIndex !==
                          (header.subHeaders as Header[]).length - 1 && (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              changeHeaderOrder(
                                header.subHeaders as Header[],
                                subHeaderIndex,
                                1
                              );
                            }}
                          >
                            <SouthIcon sx={{ color: 'white', width: '15px' }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                )
              )}
            </List>
          )}
        </List>
      ))}
    </List>
  );
}

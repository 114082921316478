import { useState } from 'react';
import {
  Divider,
  Grid,
  Drawer,
  Typography,
  Tabs,
  Tab,
  Chip,
  Box,
  Tooltip,
  Switch,
} from '@mui/material';
import { Experiment } from '../../__generated__/gql/graphql';
import TabPanel from '../../components/TabPanel';
import ComparisonTable from './ComparisonTable';
import ComparisonChart from './ComparisonChart';
import string2rbg from './String2RBG';

interface ExperimentComparisonViewProps {
  experiments: Experiment[];
  onUnselectExperiment: (experimentId: string) => void;
  isOpen: boolean;
  onClose: () => void;
}

const GRID_STYLE = {
  bgcolor: '#001529',
  padding: 1,
  borderRadius: '4px',
};

export default function ExperimentComparisonView({
  experiments,
  onUnselectExperiment,
  isOpen,
  onClose,
}: ExperimentComparisonViewProps) {
  const [tab, setTab] = useState(0);
  const [showAllTableField, setShowAllTableField] = useState(false);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 2 / 3,
        },
      }}
    >
      <Grid container padding="15px" justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">EXPERIMENTS COMPARISON</Typography>
          <Divider sx={{ bgcolor: 'white', mt: '8px' }} />
        </Grid>
        <Tabs value={tab} onChange={(_: any, newTab: number) => setTab(newTab)}>
          <Tab label="Run Facts" id="comparison-tab-0" />
          <Tab label="Data Stats" id="comparison-tab-1" />
        </Tabs>
        <TabPanel value={tab} index={0} style={{ width: '100%' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} pt="15px">
              <Typography variant="h6">Experiments</Typography>
              <Divider sx={{ bgcolor: 'white', m: '8px 0' }} />
              <Box
                display="flex"
                flexWrap="wrap"
                style={{
                  width: '100%',
                  maxHeight: '120px',
                  gap: '7px',
                  overflow: 'auto',
                }}
              >
                {experiments.map((experiment: Experiment) => (
                  <Chip
                    key={experiment.id}
                    label={`Experiment #${experiment.id}`}
                    variant="outlined"
                    sx={(() => {
                      const rbg = string2rbg(experiment.id);
                      return { borderColor: rbg, color: rbg };
                    })()}
                    onDelete={() => onUnselectExperiment(experiment.id)}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} pt="15px">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">Metadata</Typography>
                <Tooltip title="Show all table comparison fields">
                  <Switch
                    checked={showAllTableField}
                    onChange={() => setShowAllTableField(!showAllTableField)}
                  />
                </Tooltip>
              </Box>
              <Divider sx={{ bgcolor: 'white', m: '8px 0' }} />
            </Grid>
            <Grid item xs={5.8} sx={GRID_STYLE}>
              <ComparisonTable
                experiments={experiments}
                metadata="parameters"
                showAll={showAllTableField}
              />
            </Grid>
            <Grid item xs={5.8} sx={GRID_STYLE}>
              <ComparisonTable
                experiments={experiments}
                metadata="metrics"
                showAll={showAllTableField}
              />
            </Grid>
            <Grid item xs={12} pt="15px">
              <Typography variant="h6">Metrics History</Typography>
              <Divider sx={{ bgcolor: 'white', m: '8px 0' }} />
            </Grid>
            <Grid item xs={12} sx={GRID_STYLE}>
              <ComparisonChart experiments={experiments} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1} />
      </Grid>
    </Drawer>
  );
}

import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { colorThemes } from '../theme';

interface QuestionnaireAnswerButtonProps {
  questionId: number;
  option: { name: string; value: number };
  handleNextStep: (id: number, value: number[]) => void;
}

const QuestionnaireAnswerButton: FC<QuestionnaireAnswerButtonProps> = ({
  questionId,
  option,
  handleNextStep,
}) => {
  return (
    <Button
      sx={{
        border: 2,
        width: '100%',
        height: '120px',
        borderColor: colorThemes.DARK_BLUE_100,
        px: 5,
        py: 5,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
          background: 'rgba(0, 123, 255, 0.2)',
        },
        '&:active': {
          borderColor: colorThemes.ACTION_BLUE,
        },
      }}
      onClick={() => handleNextStep(questionId, [option.value])}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
        textTransform={'none'}
        sx={{
          fontSize: {
            xs: '12px',
            lg: '16px',
          },
        }}
      >
        {option.name}
      </Box>
    </Button>
  );
};

export default QuestionnaireAnswerButton;

import { Box, Card, Typography } from '@mui/material';
import { axisClasses, BarChart } from '@mui/x-charts';
import { GetRiskAssessmentDashboardQuery } from '../../__generated__/gql/graphql';
import { colorThemes } from '../../theme';

export default function ImpactStatisticsDashboard({
  data,
}: {
  data: GetRiskAssessmentDashboardQuery['riskAssessmentDashboard'];
}) {
  const impactStatistics = data?.impactStatistics;
  const businessCategories = ['High', 'Moderate', 'Minor'];
  const businessCounts = [
    impactStatistics?.numBusinessHigh || 0,
    impactStatistics?.numBusinessModerate || 0,
    impactStatistics?.numBusinessMinor || 0,
  ];

  const userCategories = ['High', 'Moderate', 'Minor'];
  const userCounts = [
    impactStatistics?.numUserHigh || 0,
    impactStatistics?.numUserModerate || 0,
    impactStatistics?.numUserMinor || 0,
  ];

  const systemCategories = ['High', 'Moderate', 'Minor'];
  const systemCounts = [
    impactStatistics?.numSystemHigh || 0,
    impactStatistics?.numSystemModerate || 0,
    impactStatistics?.numSystemMinor || 0,
  ];

  return (
    <Card
      sx={{
        borderRadius: 2,
        display: 'flex',
        backgroundColor: colorThemes.DARK_BLUE_600,
        justifyContent: 'center',
        gap: 1,
        padding: 1,
        height: 300,
        border: '2px solid #334E68',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" textAlign="center" gutterBottom>
          Business Impact
        </Typography>
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: businessCategories,
              colorMap: {
                type: 'ordinal',
                colors: ['#00a6fb', '#0582ca', '#006494'],
              },
            },
          ]}
          series={[
            {
              data: businessCounts,
            },
          ]}
          sx={{
            borderRadius: '6px 6px 8px 8px',
            bgcolor: '#efeae4',
            color: 'black',
            [`.${axisClasses.root}`]: {
              [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                stroke: 'black',
                strokeWidth: 1,
              },
              [`.${axisClasses.tickLabel}`]: {
                fill: 'black',
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" textAlign="center" gutterBottom>
          User Impact
        </Typography>
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: userCategories,
              colorMap: {
                type: 'ordinal',
                colors: ['#00a6fb', '#0582ca', '#006494'],
              },
            },
          ]}
          series={[
            {
              data: userCounts,
            },
          ]}
          sx={{
            borderRadius: '6px 6px 8px 8px',
            bgcolor: '#efeae4',
            color: 'black',
            [`.${axisClasses.root}`]: {
              [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                stroke: 'black',
                strokeWidth: 1,
              },
              [`.${axisClasses.tickLabel}`]: {
                fill: 'black',
              },
            },
          }}
        />
      </Box>

      {/* System Impact */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" textAlign="center" gutterBottom>
          System Impact
        </Typography>
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: systemCategories,
              colorMap: {
                type: 'ordinal',
                colors: ['#00a6fb', '#0582ca', '#006494'],
              },
            },
          ]}
          series={[
            {
              data: systemCounts,
            },
          ]}
          sx={{
            borderRadius: '6px 6px 8px 8px',
            bgcolor: '#efeae4',
            color: 'black',
            [`.${axisClasses.root}`]: {
              [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                stroke: 'black',
                strokeWidth: 1,
              },
              [`.${axisClasses.tickLabel}`]: {
                fill: 'black',
              },
            },
          }}
        />
      </Box>
    </Card>
  );
}

import { Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { colorThemes } from '../theme';

interface Props {
  elements: number[];
  selectedElement?: number;
  setSelectedElement: (newSelection: number) => void;
  majorVersion?: number;
}

export default function VersionSelector({
  elements,
  selectedElement,
  setSelectedElement,
  majorVersion,
}: Props): JSX.Element {
  const sortedElements = [...elements].sort((a, b) => b - a);
  const lastElement = elements[sortedElements.length - 1];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const defaultValue =
    selectedElement ??
    (elements.length > 0 ? elements[elements.length - 1] : '');
  const renderVersion = (version: number) =>
    majorVersion !== undefined
      ? `v${majorVersion}.${version}`
      : `v${version}.0`;

  return (
    <Select
      size="small"
      value={defaultValue}
      displayEmpty
      open={isDropdownOpen}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
            overflow: 'auto',
          },
        },
      }}
      sx={{
        width: 120,
        padding: '4px 8px',

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white',
        },

        '& .MuiOutlinedInput-input': {
          padding: '0',
        },

        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
            border: 2,
          },
        },

        '& .MuiSvgIcon-root': {
          color: 'white',
        },

        '.MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
          backgroundColor: colorThemes.GREY_100,
          color: 'black',
        },
      }}
      onChange={event => setSelectedElement(event.target.value as number)}
      renderValue={selected =>
        selected === lastElement
          ? isDropdownOpen
            ? `Latest -- ${renderVersion(selected)}`
            : renderVersion(selected)
          : renderVersion(selected)
      }
    >
      {sortedElements.length > 0 ? (
        sortedElements.map(element => (
          <MenuItem key={element} value={element}>
            {element === lastElement
              ? `Latest -- ${renderVersion(element)}`
              : renderVersion(element)}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled value="">
          No version available
        </MenuItem>
      )}
    </Select>
  );
}

import { axisClasses, BarChart } from '@mui/x-charts';
import { FC } from 'react';
import { RiskAssessment, RiskSection } from '../../__generated__/gql/graphql';
import { Grid, Typography } from '@mui/material';

interface RiskAssessmentChartsProps {
  selectedAssessment: RiskAssessment;
}

const RiskAssessmentCharts: FC<RiskAssessmentChartsProps> = ({
  selectedAssessment,
}) => {
  const { riskSections } = selectedAssessment;

  if (!riskSections) {
    return <div>No data available</div>;
  }

  const validRiskSections = riskSections.filter(
    (section): section is RiskSection => section !== null
  );

  const getNumericFields = (section: RiskSection) => {
    return Object.entries(section)
      .filter(([key, value]) => key !== 'category' && typeof value === 'number')
      .map(([, value]) => value as number);
  };

  const valueLabels = ['n.d.', 'Rare', 'Low', 'Medium', 'High', 'Very High'];

  const categoryData = validRiskSections.reduce((acc, section) => {
    const category = section.category || 'Unknown';
    const values = getNumericFields(section);

    if (!acc[category]) {
      acc[category] = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    }

    values.forEach(value => {
      acc[category][value] = (acc[category][value] || 0) + 1;
    });

    return acc;
  }, {} as Record<string, Record<number, number>>);

  const charts = Object.entries(categoryData).map(
    ([category, counts], index) => {
      const xAxisData = valueLabels;
      const seriesData = [{ data: Object.values(counts) }];

      return (
        <Grid item xs={6} key={index}>
          <Typography
            color="black"
            bgcolor="#efeae4"
            textAlign="center"
            pt={1}
            borderRadius="8px 8px 0px 0px"
          >
            {category}
          </Typography>
          <BarChart
            xAxis={[
              {
                scaleType: 'band',
                data: xAxisData,
                colorMap: {
                  type: 'ordinal',
                  colors: [
                    '#00a6fb',
                    '#0582ca',
                    '#006494',
                    '#003554',
                    '#03273C',
                    '#051923',
                  ],
                },
              },
            ]}
            series={seriesData}
            height={200}
            sx={{
              borderRadius: '0px 0px 8px 8px',
              bgcolor: '#efeae4',
              color: 'black',
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: 'black',
                  strokeWidth: 1,
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: 'black',
                },
              },
            }}
          />
        </Grid>
      );
    }
  );

  return (
    <Grid container spacing={2} pr={2} pb={2}>
      {charts}
    </Grid>
  );
};

export default RiskAssessmentCharts;

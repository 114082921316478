import { Box } from '@mui/material';
import theme from '../theme';
import { Suspense } from 'react';
import { Loading } from './Loading';
import { withAuthenticationRequired } from 'react-oidc-context';
import UnknownErrorFallback from './UnknownErrorFallback';
import * as Sentry from '@sentry/react';
interface ApplicationPageProps {
  children: JSX.Element | JSX.Element[];
  noPadding?: boolean;
  hideOverflow?: boolean;
}

function ApplicationPage({
  children,
  noPadding,
  hideOverflow = false,
}: ApplicationPageProps) {
  return (
    <Sentry.ErrorBoundary fallback={<UnknownErrorFallback />} showDialog={true}>
      <Suspense fallback={<Loading message={'trail is loading'} />}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.default,
            ...(hideOverflow && { overflow: 'hidden' }),
          }}
        >
          <Box
            sx={{
              padding: noPadding ? 0 : '20px',
              flexGrow: 1,
              height: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default withAuthenticationRequired(ApplicationPage, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
});

import { Box, Button, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { colorThemes } from '../../theme';

interface ProjectTypeStageProps {
  onClick: () => void;
}

export default function ProjectTypeStage({ onClick }: ProjectTypeStageProps) {
  const buttonStyle = {
    width: '100%',
    height: '100px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: colorThemes.DARK_BLUE_200,
  };
  const selectedStyle = {
    backgroundColor: colorThemes.DARK_BLUE_400,
  };

  const { setValue } = useFormContext();

  function onClickHandler(isPurchased: boolean) {
    setValue('isPurchased', isPurchased);
    onClick();
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography variant="h4">
          Is this model purchased or internally built?
        </Typography>
        <Box sx={{ display: 'flex', gap: '0.5rem', marginTop: '1rem' }}>
          <Controller
            name="isPurchased"
            render={({ field }) => (
              <Button
                sx={{
                  ...buttonStyle,
                  ...(field.value === true ? selectedStyle : {}),
                }}
                onClick={() => onClickHandler(true)}
              >
                Purchased
              </Button>
            )}
          />
          <Controller
            name="isPurchased"
            render={({ field }) => (
              <Button
                sx={{
                  ...buttonStyle,
                  ...(field.value === false ? selectedStyle : {}), // had to compare with true or false values since null is also triggers selectedStyle
                }}
                onClick={() => onClickHandler(false)}
              >
                Internally Built
              </Button>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { colorThemes } from '../theme';
import {
  AssociatedPolicyEnum,
  CountryEnum,
} from '../__generated__/gql/graphql';

export default function ExternalContextStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsNorms,
    append: appendNorm,
    remove: removeNorm,
  } = useFieldArray({
    control,
    name: 'valueNorms',
  });
  const addFieldNorm = () => {
    appendNorm({ organizationValue: '', description: '' });
  };

  const {
    fields: fieldsExternalParties,
    append: appendExternalParty,
    remove: removeExternalParty,
  } = useFieldArray({
    control,
    name: 'externalParties',
  });
  const addFieldParty = () => {
    appendExternalParty({ name: '', requirements: '' });
  };

  const buttonStyle = {
    color: 'white',
    fontWeight: 'bold',
    gap: '4px',
    paddingRight: '1rem',
    backgroundColor: colorThemes.ACTION_BLUE,
  };

  return (
    <>
      <Typography variant="h6" marginBottom={'5px'} fontWeight={600}>
        Relevant Policies
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Controller
            name="country"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  labelId="simple-select-country"
                  label="Country"
                  sx={{ width: '100%' }}
                  value={value}
                  onChange={onChange}
                >
                  {Object.entries(CountryEnum).map(([key, val]) => (
                    <MenuItem key={val} value={val}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="associatedPolicies"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <InputLabel>Associated Policies</InputLabel>
                <Select
                  labelId="simple-select-policies"
                  label="Associated Policies"
                  sx={{ width: '100%' }}
                  value={value || []}
                  onChange={event => onChange(event.target.value)}
                  multiple
                >
                  {Object.entries(AssociatedPolicyEnum).map(([key, val]) => (
                    <MenuItem key={val} value={val}>
                      {PolicyEnumMap[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Other Policies or Legal requirements"
            variant="outlined"
            {...register('otherPolicies')}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        marginTop={'1.5rem'}
        marginBottom={'5px'}
        fontWeight={600}
      >
        Values & Norms
      </Typography>
      <Button sx={buttonStyle} onClick={addFieldNorm}>
        <AddIcon />
        Add Value/Norm
      </Button>
      <Grid container>
        {fieldsNorms.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginTop={'5px'}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Value/Norm"
                variant="outlined"
                aria-placeholder={'Value/Norm'}
                {...register(`valueNorms.${index}.organizationValue`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                aria-placeholder={
                  'Describe this value/norm in the context of your organization'
                }
                {...register(`valueNorms.${index}.description`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeNorm(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ position: 'relative', marginTop: '1rem' }}>
        <Tooltip
          title={externalContentTooltipText}
          sx={{ position: 'absolute', marginLeft: '-25px' }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
        <TextField
          id="outlined-basic"
          label="Other External Context"
          variant="outlined"
          multiline
          rows={6}
          {...register('externalContext')}
          sx={{ width: '100%' }}
        />
      </Box>
      <Typography
        variant="h6"
        marginTop={'1.5rem'}
        marginBottom={'5px'}
        fontWeight={600}
      >
        External Parties
      </Typography>
      <Button sx={buttonStyle} onClick={addFieldParty}>
        <AddIcon />
        Add Party
      </Button>
      <Grid container>
        {fieldsExternalParties.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginTop={'5px'}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Party"
                variant="outlined"
                aria-placeholder={'Party'}
                {...register(`externalParties.${index}.name`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                label="Requirements"
                variant="outlined"
                {...register(`externalParties.${index}.requirements`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeExternalParty(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

const externalContentTooltipText =
  'Describe the other external context such as:' +
  '\n\nPolicies, guidelines and decisions from regulators that have an impact on the interpretation or enforcement of legal requirements in the development and use of AI systems.\n\n' +
  'Under preparation. Stage at the time of publication: ISO/IEC DIS 5259-1:2023.\n\n' +
  'Incentives or consequences associated with the intended purpose and the use of AI systems.\n\n' +
  'Culture, traditions, values, norms, and ethics with respect to development and use of AI.\n\n' +
  'Competitive landscape and trends for new products and services using AI systems.';

const PolicyEnumMap: { [key: string]: string } = {
  DataAct: 'Data Act',
  AiAct: 'AI Act',
  Gdpr: 'GDPR',
  Iso: 'ISO',
};

import { Drawer } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { Suspense } from 'react';
import { Loading } from '../components/Loading';
import type { AuditViewItemsQuery } from '../__generated__/gql/graphql';
import AuditDetailDrawer from './AuditDetailDrawer';

export interface AuditDetailDrawerProps {
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
  selectedAudit: NonNullable<
    NonNullable<AuditViewItemsQuery['allGovernanceAudits']>[number]
  >;
}

export default function AuditDetailDrawerSuspenseWrapper(
  props: AuditDetailDrawerProps
) {
  const width = '33%';

  return (
    <Drawer
      anchor="right"
      open={props.showSidebar}
      onClose={() => props.setShowSidebar(false)}
      sx={{
        width,
        right: 0,
        left: 'auto',
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
        },
      }}
    >
      <Suspense fallback={<Loading message="Loading audit data ..." />}>
        <AuditDetailDrawer {...props} />
      </Suspense>
    </Drawer>
  );
}

import { Button, Typography, Box } from '@mui/material';
import { colorThemes } from '../theme';
import { FC } from 'react';

interface StartScreenProps {
  onNextStartStage: () => void;
  language?: 'EN' | 'DE';
}

const StartScreen: FC<StartScreenProps> = ({
  onNextStartStage,
  language = 'EN',
}) => {
  return (
    <Box
      sx={{
        width: '80%',
        height: '85%',
        backgroundColor: colorThemes.DARK_BLUE_600,
        borderRadius: '2rem',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '60px',
        padding: '2rem',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 15px 40px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Typography variant="h3" color={colorThemes.YELLOW_400}>
        {language === 'DE'
          ? 'Welche Auswirkungen hat die KI Verordnung auf uns?'
          : 'How am I affected by the EU AI Act?'}
      </Typography>
      <Typography
        variant="body1"
        style={{ whiteSpace: 'pre-wrap', lineHeight: 'normal' }} // 'pre-wrap' ensures breaks without extra spacing
      >
        {language === 'DE'
          ? 'Dieses Questionnaire geht davon aus, dass:\n\n• Sie das betrachtete KI-System nicht ausschließlich für den persönlichen Gebrauch einsetzen (d.h. Sie verwenden es im beruflichen Kontext)\n• Ihre Organisation in der EU ihren Geschäftssitz hat oder hier mit einer Niederlassung ansässig ist\n• Die Ausgabe des betrachteten KI-Systems (von Empfängern) in der EU verwendet wird'
          : 'This assessment assumes that:\n\n• You are not deploying the considered AI system for personal use only (i.e., you are using it in a professional context)\n• You are located or established in the EU\n• The output of the considered AI system will be used (by recipients) in the EU'}
      </Typography>
      <Button variant="contained" onClick={onNextStartStage}>
        {language === 'DE' ? 'Questionnaire starten' : 'Start Questionnaire'}
      </Button>
    </Box>
  );
};

export default StartScreen;

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Grid, Chip } from '@mui/material';
import { colorThemes } from '../theme';
import UserTag from '../components/UserTag';
import type { AuditViewItemsQuery } from '../__generated__/gql/graphql';
import { formatGraphqlEnumToReadableText } from '../utils/stringManipulation';

export default function AuditDetailCard({
  audit,
  onClick,
}: {
  audit: NonNullable<
    NonNullable<AuditViewItemsQuery['allGovernanceAudits']>[number]
  >;
  onClick: () => void;
}) {
  const { name, status, auditors } = audit;

  return (
    <Card
      sx={{
        width: 400,
        height: 200,
        transition: 'all .2s',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_400,
        },
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            noWrap
            textOverflow="ellipsis"
            color="text.main"
            variant="h6"
          >
            {name}
          </Typography>
        </Box>

        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Box mt={2}>
              <Typography variant="subtitle2" color="text.secondary" noWrap>
                Status:
              </Typography>
              <Chip
                variant="outlined"
                size="small"
                label={formatGraphqlEnumToReadableText(status as string)}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={2}>
              <Typography variant="subtitle2" color="text.secondary" noWrap>
                Auditors:
              </Typography>
              {auditors?.map(
                auditor =>
                  auditor && (
                    <UserTag
                      key={auditor.id}
                      user={auditor}
                      variant={'text'}
                      size={'medium'}
                      noWrap={true}
                    />
                  )
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

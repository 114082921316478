import React from 'react';
import { Box } from '@mui/material';
import IconLow from '@mui/icons-material/ThumbUp';
import IconMedium from '@mui/icons-material/ReportProblem';
import IconHigh from '@mui/icons-material/Warning';
import IconCritical from '@mui/icons-material/Dangerous';
import IconUnknown from '@mui/icons-material/QuestionMark';
import { riskColors } from '../../theme';

interface RiskIndicatorProps {
  riskRank?: number;
}

function RiskIndicator({ riskRank }: RiskIndicatorProps) {
  let Icon;
  let bgColor;

  if (
    riskRank === undefined ||
    riskRank === 0 ||
    riskRank === null ||
    Number.isNaN(riskRank)
  ) {
    Icon = IconUnknown;
    bgColor = riskColors.unknown;
  } else if (riskRank <= 25) {
    Icon = IconLow;
    bgColor = riskColors.moderate;
  } else if (riskRank <= 50) {
    Icon = IconMedium;
    bgColor = riskColors.serious;
  } else if (riskRank <= 100) {
    Icon = IconHigh;
    bgColor = riskColors.major;
  } else {
    Icon = IconCritical;
    bgColor = riskColors.critical;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: bgColor,
        borderRadius: '8px',
        width: '30px',
        height: '30px',
      }}
    >
      <Icon
        style={{
          cursor: 'default',
          color: '#fff',
          fontSize: 20,
        }}
      />
    </Box>
  );
}

export default RiskIndicator;

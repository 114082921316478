import React from 'react';
import { Project } from '../__generated__/gql/graphql';

// The context will not be used with the default value of undefined.
const DefaultProjectContext = {
  project: undefined as unknown as Project,
  setProject: () => {},
};

const ProjectContext = React.createContext<{
  project?: Project;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setProject: (project: Project | undefined) => void;
}>(DefaultProjectContext);

export const ProjectContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const storedProject = localStorage.getItem('selectedProject');
  const parsedStoredProject = storedProject
    ? JSON.parse(storedProject)
    : undefined;

  const [project, setProject] = React.useState<Project>(
    parsedStoredProject ?? DefaultProjectContext.project
  );

  const handleSetProject = (project: Project | undefined) => {
    if (project === undefined) {
      localStorage.removeItem('selectedProject');
      setProject(DefaultProjectContext.project);
    } else {
      localStorage.setItem('selectedProject', JSON.stringify(project));
      setProject(project);
    }
  };

  return (
    <ProjectContext.Provider value={{ project, setProject: handleSetProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => React.useContext(ProjectContext);

import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

mermaid.initialize({
  startOnLoad: false,
});

interface MermaidProps {
  chart: string;
}

const MermaidGraphic: React.FC<MermaidProps> = ({ chart }) => {
  const mermaidRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mermaidRef.current) {
      mermaid.contentLoaded();
      try {
        mermaid.parse(chart);
        mermaidRef.current.innerHTML = chart;
        mermaid.init(undefined, mermaidRef.current);
      } catch (error) {
        console.error('Mermaid syntax error', error);
      }
    }
  }, [chart]);

  return <div ref={mermaidRef} />;
};

export default MermaidGraphic;

import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { RequirementCompletionStatusEnum } from '../__generated__/gql/graphql';

const steps = ['Not Started', 'Assigned', 'Completed', 'Approved'];

const statusToStepIndex = {
  [RequirementCompletionStatusEnum.NotStarted]: 0,
  [RequirementCompletionStatusEnum.Assigned]: 1,
  [RequirementCompletionStatusEnum.Completed]: 2,
  [RequirementCompletionStatusEnum.Approved]: 3,
};

interface ProgressStepperProps {
  status: RequirementCompletionStatusEnum;
}

const RequirementProgressStepper: React.FC<ProgressStepperProps> = ({
  status,
}) => {
  const activeStep = statusToStepIndex[status];

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default RequirementProgressStepper;

import {
  Box,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

export const Loading = ({
  message,
  size = 'regular',
  sx,
}: {
  message: string;
  size?: 'small' | 'regular';
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      width="100%"
      height="100%"
      sx={{
        ...(size === 'regular' && { minHeight: '300px' }),
        ...sx,
      }}
    >
      <CircularProgress {...(size === 'small' && { size: 24 })} />
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
};

import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import TreeVisualizerGraph from './TreeVisualizerGraph';
import MetricSelector from './MetricSelector';
import ExperimentFilterModal from './experiment_filter_modal/ExperimentFilterModal';
import { Filterable, Rule, ValueType } from '../__generated__/gql/graphql';
import { gql } from '../__generated__/gql';
import { defaultAppliedRules } from './TreeSettingsAtoms';
import { Loading } from '../components/Loading';
import NoSelectedProject from '../components/NoSelectedProject';

const GET_FILTER_KEYS = gql(`
  query projectKeys($projectId: String!) {
    project(id: $projectId) {
      filterableMetrics {
        name
        type
      }
      filterableParameters {
        name
        type
      }
    }
  }
`);

export default function TreeVisualizer() {
  const { projectId } = useParams();
  const initFilterables: Filterable[] = [
    {
      name: 'Id',
      type: ValueType.String,
    },
  ];

  const { loading, error, data } = useQuery(GET_FILTER_KEYS, {
    variables: { projectId: projectId || '' },
  });

  const filterableParameters = (data?.project?.filterableParameters ??
    []) as Filterable[];
  const filterableMetrics = (data?.project?.filterableMetrics ??
    []) as Filterable[];
  const filterables = initFilterables.concat(
    filterableParameters,
    filterableMetrics
  );
  const [showFilterExperimentModal, setShowFilterExperimentModal] =
    useState(false);
  const [appliedRules, setAppliedRules] =
    useRecoilState<Rule[]>(defaultAppliedRules);

  if (loading) {
    return <Loading message="Loading project ..." />;
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            position: 'fixed',
          }}
        >
          <Typography variant="h4" color="white">
            Experiments
          </Typography>
          {projectId && !error && (
            <Typography variant="body2" color="text.secondary">
              Project Id: {projectId}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            position: 'fixed',
            right: '20px',
            zIndex: 1100,
          }}
        >
          <Button
            variant={'outlined'}
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilterExperimentModal(true)}
          >
            FILTER EXPERIMENTS
          </Button>
          <MetricSelector />
        </Box>
      </Box>

      {projectId ? (
        !error && <TreeVisualizerGraph projectId={projectId} />
      ) : (
        <NoSelectedProject />
      )}

      <ExperimentFilterModal
        filterables={filterables}
        appliedRules={appliedRules}
        setAppliedRules={setAppliedRules}
        isOpen={showFilterExperimentModal}
        onClose={() => setShowFilterExperimentModal(false)}
      />
    </>
  );
}

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  AllowedDataTypeEnum,
  AllowedTaskTypeEnum,
  OrganizationDepartmentsQuery,
  RiskClass,
  UsersBasicInfoQuery,
} from '../../__generated__/gql/graphql';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  getRiskClassCategoryText,
  getRiskClassRoleText,
} from '../../risk_classification/QuestionnaireResult';
import { colorThemes } from '../../theme';

export default function ModelSpesificationStage({
  usersData,
  departmentsData,
  riskClass,
  onClick,
  onNextRiskClassQuestionnaire,
}: {
  usersData: UsersBasicInfoQuery;
  departmentsData: OrganizationDepartmentsQuery;
  riskClass?: RiskClass;
  onClick: () => void;
  onNextRiskClassQuestionnaire: () => void;
}) {
  const { register, control, getValues, setValue } = useFormContext();
  const users = usersData.allUsers || [];

  const allDepartments = Array.from(
    new Set([
      ...(departmentsData.organization?.departments || []),
      ...DefaultDepartments,
    ])
  );

  const handleDataTypeChange = (
    event: SelectChangeEvent<AllowedDataTypeEnum[]>
  ) => {
    const value = event.target.value;
    setValue('allowedDataTypes', value);
  };

  const handleTaskTypeChange = (
    event: SelectChangeEvent<AllowedTaskTypeEnum[]>
  ) => {
    const value = event.target.value;
    setValue('allowedTaskTypes', value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Title"
            required
            variant="outlined"
            placeholder="Title of the project"
            aria-placeholder={'Title'}
            {...register('title')}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Description"
            required
            variant="outlined"
            placeholder="Description"
            aria-placeholder={'Description'}
            {...register('description')}
            sx={{ width: '100%' }}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={'responsiblePerson'}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth required>
                <InputLabel>Responsible Person</InputLabel>
                <Select
                  labelId="simple-select-objective"
                  label="Responsible Person"
                  sx={{ width: '100%' }}
                  value={value}
                  onChange={onChange}
                >
                  {Object.values(users).map(user => {
                    if (!user) return <></>;
                    return (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name ? user.name : user.email}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            control={control}
            rules={{ required: false }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="department"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={allDepartments}
                freeSolo
                getOptionLabel={option => option || ''}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Department"
                    required
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
                onChange={(_, data) => field.onChange(data || '')}
              />
            )}
          />
        </Grid>

        {getValues('isPurchased') ? (
          <></>
        ) : (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">
                  Allowed Data Types
                </InputLabel>
                <Controller
                  name="allowedDataTypes"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Allowed Data Types" />}
                      renderValue={selected =>
                        selected.map(s => DataTypeDisplayMap[s]).join(', ')
                      }
                      onChange={handleDataTypeChange}
                    >
                      {Object.values(AllowedDataTypeEnum).map(dataType => (
                        <MenuItem key={dataType} value={dataType}>
                          <Checkbox
                            checked={getValues('allowedDataTypes').includes(
                              dataType
                            )}
                          />
                          <ListItemText
                            primary={DataTypeDisplayMap[dataType]}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ maxWidth: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Allowed Task Types
                </InputLabel>
                <Controller
                  name="allowedTaskTypes"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Allowed Task Types" />}
                      renderValue={selected =>
                        selected.map(s => TaskTypeDisplayMap[s]).join(', ')
                      }
                      onChange={handleTaskTypeChange}
                    >
                      {Object.values(AllowedTaskTypeEnum).map(taskType => (
                        <MenuItem key={taskType} value={taskType}>
                          <Checkbox
                            checked={getValues('allowedTaskTypes').includes(
                              taskType
                            )}
                          />
                          <ListItemText
                            primary={TaskTypeDisplayMap[taskType]}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Add Usage Policy"
            variant="outlined"
            placeholder="Add Usage Policy"
            multiline
            rows={3}
            aria-placeholder={'Usage Policy'}
            {...register('usagePolicy')}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddOutlinedIcon />}
            onClick={onClick}
            variant="outlined"
          >
            Add User Facing Model Card
          </Button>
        </Grid>
        <Grid item marginBottom={'1rem'}>
          {riskClass ? (
            <Box
              display={'flex'}
              border={'1px solid'}
              borderColor={colorThemes.YELLOW_400}
              p={1}
              borderRadius={1}
            >
              {riskClass.riskClassRole === 'OUT_OF_SCOPE' ? (
                <Typography>Risk Class Assessment: Out of Scope</Typography>
              ) : (
                <>
                  <Typography>
                    Risk Class Assessment:&nbsp;
                    {getRiskClassRoleText(riskClass.riskClassRole)}
                  </Typography>
                  <Typography>
                    &nbsp;with&nbsp;
                    {getRiskClassCategoryText(riskClass.riskClassCategory)} Risk
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="outlined"
              onClick={onNextRiskClassQuestionnaire}
            >
              Add Risk Class
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export const DefaultDepartments = [
  'Marketing',
  'Sales',
  'Product',
  'Development',
];

const DataTypeDisplayMap = {
  AUDIO: 'Audio',
  CATEGORICAL: 'Categorical',
  IMAGE: 'Image',
  NUMERICAL: 'Numerical',
  TABULAR: 'Tabular',
  OTHER: 'Other',
  TEXT: 'Text',
  TIME_SERIES: 'Time Series',
  VIDEO: 'Video',
};

const TaskTypeDisplayMap = {
  ANOMALY_DETECTION: 'Anomaly Detection',
  CAUSAL_MODELING: 'Causal Modeling',
  CLASSIFICATION: 'Classification',
  CLUSTERING: 'Clustering',
  OTHER: 'Other',
  REGRESSION: 'Regression',
  SIMILARITY_MATCHING: 'Similarity Matching',
  TRANSCRIPTION: 'Transcription',
  TRANSLATION: 'Translation',
};

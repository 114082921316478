import { Box, Button, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { colorThemes } from '../../theme';
import { ProjectTypeEnum } from '../../__generated__/gql/graphql';

interface ModelTypeStageProps {
  onClick: () => void;
}
export default function ModelTypeStage({ onClick }: ModelTypeStageProps) {
  const buttonStyle = {
    width: '100%',
    height: '100px',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: colorThemes.DARK_BLUE_200,
  };
  const selectedStyle = {
    backgroundColor: colorThemes.DARK_BLUE_400,
  };

  const { setValue } = useFormContext();

  const onClickHandler = (modelType: ProjectTypeEnum) => {
    setValue('purchasedModel', modelType);
    onClick();
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography variant="h4">
          Choose your purchased model or create a custom one
        </Typography>
        <Box sx={{ display: 'flex', gap: '0.5rem', marginTop: '1rem' }}>
          {Object.entries(ProjectTypeEnum).map(([key, value]) => (
            <Controller
              key={key}
              name="purchasedModel"
              render={({ field }) => (
                <Button
                  sx={{
                    ...buttonStyle,
                    ...(field.value ===
                    ProjectTypeEnum[key as keyof typeof ProjectTypeEnum]
                      ? selectedStyle
                      : {}),
                  }}
                  onClick={() =>
                    onClickHandler(
                      ProjectTypeEnum[key as keyof typeof ProjectTypeEnum]
                    )
                  }
                >
                  {ModelTypeEnumyMap[value]}
                </Button>
              )}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const ModelTypeEnumyMap: { [key: string]: string } = {
  CUSTOM: 'Custom',
  CHATGPT: 'ChatGPT',
  GITHUB_COPILOT: 'GitHub Copilot',
  MICROSOFT_COPILOT: 'Microsoft Copilot',
};

import React, { useState, FC } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import TreatmentBox from './TreatmentBox';
import { gql, useMutation } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import { RiskTreatment, User } from '../../__generated__/gql/graphql';

interface CreateNewTreatmentProps {
  setExpanded: (expanded: boolean) => void;
  allUsers: User[];
  append: (treatment: RiskTreatment) => void;
}

const CREATE_RISK_TREATMENT = gql`
  mutation CreateRiskTreatment(
    $treatment: String!
    $responsiblePersonId: String!
    $description: String!
  ) {
    createRiskTreatment(
      treatment: $treatment
      responsiblePersonId: $responsiblePersonId
      description: $description
    ) {
      riskTreatment {
        id
        treatment
        responsiblePersonId
        description
      }
    }
  }
`;

const CreateNewTreatment: FC<CreateNewTreatmentProps> = ({
  setExpanded,
  allUsers,
  append,
}) => {
  const [treatmentSection, setTreatmentSection] = useState({
    treatment: '',
    responsiblePersonId: '',
    description: '',
  });

  const [createNewTreatment] = useMutation(CREATE_RISK_TREATMENT, {
    update: (cache, { data: { createRiskTreatment } }) => {
      const newTreatment = createRiskTreatment.riskTreatment;

      cache.modify({
        fields: {
          riskTreatments(existingTreatments = []) {
            const newTreatmentRef = cache.writeFragment({
              data: newTreatment,
              fragment: gql`
                fragment newTreatment on RiskTreatment {
                  id
                  treatment
                  responsiblePersonId
                  description
                }
              `,
            });
            return [...existingTreatments, newTreatmentRef];
          },
        },
      });
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTreatmentSection({ ...treatmentSection, [name]: value });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setTreatmentSection({ ...treatmentSection, [name]: value });
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!treatmentSection.responsiblePersonId) {
      toast.error('Please select a responsible person before submitting');
      return;
    }

    try {
      const response = await createNewTreatment({
        variables: {
          treatment: treatmentSection.treatment,
          responsiblePersonId: treatmentSection.responsiblePersonId,
          description: treatmentSection.description,
        },
      });
      const treatment: RiskTreatment = {
        id: response.data.createRiskTreatment.riskTreatment.id,
        treatment: treatmentSection.treatment,
        responsiblePersonId:
          response.data.createRiskTreatment.riskTreatment.responsiblePersonId,
        description: treatmentSection.description,
      };
      append(treatment);
      setExpanded(false);
      setTreatmentSection({
        treatment: '',
        responsiblePersonId: '',
        description: '',
      });
    } catch (err) {
      console.error('Error:', err);
      toast.error('Error creating new treatment');
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      <Typography color={'black'}>New Treatment Option:</Typography>
      <TreatmentBox
        checked={false}
        allUsers={allUsers}
        title={treatmentSection.treatment}
        personId={treatmentSection.responsiblePersonId}
        description={treatmentSection.description}
        setExpanded={setExpanded}
        handleInputChange={handleInputChange}
        handleSelectChange={handleSelectChange}
        onFormSubmit={handleFormSubmit}
        addible={true}
      />
      <ToastContainer />
    </Box>
  );
};

export default CreateNewTreatment;

import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

interface ReportConcernDialogProps {
  open: boolean;
  onClose: () => void;
  getModelName?: string;
  getPerson?: string;
}

const REPORT_CONCERN_MUTATION = gql`
  mutation ReportConcern(
    $modelName: String!
    $user: String!
    $concernType: String!
    $description: String!
    $option: String
  ) {
    reportConcern(
      modelName: $modelName
      user: $user
      concernType: $concernType
      description: $description
      option: $option
    ) {
      concern {
        modulName
        person
        type
        description
        importance
        date
      }
    }
  }
`;

const ReportConcernDialog: FC<ReportConcernDialogProps> = ({
  open,
  onClose,
  getModelName,
  getPerson,
}) => {
  const [modelName, setModelName] = useState(getModelName);
  const [user, setUser] = useState(getPerson);
  const [concernType, setConcernType] = useState('');
  const [description, setDescription] = useState('');
  const [option, setOption] = useState('');

  const [reportConcern] = useMutation(REPORT_CONCERN_MUTATION);

  const handleReportConcern = () => {
    try {
      reportConcern({
        variables: {
          modelName,
          user,
          concernType,
          description,
          option,
        },
      });
      toast.success('Successfully submitted concern');
      onClose();
    } catch (error) {
      toast.error('Error reporting concern');
      console.error('Error reporting concern:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle fontWeight={'bold'} fontSize={'30px'} pb={'8px !important'}>
        Concern Reporting
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ height: '50vh', width: '50vw' }}
        >
          <Box display="flex" justifyContent="space-between" pt={2}>
            <TextField
              label={'Model Name'}
              sx={{ width: '30%' }}
              value={modelName}
              onChange={e => setModelName(e.target.value)}
            />
            <TextField
              label={'User'}
              sx={{ width: '30%' }}
              value={user}
              onChange={e => setUser(e.target.value)}
            />
            <FormControl sx={{ width: '30%' }}>
              <InputLabel>Type of Concern</InputLabel>
              <Select
                label="Type of Concern"
                value={concernType}
                onChange={e => setConcernType(e.target.value)}
              >
                <MenuItem value="Test">Test</MenuItem>
                <MenuItem value="Bug">Bug</MenuItem>
                <MenuItem value="Feature Request">Feature Request</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            multiline
            rows={4}
            sx={{ mt: 2 }}
            label={'Concern Description '}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Typography fontWeight={'bold'} pt={2}>
            Options
          </Typography>
          <RadioGroup
            aria-label="options"
            name="options"
            value={option}
            onChange={e => setOption(e.target.value)}
          >
            <FormControlLabel
              value="Confidential"
              control={<Radio />}
              label="Confidential"
            />
            <FormControlLabel
              value="Anonymity"
              control={<Radio />}
              label="Anonymity"
            />
            <FormControlLabel
              value="Urgent (Request to escalate)"
              control={<Radio />}
              label="Urgent (Request to escalate)"
            />
          </RadioGroup>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={handleReportConcern}>
            Report Concern
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportConcernDialog;

import React from 'react';
import { DateTime } from 'luxon';

interface FormattedDateTimeProps {
  isoDate: string | null | undefined;
  format?: string; // Optional prop to customize the format
}

const FormattedDateTime: React.FC<FormattedDateTimeProps> = ({
  isoDate,
  format = 'LLL dd, yyyy HH:mm',
}) => {
  const formattedDate = isoDate
    ? DateTime.fromISO(isoDate).toFormat(format)
    : 'N/A';

  return <span>{formattedDate}</span>;
};

export default FormattedDateTime;

import { atom, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  DocumentationTemplate,
  DocumentationTemplates,
} from './tabs/template/DocumentationTemplates';
import {
  DataSourceEnum,
  DocumentComponentTypeEnum,
} from '../../__generated__/gql/graphql';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';

export interface DocumentationEntry {
  id: string;
  type: DocumentComponentTypeEnum;
  name?: string;
  query?: string;
  args?: (string | null)[];
  dataSource?: DataSourceEnum;
}

export const documentTitleAtom = atom<string>({
  key: 'documentTitleAtom',
  default: 'New Document',
});

export const projectIdAtom = atom<string>({
  key: 'projectIdAtom',
  default: '',
});

export const experimentIdAtom = atom<string>({
  key: 'experimentIdAtom',
  default: '',
});

export const documentIdAtom = atom<string>({
  key: 'documentIdAtom',
  default: '',
});

export const documentComponentsAtom = atom<DocumentationEntry[]>({
  key: 'documentComponents',
  default: [],
});

export const majorVersionAtom = atom<number>({
  key: 'majorVersion',
  default: 1,
});

export const editableFileIdAtom = atom<string>({
  key: 'editableFileId',
  default: '',
});

export const documentTemplateAtom = atom<DocumentationTemplate | null>({
  key: 'documentTemplate',
  default: null,
});

export const useResetDocumentState = () => {
  const resetDocumentId = useResetRecoilState(documentIdAtom);
  const resetDocumentComponents = useResetRecoilState(documentComponentsAtom);
  const resetMajorVersion = useResetRecoilState(majorVersionAtom);
  const resetEditableFileId = useResetRecoilState(editableFileIdAtom);
  const resetDocumentTemplate = useResetRecoilState(documentTemplateAtom);
  const resetDocumentTitle = useResetRecoilState(documentTitleAtom);
  const resetProjectId = useResetRecoilState(projectIdAtom);
  const resetExperimentId = useResetRecoilState(experimentIdAtom);

  const resetAll = () => {
    resetDocumentTitle();
    resetDocumentId();
    resetDocumentComponents();
    resetMajorVersion();
    resetEditableFileId();
    resetDocumentTemplate();
    resetProjectId();
    resetExperimentId();
  };

  return resetAll;
};

export const selectTemplate = (
  template: DocumentationTemplate,
  {
    setDocumentComponents,
    setDocumentTemplate,
    setDocumentTitle,
  }: {
    setDocumentComponents: (components: DocumentationEntry[]) => void;
    setDocumentTemplate: (template: DocumentationTemplate) => void;
    setDocumentTitle: (title: string) => void;
  }
) => {
  setDocumentTemplate(template);
  setDocumentTitle(template.title);
  setDocumentComponents(
    template.components.map(component => ({
      id: uuidv4(),
      type:
        component.componentType || DocumentComponentTypeEnum.LegalDisclaimer,
      name: component.name || '',
      query: component.query || '',
      args: component.args || [],
      dataSource: component.dataSource || DataSourceEnum.Text,
    }))
  );
};

export const useInitializeTemplateFromUrl = () => {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const setDocumentTemplate = useSetRecoilState(documentTemplateAtom);
  const setDocumentTitle = useSetRecoilState(documentTitleAtom);
  const [searchParams] = useSearchParams();

  return () => {
    const templateId = searchParams.get('template');
    const template = templateId
      ? DocumentationTemplates.find(t => t.id.toString() === templateId)
      : DocumentationTemplates[0];

    if (template) {
      selectTemplate(template, {
        setDocumentComponents,
        setDocumentTemplate,
        setDocumentTitle,
      });
    }
  };
};

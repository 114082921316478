import Markdown, { Components } from 'react-markdown';
import { Typography } from '@mui/material';
import { colorThemes } from '../theme';

interface Props {
  mdString?: string;
}

export function MarkdownRendering({ mdString }: Props): JSX.Element {
  const markdownComponents: Components = {
    p: ({ ...props }) => {
      return (
        <Typography color={colorThemes.DARK_BLUE_600}>
          {props.children}
        </Typography>
      );
    },
    h1: ({ ...props }) => (
      <Typography variant="h4" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),
    h2: ({ ...props }) => (
      <Typography variant="h5" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),

    // Lists
    ul: ({ ...props }) => (
      <Typography component="ul" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),
    ol: ({ ...props }) => (
      <Typography component="ol" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),
    li: ({ ...props }) => (
      <Typography component="li" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),

    code: ({ ...props }) => (
      <Typography
        component="code"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          // overflowX: 'auto',
        }}
      >
        {props.children}
      </Typography>
    ),
  };
  return <Markdown components={markdownComponents}>{mdString}</Markdown>;
}

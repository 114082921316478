import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRecoilState } from 'recoil';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  autoRefreshEnabled,
  autoRefreshInterval,
} from '../tree_visualization/TreeSettingsAtoms';

const PUT_SETTINGS = gql`
  mutation put_settings(
    $autoRefreshEnabled: Boolean
    $autoRefreshInterval: Int
    $projectId: String!
  ) {
    putSettings(
      autoRefreshEnabled: $autoRefreshEnabled
      autoRefreshInterval: $autoRefreshInterval
      projectId: $projectId
    ) {
      id
      success
    }
  }
`;

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
}

export default function ProjectSettingsModal({
  open,
  onClose,
  projectId,
}: SettingsModalProps) {
  const [refreshInterval, setRefreshInterval] =
    useRecoilState(autoRefreshInterval);
  const [enableAutoRefresh, setEnableAutoRefresh] =
    useRecoilState(autoRefreshEnabled);
  const [loading, setLoading] = useState(false);

  const [commitSettingsUpdate] = useMutation(PUT_SETTINGS, {
    onCompleted: () => {
      setLoading(false);
      toast.success('Settings saved successfully');
    },
    onError: error => {
      setLoading(false);
      toast.error(`Error saving settings: ${error.message}`);
    },
  });

  const handleEnablePollingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnableAutoRefresh(event.target.checked);
  };

  const handlePollingIntervalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRefreshInterval(Number(event.target.value));
  };

  const handleSubmit = () => {
    setLoading(true);
    commitSettingsUpdate({
      variables: {
        autoRefreshEnabled: enableAutoRefresh,
        autoRefreshInterval: refreshInterval,
        projectId,
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={enableAutoRefresh}
                onChange={handleEnablePollingChange}
              />
            }
            label="Enable Auto Refresh"
          />
          <TextField
            label="Auto Refresh Interval (seconds)"
            type="number"
            value={refreshInterval}
            onChange={handlePollingIntervalChange}
            disabled={!enableAutoRefresh}
            margin="dense"
            fullWidth
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={handleSubmit} loading={loading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

import { Card, Typography } from '@mui/material';
import { axisClasses, BarChart } from '@mui/x-charts';
import { GetRiskAssessmentDashboardQuery } from '../../__generated__/gql/graphql';
import { colorThemes } from '../../theme';

export default function RiskClassDashboard({
  data,
}: {
  data: GetRiskAssessmentDashboardQuery['riskAssessmentDashboard'];
}) {
  const riskClassStatistics = data?.riskClassStatistics;

  const categories = [
    'Moderate Risk',
    'Serious Risk',
    'Major Risk',
    'Critical Risk',
  ];
  const counts = [
    riskClassStatistics?.numModerateRisk || 0,
    riskClassStatistics?.numSeriousRisk || 0,
    riskClassStatistics?.numMajorRisk || 0,
    riskClassStatistics?.numCriticalRisk || 0,
  ];

  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: colorThemes.DARK_BLUE_600,
        justifyContent: 'center',
        gap: '16px',
        padding: 1,
        height: 300,
        border: '2px solid #334E68',
      }}
    >
      <Typography variant="h6" textAlign="center" gutterBottom>
        Risk Class Distribution
      </Typography>
      <BarChart
        xAxis={[
          {
            scaleType: 'band',
            data: categories,
            colorMap: {
              type: 'ordinal',
              colors: [
                '#00a6fb', // Moderate Risk
                '#0582ca', // Serious Risk
                '#cc1f1f', // Major Risk
                '#750a00', // Critical Risk
              ],
            },
          },
        ]}
        series={[
          {
            data: counts,
          },
        ]}
        sx={{
          borderRadius: '6px 6px 8px 8px',
          marginBottom: '36px',
          bgcolor: '#efeae4',
          color: 'black',
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tick}, .${axisClasses.line}`]: {
              stroke: 'black',
              strokeWidth: 1,
            },
            [`.${axisClasses.tickLabel}`]: {
              fill: 'black',
            },
          },
        }}
      />
    </Card>
  );
}

import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import AverageSeverityCircularDashboard from './AverageSeverityCircularDashboard';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GetRiskAssessmentDashboardQuery } from '../../__generated__/gql/graphql';
import FrequencyStatisticsDashboard from './FrequencyStatisticsDashboard';
import ImpactStatisticsDashboard from './ImpactStatisticsDashboard';
import NumberDisplay from './NumberDisplay';
import RiskClassDashboard from './RiskClassDashboard';
import { useState } from 'react';

const GET_RISK_ASSESSMENT_DASHBOARD = gql(`
     query getRiskAssessmentDashboard($projectId: String!) {
        riskAssessmentDashboard(projectId: $projectId) {
            averageSeverity(projectId: $projectId)
            riskClassStatistics(projectId: $projectId) {
                numModerateRisk
                numSeriousRisk
                numMajorRisk
                numCriticalRisk
            }
            severityStatistics(projectId: $projectId) {
                numSerious
                numModerate
                numMinor
            }
            frequencyStatistics(projectId: $projectId) {
                numRare
                numUnlikely
                numModerate
                numLikely
                numOften
            }
            impactStatistics(projectId: $projectId) {
                numSystemHigh
                numSystemModerate
                numSystemMinor
                numUserHigh
                numUserModerate
                numUserMinor
                numBusinessHigh
                numBusinessModerate
                numBusinessMinor
            }
            numAssessedRisks(projectId: $projectId)
            numUntreatedHighRisk(projectId: $projectId)
  }
}
`);

enum DashboardTypeEnum {
  IMPACT = 'impact',
  FREQUENCY = 'frequency',
  RISK_CLASS = 'riskClass',
}

export default function RiskManagementDashboard() {
  const { projectId } = useParams();
  const { data } = useSuspenseQuery<GetRiskAssessmentDashboardQuery>(
    GET_RISK_ASSESSMENT_DASHBOARD,
    {
      variables: { projectId },
    }
  );
  const [dashboardType, setDashboardType] = useState(DashboardTypeEnum.IMPACT);

  const DashboardMap: { [key: string]: JSX.Element } = {
    impact: <ImpactStatisticsDashboard data={data.riskAssessmentDashboard} />,
    frequency: (
      <FrequencyStatisticsDashboard data={data.riskAssessmentDashboard} />
    ),
    riskClass: <RiskClassDashboard data={data.riskAssessmentDashboard} />,
  };

  const handleDashboardChange = (event: SelectChangeEvent) => {
    setDashboardType(event.target.value as DashboardTypeEnum);
  };

  return (
    <>
      <Select
        sx={{ width: '150px' }}
        id="demo-simple-select-small"
        value={dashboardType}
        onChange={handleDashboardChange}
        size="small"
      >
        <MenuItem value={DashboardTypeEnum.IMPACT}>Impact</MenuItem>
        <MenuItem value={DashboardTypeEnum.FREQUENCY}>Frequency</MenuItem>
        <MenuItem value={DashboardTypeEnum.RISK_CLASS}>Risk Class</MenuItem>
      </Select>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {DashboardMap[dashboardType]}
        </Grid>
        <Grid item xs={2}>
          <AverageSeverityCircularDashboard
            value={data.riskAssessmentDashboard?.averageSeverity || 0}
          />
        </Grid>
        <Grid item xs={2}>
          <NumberDisplay
            title="Assessed Risks"
            value={data.riskAssessmentDashboard?.numAssessedRisks || 0}
          />
        </Grid>
        <Grid item xs={2}>
          <NumberDisplay
            title="Untreated Risks"
            value={data.riskAssessmentDashboard?.numUntreatedHighRisk || 0}
          />
        </Grid>
      </Grid>
    </>
  );
}
